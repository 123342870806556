import React, { useState } from "react";
import "./destination.css";
import a from "./images/Agra.jpg";
import taj from "../Enchant images/Agra/Historical & Cultural exploration/Taj Mahal.jpg";
import redf from "../Enchant images/Agra/Historical & Cultural exploration/Agra Fort1.jpg";
import itc from "../Enchant images/Agra/Palatial Accommodation/ITC Mughal.jpg";
import obe from "../Enchant images/Agra/Palatial Accommodation/The Oberoi Amarvilas1.jpg";
import r1 from "../Enchant images/Agra/Culinary Excellence/Peshawri at ITC Mughal2.jpg";
import r2 from "../Enchant images/Agra/Culinary Excellence/Esphahan at The Oberoi Amarvilas.jpg";
import s1 from "../Enchant images/Agra/Wellness & relaxation/Kaya Kalp - The Royal Spa at ITC Mughal.jpg";
import e1 from "../Enchant images/Agra/Exclusive Experience/Taj Mahal Private Viewing.jpg";
import e2 from "../Enchant images/Agra/Exclusive Experience/Mughal Cuisine Cooking Classes.jpg";
import h1 from "../Enchant images/Agra/Luxury Shopping/Taj Ganj Bazaar.jpg";
export default function Agra() {
  const [activeLink, setActiveLink] = useState("overview");

  const scrollToSection = () => {
    const targetSection = document.getElementById("j");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("itineraries");
  };
  const scrollToSection1 = () => {
    const targetSection = document.getElementById("r");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("locations");
  };
  const scrollToSection2 = () => {
    const targetSection = document.getElementById("k");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("hotels");
  };

  return (
    <>
      <div className="div">
        <div className="destination_header">
          <img id="s" src={a} alt="" />
          <div className="he">
            <h1>LUXURY HOLIDAYS & HONEYMOONS IN AGRA</h1>
          </div>
        </div>

        <center>
          <div className="nav2">
            <div className="div3">
              <a href="">
                <p
                  className={` ${
                    activeLink === "overview" ? "active-link" : ""
                  }`}
                >
                  OVERVIEW
                </p>
              </a>
              <a onClick={scrollToSection}>
                <p
                  className={` ${
                    activeLink === "itineraries" ? "active-link" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  ITINERARIES
                </p>
              </a>

              <a onClick={scrollToSection2}>
                <p
                  className={` ${activeLink === "hotels" ? "active-link" : ""}`}
                  style={{ cursor: "pointer" }}
                >
                  HOTELS
                </p>
              </a>
              <a onClick={scrollToSection1}>
                <p
                  style={{ cursor: "pointer" }}
                  className={` ${
                    activeLink === "locations" ? "active-link" : ""
                  }`}
                >
                  RESTAURANTS
                </p>
              </a>
            </div>
          </div>
        </center>

        <br />

        <div className="div4">
          <div className="sec1">
            <br />
            

            <h2>
              WHY SHOULD YOU TRAVEL TO AGRA WITH US?
              <br />
              
            </h2>
            <br />
            <p style={{textAlign:"justify"}}>
              Welcome to the iconic city of Agra, home to one of the world's
              most famous landmarks, the Taj Mahal. With its historical
              significance, opulent accommodations, and cultural richness, Agra
              offers a luxurious journey into India's royal heritage. Let's
              explore the world of luxury that awaits you in this captivating
              destination. Agra, with its luxurious accommodations, historical
              significance, and refined experiences, offers a journey into
              India's royal past. From staying in elegant hotels to savoring
              gourmet cuisine and engaging in exclusive activities, Agra
              promises an unforgettable journey that captures the essence of
              luxury and cultural richness.
            </p>
          </div>
          <div className="sec2">
            <br />
            
            <img src={h1} alt="" />
          </div>
        </div>

        <div className="div5" style={{ background: "#fafafa" }}>
          <br />
          <br />
          
          <center>
            <h2 id="j">RECOMMENDED ITINERARIES IN AGRA</h2>
            <br />
            <strong>
              <p>
                Engage with Agra's rich history through visits to its iconic
                landmarks.
              </p>
            </strong>
            <br />
            <br />
            
          </center>

          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={taj} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Taj Mahal</h4>
                    <br />
                    
                    <p style={{textAlign:"justify"}}>
                      Explore the timeless beauty of the Taj Mahal, an
                      architectural marvel that reflects the love of Mughal
                      Emperor Shah Jahan for his wife Mumtaz Mahal.{" "}
                    </p>
                    
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={redf} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Agra Fort</h4>
                    <br />
                    
                    <p style={{textAlign:"justify"}}>
                      Discover the UNESCO World Heritage Site, Agra Fort, a
                      sprawling complex that served as a royal residence and
                      military stronghold.{" "}
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </center>

         
          <center>
            <h2 id="k">RECOMMENDED HOTELS IN AGRA</h2>
            <br />
            <strong>
              <p>
                Agra boasts a selection of luxurious hotels that combine comfort
                with regal elegance.
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>

          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "405px" }}>
                <div className="dimg1">
                  <img src={obe} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>The Oberoi Amarvilas</h4>
                    <br />
                    
                    <p style={{textAlign:"justify"}}>
                      Indulge in unobstructed views of the Taj Mahal from the
                      comfort of your suite at The Oberoi Amarvilas. Experience
                      world-class amenities, exquisite dining, and personalized
                      service.{" "}
                    </p>

                    <br />
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={itc} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>ITC Mughal</h4>
                    <br />
                    
                    <p style={{textAlign:"justify"}}>
                      Reflecting the Mughal grandeur, this luxury resort offers
                      a range of elegant accommodations surrounded by lush
                      gardens. Enjoy modern comforts while embracing the
                      historical essence of Agra.{" "}
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </center>
         <br/>
          <center>
            <h2 id="r">RECOMMENDED RESTAURANTS IN AGRA</h2>
            <br />
            <strong>
              <p>
                Agra's culinary scene is a celebration of Mughal and North
                Indian flavors.
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>

          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={r1} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>
                      Peshawri at ITC Mughal
                    </h4>
                    <br />
                    <p style={{textAlign:"justify"}}>
                      Savor the flavors of North Indian cuisine in a regal
                      setting. Peshawri offers a traditional dining experience
                      with a focus on aromatic kebabs and rich curries.{" "}
                    </p>{" "}
                    <br />
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={r2} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>
                      Esphahan at The Oberoi Amarvilas
                    </h4>
                    <br />
                    <p style={{textAlign:"justify"}}>
                      Experience fine dining with a view of the Taj Mahal as a
                      backdrop. Esphahan offers an exquisite fusion of Mughalai
                      and contemporary cuisine.{" "}
                    </p>{" "}
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </center>
        </div>
      </div>
     

      <center>
        <h2 id="k">WELLNESS AND RELAXATION</h2>
        <br />
        <strong>
          <p>
            Rejuvenate your senses at Agra's luxurious wellness centers and
            spas.
          </p>
        </strong>
        <br />
        <br />
        <br />
      </center>
      <center>
        {/* this is two templates */}

        <div className="hotel">
          <div className="h2" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg2">
              <img src={s1} alt="" />
              <div className="dp2">
                <br />
                <h4 style={{ textAlign: "start" }}>
                  Kaya Kalp - The Royal Spa at ITC Mughal:
                </h4>
                <br />
                
                <p style={{textAlign:"justify"}}>
                  Immerse yourself in holistic wellness at Kaya Kalp, where
                  traditional therapies and modern spa practices ensure a
                  rejuvenating experience.
                </p>{" "}
                <br />
              </div>
            </div>
          </div>
        </div>
      </center>
    

      <center>
        <h2 id="j"> EXCLUSIVE EXPERIENCES </h2>
        <br />
        <strong>
          <p>Elevate your Agra journey with unique activities:</p>
        </strong>
        <br />
        <br />
        <br />
      </center>

      <center>
        {/* this is two templates */}

        <div className="hotel">
          <div className="h1" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg1">
              <img src={e1} alt="" />
              <div className="dp1">
                <br />
                <h4 style={{ textAlign: "start" }}>
                  Taj Mahal Private Viewing
                </h4>
                <br />
                <p style={{textAlign:"justify"}}>
                  Arrange for a private viewing of the Taj Mahal at sunrise or
                  sunset, allowing you to experience this iconic monument in
                  solitude.{" "}
                </p>{" "}
                <br />
              </div>
            </div>
          </div>

          <div className="h2" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg2">
              <img src={e2} alt="" />
              <div className="dp2">
                <br />
                <h4 style={{ textAlign: "start" }}>
                  Mughal Cuisine Cooking Classes
                </h4>
                <br />
                <p style={{textAlign:"justify"}}>
                  Participate in private cooking classes to learn the secrets of
                  Mughal cuisine from expert chefs.{" "}
                </p>{" "}
                <br />
              </div>
            </div>
          </div>
        </div>
      </center>
    </>
  );
}
