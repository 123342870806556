import React from "react";
import { Link } from "react-router-dom"; // Import Link from React Router
import "./navbar.css";

export default function PinkButton() {
  return (
    <div className="div">
        <button className="pink-button">LOGIN</button>
  
    </div>
  );
}