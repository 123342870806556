import React, { useEffect, useState } from "react";
import { HiOutlineLocationMarker } from "react-icons/hi";
import gul from "./images/Gulmarg.jpg";
import all from "./images/Alleppey.jpg";
import an from "./images/Andaman.jpg";
import ba from "./images/Bali.jpg";
import dal from "./images/Dalhousie.jpg";
import di from "./images/Diu.jpg";
import dub from "./images/Dubai.jpg";
import gok from "./images/Gokarna.jpg";
import gan from "./images/Gangtok.jpg";
import guw from "./images/Guwahati.jpg";
import kuma from "./images/Kumarakom.jpg";
import mal from "./images/Maldives.jpg";
import mun from "./images/Munnar.jpg";
import rant from "./images/Ranthambore.jpg";
import sing from "./images/Singapore.jpg";
import srin from "./images/Srinagar.jpg";
import tur from "./images/Turkey.jpg";
import uda from "./images/Udaipur.jpg";
import ama from "./images/Amalfi.jpg";
import ams from "./images/Amsterdam.jpg";
import aus from "./images/Australia.jpg";
import bel from "./images/Belgium.jpg";
import bor from "./images/BoraBora.jpg";
import can from "./images/Cancun.jpg";
import cap from "./images/CapeTown.jpg";
import cro from "./images/Croatia.jpg";
import egy from "./images/Egypt.jpg";
import fin from "./images/Finland.jpg";
import liz from "./images/LizardIsland.jpeg";
import lon from "./images/London.jpg";
import mil from "./images/Milan.jpg";
import par from "./images/Paris.jpg";
import rom from "./images/Rome.jpg";
import sco from "./images/Scotland.jpg";
import sey from "./images/Seychelles.jpg";
import saint from "./images/St Lucia.jpg";
import swis from "./images/Switzerland.jpg";
import masai from "./images/MaasaiMara.jpg";
import tul from "./images/Tulum.jpg";
import vie from "./images/Vietnam.jpg";
import mia from './images/Miami.jpg'
import mar from './images/Mauritius.jpg'
import './experience.css';
export default function Experience() {
  const Data = [
    {
      id: 1,
      imgsrc:
        "https://cdn.pixabay.com/photo/2020/02/02/17/24/travel-4813658_1280.jpg",
      destTitle: "New Delhi",
      location: "India",
      grade: "Cultaral Relax",
      fees: 100,
      description:
        "Delhi, a city of contrasts, seamlessly merges historic grandeur with contemporary dynamism. As the heart of India, it boasts iconic landmarks like the Red Fort and India Gate. Amidst the bustling streets, find luxury accommodations, diverse culinary experiences, and high-end shopping.",
    },
    {
      id: 2,
      imgsrc:
        "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0e/2b/e6/f1/view-from-outside-of.jpg?w=1400&h=900&s=1",
      destTitle: "Jodhapur",
      location: "India",
      grade: "Cultaral Relax",
      fees: 200,
      description:
        "In the heart of Rajasthan's Thar Desert, Jodhpur stands as an oasis of regal magnificence. The city's majestic Mehrangarh Fort dominates the landscape, while its azure-painted houses evoke a sense of enchantment. Experience luxury in heritage hotels, savor Rajasthani cuisine, ",
    },
    {
      id: 3,
      imgsrc:
        "https://cdn.pixabay.com/photo/2023/03/26/20/48/tajmahal-7879103_1280.jpg",
      destTitle: "Agra",
      location: "India",
      grade: "Cultaral Relax",
      fees: 300,
      description:
        "Welcome to the iconic city of Agra, home to one of the world's most famous landmarks, the Taj Mahal. With its historical significance, opulent accommodations, and cultural richness, Agra offers a luxurious journey into India's royal heritage.Let's explore the world of luxury that awaits you in this captivating destination.",
    },
  ]
 
  const Data1 = [
    {
      id: 7,
      imgsrc:
        "https://cdn.pixabay.com/photo/2019/02/12/14/53/golconda-fort-3992421_1280.jpg",
      destTitle: "Hyderabad",
      location: "India",
      grade: "Cultaral Relax",
      fees: 688,
      description:
        "Hyderabad, a city steeped in rich history and vibrant culture, welcomes tourists with open arms. Explore the iconic Charminar, savor the famed Hyderabadi cuisine, and immerse yourself in the blend of tradition and modernity that defines metropolis. ",
    },
    {
      id: 8,
      imgsrc:
        "https://cdn.pixabay.com/photo/2014/01/14/18/52/kempegowda-international-airport-245023_1280.jpg",
      destTitle: "Bengaluru",
      location: "India",
      grade: "Cultaral Relax",
      fees: 899,
      description:
        "Bangalore, known as the Silicon Valley of India, invites tourists to experience its dynamic blend of innovation and green spaces. Explore lush parks,  and indulge in a diverse culinary tapestry. Bangalore offers a glimpse into its cosmopolitan charm and vibrant urban life.",
    },
    {
      id: 9,
      imgsrc:
        "https://cdn.pixabay.com/photo/2021/04/24/05/25/by-siddarth-shankar-6203247_1280.jpg",
      destTitle: "Chennai",
      location: "India",
      grade: "Cultaral Relax",
      fees: 261,
      description:
        "Chennai, a coastal gem on the Bay of Bengal, invites tourists to explore its unique blend of tradition and modernity. Discover the iconic Marina Beach, delve into the city's artistic heritage at museums and galleries, and savor South Indian delicacies that tantalize the taste buds. ",
    },
   
  ]
  const Data2 = [
    {
      id: 10,
      imgsrc:
        "https://cdn.pixabay.com/photo/2013/07/25/12/06/bridge-167041_1280.jpg",
      destTitle: "Kolkata",
      location: "India",
      grade: "Cultaral Relax",
      fees: 5682,
      description:
        "Kolkata, the cultural capital of India, welcomes tourists with its artistic fervor and historical significance. Immerse yourself in its colonial architecture. From the bustling Howrah Bridge to the tranquility of the Maidan, Kolkata offers a sensory journey through its literary heritage,",
    },
    {
      id: 11,
      imgsrc:
        "https://cdn.pixabay.com/photo/2019/12/12/08/36/vizag-4690108_1280.jpg",
      destTitle: "Vizag",
      location: "India",
      grade: "Cultaral Relax",
      fees: 687,
      description:
        "Welcome to the picturesque coastal city of Visakhapatnam, often referred to as Vizag. With its stunning beaches, cultural heritage, and upscale experiences, Visakhapatnam offers a luxurious escape that captures the essence of coastal beauty and modern indulgence.",
    },
    {
      id: 6,
      imgsrc:
        "https://media.istockphoto.com/id/1325342016/photo/mumbai-skyline-wadala-sewri-lalbaug.webp?b=1&s=170667a&w=0&k=20&c=PIdofESaOihcI_XFKRPjOrCAwj-6QIuZX84KxM6WDS4=",
      destTitle: `Mumbai`,
      location: "India",
      grade: "Cultaral Relax",
      fees: 600,
      description:
        "Explore Mumbai, a metropolis where glamour, culture, and luxury converge for an exquisite experience. As India's financial hub and a cultural melting pot, Mumbai offers upscale accommodations, gourmet dining, and exclusive experiences.",
    },
  ]

    const newPath = "/experience";
    window.history.replaceState({}, document.title, newPath);




  return (
    <>
     <br/>
     <br/>
      <center>
       
        
        <div className="div">
        <div className="destination_header">
          <img id="s" src={an} alt="" />
          <div className="he">
            <h1>
            WHAT ARE YOUR EXPECTATIONS REGARDING EXPERIENCE         </h1>{" "}
          </div>
        </div>
        </div>
      
       
        
        <div className="div5" style={{ background: "#fafafa" }}>
          <br />
          <br />
          <br />
          <center>
            <h2 id="j">EXPLORE THE WORLD</h2>
            <br />
            <strong>
              <p>
              "Embark on a journey of a lifetime and embrace the thrill of the unknown."
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>
          

   
        <div className="secContent grid">
          {Data.map(
            ({ id, imgsrc, destTitle, location, grade, fees, description }) => {
              return (
                <div key={id} className="singleDestination">
                  <div className="imageDiv">
                    <img src={imgsrc} alt={destTitle} />
                  </div>
                  <div className="cardInfo">
                    <center>
                      <h3
                      //   className="destTitle"
                      >
                        {destTitle} |{" "}
                        <span className="continent ">
                          <HiOutlineLocationMarker className="icon" />
                          <span className="name">{location}</span>
                        </span>
                      </h3>
                    </center>

                    <span>
                      {" "}
                      <strong>
                        <a
                          href={`/${destTitle.toLowerCase()}`}
                          style={{ color: "black" }}
                        >
                          <p style={{ cursor: "pointer", textAlign: "end" }}>
                            View{" >"}
                          </p>
                        </a>
                      </strong>
                    </span>
                  </div>
                </div>
              );
            }
          )}
          </div>
          <br/>
          <br/>
          <center>
            <h2 id="j">WANDERLUST LIFESTYLE</h2>
            <br />
            <strong>
              <p>
              "Turn your wanderlust into a sustainable lifestyle with these digital nomad tips."

              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>  
       
    <div className="secContent grid">
          {Data1.map(
            ({ id, imgsrc, destTitle, location, grade, fees, description }) => {
              return (
                <div key={id} className="singleDestination">
                  <div className="imageDiv">
                    <img src={imgsrc} alt={destTitle} />
                  </div>
                  <div className="cardInfo">
                    <center>
                      <h3
                      //   className="destTitle"
                      >
                        {destTitle} |{" "}
                        <span className="continent ">
                          <HiOutlineLocationMarker className="icon" />
                          <span className="name">{location}</span>
                        </span>
                      </h3>
                    </center>

                    <span>
                      {" "}
                      <strong>
                        <a
                          href={`/${destTitle.toLowerCase()}`}
                          style={{ color: "black" }}
                        >
                          <p style={{ cursor: "pointer", textAlign: "end" }}>
                            View{" >"}
                          </p>
                        </a>
                      </strong>
                    </span>
                  </div>
                </div>
              );
            }
          )}
          </div>
<br/><br/>

<center>
            <h2 id="j">FAMILY ADVENTURES</h2>
            <br />
            <strong>
              <p>
              Explore destinations that cater to both parents and children.
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>
<div className="secContent grid">
          {Data2.map(
            ({ id, imgsrc, destTitle, location, grade, fees, description }) => {
              return (
                <div key={id} className="singleDestination">
                  <div className="imageDiv">
                    <img src={imgsrc} alt={destTitle} />
                  </div>
                  <div className="cardInfo">
                    <center>
                      <h3
                      //   className="destTitle"
                      >
                        {destTitle} |{" "}
                        <span className="continent ">
                          <HiOutlineLocationMarker className="icon" />
                          <span className="name">{location}</span>
                        </span>
                      </h3>
                    </center>

                    <span>
                      {" "}
                      <strong>
                        <a
                          href={`/${destTitle.toLowerCase()}`}
                          style={{ color: "black" }}
                        >
                          <p style={{ cursor: "pointer", textAlign: "end" }}>
                            View{" >"}
                          </p>
                        </a>
                      </strong>
                    </span>
                  </div>
                </div>
              );
            }
          )}
          </div>

        </div>
      </center>
    
    </>
  );
}