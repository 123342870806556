import React, { useState } from "react";
import "./destination.css";
import t1 from '../Enchant images/Udaipur/Cultural Exploration/udaipur-2311788_1280.jpg'
import i1 from '../Enchant images/Udaipur/Cultural Exploration/City Palace.jpg'
import i2 from '../Enchant images/Udaipur/Cultural Exploration/Boat Ride on Lake Pichola.jpg'
import i3 from '../Enchant images/Udaipur/Cultural Exploration/Hathi Pol Bazaar.jpg'
import h1 from '../Enchant images/Udaipur/Palatia Retreat/Taj Lake Palace.jpg'
import h2 from '../Enchant images/Udaipur/Palatia Retreat/The Oberoi Udaivilas.jpg'
import r1 from '../Enchant images/Udaipur/Gourmet Delights/Sheesh Mahal at The Leela Palace.jpg'
import r2 from '../Enchant images/Udaipur/Gourmet Delights/Ambrai Restaurant.jpg'
import s1 from '../Enchant images/Udaipur/Wellness & Relaxation/Jiva Spa at Taj Lake Palace.jpg'
import e2 from '../Enchant images/Udaipur/Exclusive Experience/Cultural Performances.jpg'
import e1 from '../Enchant images/Udaipur/Exclusive Experience/Vintage Car ride.jpg'
import i from './images/Udaipur.jpg'

export default function Udaipur() {
  const [activeLink, setActiveLink] = useState("overview");

  const scrollToSection = () => {
    const targetSection = document.getElementById("j");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("itineraries");
  };
  const scrollToSection1 = () => {
    const targetSection = document.getElementById("r");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("locations");
  };
  const scrollToSection2 = () => {
    const targetSection = document.getElementById("k");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("hotels");
  };

  return (
    <>
      <div className="div">
        <div className="destination_header">
          <img
            id="s"
            src={i}
            alt=""
          />
          <div className="he">
            <h1>LUXURY HOLIDAYS & HONEYMOONS IN UDAIPUR</h1>
          </div>
        </div>

        <center>
          <div className="nav2">
            <div className="div3">
              <a href="">
                <p
                  className={` ${
                    activeLink === "overview" ? "active-link" : ""
                  }`}
                >
                  OVERVIEW
                </p>
              </a>
              <a onClick={scrollToSection}>
                <p
                  className={` ${
                    activeLink === "itineraries" ? "active-link" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  ITINERARIES
                </p>
              </a>
           
              <a onClick={scrollToSection2}>
                <p
                  className={` ${activeLink === "hotels" ? "active-link" : ""}`}
                  style={{ cursor: "pointer" }}
                >
                  HOTELS
                </p>
              </a>
              <a onClick={scrollToSection1}>
                <p
                  style={{ cursor: "pointer" }}
                  className={` ${
                    activeLink === "locations" ? "active-link" : ""
                  }`}
                >
                  RESTAURANTS
                </p>
              </a>
            </div>
          </div>
        </center>

        <br />
  

        <div className="div4">
          <div className="sec1">
            <br />
           
            <h2>
              WHY SHOULD YOU TRAVEL TO UDAIPUR WITH US?
              <br />
           
            </h2>
            <br/>
            <p style={{textAlign:"justify"}}>
            Welcome to the enchanting city of Udaipur, often referred to as the "City of Lakes" and the "Venice of the East." Nestled in the heart of Rajasthan, Udaipur is a regal destination that exudes luxury, culture, and timeless elegance. From opulent palaces and serene lakes to fine dining and royal experiences, Udaipur offers a lavish escape that celebrates the grandeur of Indian royalty.

Udaipur, with its palatial accommodations, rich history, and refined experiences, offers a luxurious escape that encapsulates the grandeur of Rajasthan's royal past. 
            </p>
          </div>
          <div className="sec2">
            
            
            <img
              src={t1}
              alt=""
            />
          </div>
        </div>

        <div className="div5" style={{ background: "#fafafa" }}>
          <br />
          <br />
          
          <center>
            <h2 id="j">RECOMMENDED ITINERARIES IN UDAIPUR</h2>
            <br />
            <strong>
              <p>
              Udaipur's rich history and cultural heritage are waiting to be explored and offers a blend of traditional crafts and contemporary designs.


              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>
          <div className="fhotel">
            <div className="d1">
              <img
                src={i1}
                alt=""
              />
            </div>
            <div
              className="d2"
              style={{
                background: "white",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                height:"338px"
              }}
            >
              <br />
              <div className="d21">
                <h4>City Palace</h4>
                <br />
               
                <p style={{textAlign:"justify"}}>
                Immerse yourself in the grandeur of the City Palace, a majestic complex of palaces, courtyards, and museums. Witness the artistry and architecture of Rajasthan's royal history.
                </p>
                <br /> <br />
               
              </div>
            </div>
          </div>
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img
                    src={i2}
                    alt=""
                  />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Boat Ride on Lake Pichola</h4>
                    <br />
                    
                    <p style={{textAlign:"justify"}}>
                    Enjoy a luxurious boat ride on Lake Pichola, gliding past historic landmarks like Jag Mandir and the Lake Palace. Witness the city's beauty from the tranquil waters.
                    </p>
                    <br />
                   
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img
                    src={i3}
                    alt=""
                  />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Hathi Pol Bazaar</h4>
                  
                    <br />
                    <p style={{textAlign:"justify"}}>
                    Explore the bustling market for intricate textiles, handicrafts, jewelry, and miniature paintings that showcase the city's artistic heritage.
                    </p>
                    <br />
                   
                  </div>
                </div>
              </div>
            </div>
          </center>

          
          <center>
            <h2 id="k">RECOMMENDED HOTELS IN UDAIPUR</h2>
            <br />
            <strong>
              <p>
              Udaipur is renowned for its exquisite palace hotels that transport you to a world of royal opulence.


              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>
      
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img
                    src={h1}
                    alt=""
                  />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Taj Lake Palace</h4>
                   
                    <br />  <p style={{textAlign:"justify"}}>
                    A floating marvel on Lake Pichola, this palace-turned-hotel offers unmatched luxury. Experience the allure of staying in a palace surrounded by water and enjoy impeccable service and stunning views.
                    </p>
                    <br />
                    
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img
                    src={h2}
                    alt=""
                  />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>The Oberoi Udaivilas</h4>
                    
                    <br />
                    <p style={{textAlign:"justify"}}>
                    Set against the backdrop of the Aravalli Hills, this resort redefines luxury. With grand architecture, lush gardens, and luxurious suites, it provides a royal haven.
                    </p>
                    <br />
                  
                  </div>
                </div>
              </div>
            </div>
          </center>
         
          <center>
            <h2 id="r">RECOMMENDED RESTAURANTS IN UDAIPUR</h2>
            <br />
            <strong>
              <p>
              Udaipur's culinary scene is a symphony of flavors that caters to the refined palate.


              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>

          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img
                    src={r1}
                    alt=""
                  />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Sheesh Mahal at The Leela Palace</h4>
                    <br />
                    <p style={{textAlign:"justify"}}>
                    Experience fine dining in an elegant setting that mirrors the grandeur of the past. Indulge in regal Rajasthani cuisine and international delicacies.
                    </p>{" "}
                    <br />
                  
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img
                    src={r2}
                    alt=""
                  />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Ambrai Restaurant</h4>
                    <br />
                    <p style={{textAlign:"justify"}}>
                    Dine overlooking the iconic Lake Pichola and Udaipur's historic skyline. Savor gourmet dishes inspired by local traditions and global influences.
                    </p>{" "}
                    <br />
                   
                  </div>
                </div>
              </div>
            </div>
          </center>
        </div>
      </div>
  
      <center>
        <h2 id="k">WELLNESS AND RELAXATION</h2>
        <br />
        <strong>
          <p>
          Rejuvenate your senses in Udaipur's serene wellness centers and spas.


          </p>
        </strong>
        <br />
        <br />
        <br />
      </center>
      <center>
        {/* this is two templates */}

        <div className="hotel">
        

          <div className="h2" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg2">
              <img
                src={s1}
                alt=""
              />
              <div className="dp2">
                <br />
                <h4 style={{ textAlign: "start" }}>Jiva Spa at Taj Lake Palace</h4>
                <br />
                
                <p style={{textAlign:"justify"}}>
                Experience relaxation fit for royalty with rejuvenating therapies that blend traditional Indian healing techniques with modern spa practices.
                </p>
                <br />
               
              </div>
            </div>
          </div>
        </div>
      </center>
    
      <center>
        <br/>
        
        <h2 id="j">EXCLUSIVE EXPERIENCES
</h2>
        <br />
        <strong>
          <p>
          Elevate your Udaipur journey with these extraordinary activities:


          </p>
        </strong>
        <br />
        <br />
        <br />
      </center>

   
      <center>
        {/* this is two templates */}

        <div className="hotel">
          <div className="h1" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg1">
              <img
                src={e1}
                alt=""
              />
              <div className="dp1">
                <br />
                <h4 style={{ textAlign: "start" }}>Vintage Car Ride</h4>
                <br />
                <p style={{textAlign:"justify"}}>
                Feel like a Maharaja as you explore the city in vintage cars. Let the chauffeur take you on a journey through Udaipur's historic streets.
                </p>{" "}
                <br />
    
              </div>
            </div>
          </div>

          <div className="h2" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg2">
              <img
                src={e2}
                alt=""
              />
              <div className="dp2">
                <br />
                <h4 style={{ textAlign: "start" }}>Cultural Performances</h4>
                <br />
                <p style={{textAlign:"justify"}}>
                Attend traditional music and dance performances in the regal settings of Udaipur's palaces. Immerse yourself in the cultural vibrancy of the region.
                </p>{" "}
                <br />
               
              </div>
            </div>
          </div>
        </div>
      </center>
    </>
  );
}
