import React, { useState } from "react";
import "./destination.css";
import a from "./images/Vietnam.jpg";
import t1 from '../Enchant images/Veitnam/Cultural exploration & natural wonders/istockphoto-1503226881-612x612.webp'
import i1 from '../Enchant images/Veitnam/Cultural exploration & natural wonders/Halong Bay Cruise.jpg'
import i2 from "../Enchant images/Veitnam/Cultural exploration & natural wonders/Hue's Imperial City.jpg"
import r1 from '../Enchant images/Veitnam/Culinary Delightd & Vietnamese cuisine/Maison Marou.jpg'
import r2 from "../Enchant images/Veitnam/Culinary Delightd & Vietnamese cuisine/Hoi An's Street Food Tour.jpg"
import h1 from '../Enchant images/Veitnam/Luxurious  Resorts & Boutique Hotels/The Nam Hai Hoi An.jpg'
import h2 from '../Enchant images/Veitnam/Luxurious  Resorts & Boutique Hotels/Amanoi.jpg'
import s1 from '../Enchant images/Veitnam/Wellness & Relaxation/Six Senses Ninh Van Bay Spa.jpg'
import e1 from '../Enchant images/Veitnam/Exclusive Experience/Seaplane Tour over Halong Bay.jpg'
import e2 from '../Enchant images/Veitnam/Exclusive Experience/Private Vespa Tour in Saigon.jpg'
export default function Vietnam() {
  const [activeLink, setActiveLink] = useState("overview");

  const scrollToSection = () => {
    const targetSection = document.getElementById("j");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("itineraries");
  };
  const scrollToSection1 = () => {
    const targetSection = document.getElementById("r");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("locations");
  };
  const scrollToSection2 = () => {
    const targetSection = document.getElementById("k");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("hotels");
  };

  return (
    <>
      <div className="div">
        <div className="destination_header">
          <img id="s" src={a} alt="" />
          <div className="he">
            <h1>
            DISCOVER THE MAGIC OF THIS DIVERSE COUNTRY AND CREATE MEMORIES THAT RESONATE!            </h1>{" "}
          </div>
        </div>

        <center>
          <div className="nav2">
            <div className="div3">
              <a href="">
                <p
                  className={` ${
                    activeLink === "overview" ? "active-link" : ""
                  }`}
                >
                  OVERVIEW
                </p>
              </a>
              <a onClick={scrollToSection}>
                <p
                  className={` ${
                    activeLink === "itineraries" ? "active-link" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  ITINERARIES
                </p>
              </a>
             
              <a onClick={scrollToSection2}>
                <p
                  className={` ${activeLink === "hotels" ? "active-link" : ""}`}
                  style={{ cursor: "pointer" }}
                >
                  HOTELS
                </p>
              </a>
              <a onClick={scrollToSection1}>
                <p
                  style={{ cursor: "pointer" }}
                  className={` ${
                    activeLink === "locations" ? "active-link" : ""
                  }`}
                >
                  RESTAURANTS
                </p>
              </a>
            </div>
          </div>
        </center>

        <br />
        

        <div className="div4">
          <div className="sec1">
          
           
            <h2>
              WHY SHOULD YOU TRAVEL TO VIETNAM WITH US?
            
              
            </h2> 
            <br/>
            
            <p style={{textAlign:"justify"}}>
            Welcome to the captivating land of Vietnam, where ancient traditions, breathtaking landscapes, and upscale experiences converge to offer a luxurious and culturally enriching escape. From bustling cities to serene countryside and pristine coastlines, Vietnam presents a perfect blend of opulence and heritage. Let's explore the world of luxury that awaits you in this mesmerizing destination.

Vietnam, with its luxurious resorts, cultural wonders, and diverse experiences, offers a captivating and opulent escape that captures both tradition and natural beauty. From staying in elegant accommodations to savoring gourmet cuisine and engaging in curated activities, Vietnam promises an unforgettable journey that embodies the essence of luxury and the allure of authentic exploration.             </p>
          </div>
          <div className="sec2">
          
            <br/>
            <img
              src={t1}
              alt=""
              height={200}
            />
          </div>
        </div>

        <div className="div5" style={{ background: "#fafafa" }}>
          <br />
          <br />
      
          <center>
            <h2 id="j">RECOMMENDED ITINERARIES IN VEITNAM</h2>
            <br />
            <strong>
              <p>
              Engage with Vietnam's rich history and natural beauty through curated experiences
              </p>
            </strong>
            <br />
          
          </center>

          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img
                    src={i1}
                    alt=""
                  />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Halong Bay Cruise</h4>
                    <br />
                    
                    <p style={{textAlign:"justify"}}>
Explore the iconic Halong Bay on a luxury cruise, where you can sail through emerald waters, limestone karsts, and stunning caves in this UNESCO World Heritage site.
                    </p>
                    <br />
                  
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img
                    src={i2}
                    alt=""
                  />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Hue's Imperial City</h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                    Discover the historical significance of Hue's Imperial City, a complex of palaces, temples, and gates that reflects Vietnam's imperial past.
                    </p>
                    <br />
                  
                  </div>
                </div>
              </div>
            </div>
          </center>

        
          <center>
            <h2 id="r">CULINARY DELIGHTS AND VIETNAMESE CUISINE</h2>
            <br />
            <strong>
              <p>
              Indulge in a culinary journey that celebrates Vietnamese flavors and international influences.


              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>
          {/* <div className="fhotel">
            <div className="d1">
              <img
                src="https://cdn.pixabay.com/photo/2016/11/18/14/05/brick-wall-1834784_1280.jpg"
                alt=""
              />
            </div>
            <div
              className="d2"
              style={{
                background: "white",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              <br />
              <div className="d21">
                <h4>Taj Exotica Goa</h4>
                <br />
                <br />
                <br />
                <p style={{ textAlign: "start" }}>
                  Set amidst palm-fringed gardens and overlooking the Arabian
                  Sea, Taj Exotica is an oasis of luxury. With spacious villas,
                  impeccable service, and stunning sea views, this resort
                  promises an unforgettable stay.
                </p>
                <br /> <br />
                <p
                  style={{
                    textAlign: "end",
                    fontWeight: "600",
                    fontSize: "14px",
                    letterSpacing: "2px",
                  }}
                >
               
                </p>
              </div>
            </div>
          </div> */}
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img
                    src={r1}
                    alt=""
                  />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}> Maison Marou</h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
            Savor gourmet chocolate and coffee at Maison Marou, where artisanal chocolate is paired with Vietnamese coffee in a chic and modern setting.

                    </p>
                    <br />
                  
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img
                    src={r2}
                    alt=""
                  />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Hoi An's Street Food Tour</h4>
                    <br />
                  
                    <p style={{textAlign:"justify"}}>
                    Enjoy a guided street food tour in Hoi An, discovering the flavors of Vietnamese cuisine amidst bustling markets and local eateries.
                    </p>
                    <br />
                   
                  </div>
                </div>
              </div>
            </div>
          </center>
        
          <center>
            <h2 id="k"> RECOMMENDED HOTELS IN VIETNAM</h2>
            <br />
            <strong>
              <p>
              Vietnam boasts a collection of luxurious resorts and boutique hotels that provide a perfect blend of comfort and local charm.


</p>
            </strong>
            <br />
            <br />
            <br />
          </center>

         
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img
                    src={h1}
                    alt=""
                  />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>
                    The Nam Hai Hoi An
                    </h4>
                    <br />
                    <p style={{textAlign:"justify"}}>
                    Immerse yourself in luxury at The Nam Hai, offering lavish villas, stunning beachfront views, and impeccable service on the shores of the South China Sea. </p>                    <br />
                  
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img
                    src={h2}
                    alt=""
                  />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>
                    Amanoi
                    </h4>
                    <br />
                    <p style={{textAlign:"justify"}}>
                    Experience tranquility at Amanoi, a secluded resort nestled in Nui Chua National Park, where contemporary design, wellness offerings, and personalized experiences create an ambiance of relaxation.           </p>         <br />
                
                  </div>
                </div>
              </div>
            </div>
          </center>
        </div>
      </div>
 
      <center>
        <h2 id="k">WELLNESS AND RELAXATION</h2>
        <br />
        <strong>
          <p>
          Rejuvenate your senses at Vietnam's luxury wellness centers and spas.


          </p>
        </strong>
        <br />
        <br />
        <br />
      </center>
      <center>
        {/* this is two templates */}

        <div className="hotel">
          <div className="h2" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg2">
              <img
                src={s1}
                alt=""
              />
              <div className="dp2">
                <br />
                <h4 style={{ textAlign: "start" }}>
                Six Senses Ninh Van Bay Spa
                </h4>
                <br />
                
                <p style={{textAlign:"justify"}}>
          Immerse yourself in holistic wellness at Six Senses Spa, offering a range of treatments inspired by Vietnamese traditions and modern relaxation techniques.
                </p>{" "}
                <br />
               
              </div>
            </div>
          </div>
        </div>
      </center>
   

      <center>
        <h2 id="j"> EXCLUSIVE EXPERIENCES</h2>
        <br />
        <strong>
          <p>
          Elevate your Vietnam journey with extraordinary activities:

</p>
        </strong>
        <br />
        <br />
        <br />
      </center>

      <center>
        {/* this is two templates */}

        <div className="hotel">
          <div className="h1" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg1">
              <img
                src={e1}
                alt=""
              />
              <div className="dp1">
                <br />
                <h4 style={{ textAlign: "start" }}> Seaplane Tour over Halong Bay</h4>
                <br />
                <p style={{textAlign:"justify"}}>
               Soar above Halong Bay in a seaplane for a breathtaking aerial view of the karst formations, emerald waters, and picturesque islands.
                </p>{" "}
                <br />
              
              </div>
            </div>
          </div>

          <div className="h2" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg2">
              <img
                src={e2}
                alt=""
              />
              <div className="dp2">
                <br />
                <h4 style={{ textAlign: "start" }}>Private Vespa Tour in Saigon</h4>
                <br />
                <p style={{textAlign:"justify"}}>
         Embark on a private Vespa tour in Saigon, exploring the city's vibrant streets, cultural landmarks, and hidden culinary gems.
                </p>{" "}
                <br />
               
              </div>
            </div>
          </div>
        </div>
        <p style={{textAlign:"end",color:"white"}}>&copy;&nbsp;2023,Cambliss Pvt. Ltd.</p>

      </center>
      <br />
    
     
    </>
  );
}
