import React, { useState } from "react";
import "./destination.css";
import n1 from '../Enchant images/Alleppey/Cultural Engagment/t1.jpg'
import i from './images/Alleppey.jpg'
import t1 from '../Enchant images/Alleppey/Backwater & Bills & rELAXATION/Alleppey Beach.jpg'
import i1 from '../Enchant images/Alleppey/Cultural Engagment/Alleppey Backwater Village Tour.jpg'
import i2 from '../Enchant images/Alleppey/Cultural Engagment/Local Handicraft Shopping1.jpg'
import h1 from '../Enchant images/Alleppey/Luxury Houseboat & Resorts/Punnamada Resort.jpg'
import h2 from '../Enchant images/Alleppey/Luxury Houseboat & Resorts/Lake Palace Resort.jpg'
import r1 from '../Enchant images/Alleppey/Gourmet Delights/Vembanad Restaurant1.jpg'
import r2 from '../Enchant images/Alleppey/Gourmet Delights/Arabian Grill.jpg'
import s1 from '../Enchant images/Alleppey/Wellness & Rejuvenation/Ayurvedic Spa at Punnamada Resort.jpg'
import e1 from '../Enchant images/Alleppey/Exclusive Experince/Ayurvedic Wellness Retreat1.jpg'
import e2 from '../Enchant images/Alleppey/Exclusive Experince/Private Sunrise Houseboat Cruise.jpg'
export default function Allepey() {
  const [activeLink, setActiveLink] = useState("overview");

  const scrollToSection = () => {
    const targetSection = document.getElementById("j");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("itineraries");
  };
  const scrollToSection1 = () => {
    const targetSection = document.getElementById("r");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("locations");
  };
  const scrollToSection2 = () => {
    const targetSection = document.getElementById("k");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("hotels");
  };

  return (
    <>
      <div className="div">
        <div className="destination_header">
          <img
            id="s"
            src={i}
            alt=""
          />
          <div className="he">
            <h1>LUXURY HOLIDAYS & HONEYMOONS IN ALLEPPEY</h1>
          </div>
        </div>

        <center>
          <div className="nav2">
            <div className="div3">
              <a href="">
                <p
                  className={` ${
                    activeLink === "overview" ? "active-link" : ""
                  }`}
                >
                  OVERVIEW
                </p>
              </a>
              <a onClick={scrollToSection}>
                <p
                  className={` ${
                    activeLink === "itineraries" ? "active-link" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  ITINERARIES
                </p>
              </a>
             
              <a onClick={scrollToSection2}>
                <p
                  className={` ${activeLink === "hotels" ? "active-link" : ""}`}
                  style={{ cursor: "pointer" }}
                >
                  HOTELS
                </p>
              </a>
              <a onClick={scrollToSection1}>
                <p
                  style={{ cursor: "pointer" }}
                  className={` ${
                    activeLink === "locations" ? "active-link" : ""
                  }`}
                >
                  RESTAURANTS
                </p>
              </a>
            </div>
          </div>
        </center>

        <br />
      

        <div className="div4">
          <div className="sec1">
            <br />
           
            <h2>
              WHY SHOULD YOU TRAVEL TO ALLEPPEY WITH US?
              <br />
             
            </h2>
            <br />
            <p style={{textAlign:"justify"}}>
            Welcome to the tranquil haven of Alleppey, often referred to as the "Venice of the East." Nestled amidst Kerala's backwaters, Alleppey offers a luxurious escape that combines serene landscapes, opulent accommodations, and unique experiences. Let's explore the world of opulence that awaits you in this enchanting destination.

Alleppey, with its luxurious houseboats, tranquil backwaters, and curated experiences, offers a serene and opulent escape that captures both natural beauty and refined elegance. From staying in waterfront accommodations to savoring gourmet cuisine and engaging in wellness rituals, Alleppey promises an unforgettable journey that embodies the essence of luxury and the allure of Kerala's backwaters. Discover the magic of this idyllic destination and create memories that resonate with sophistication and the tranquility of the water.


            </p>
          </div>
          <div className="sec2">
            <br />
            <br />
            <img
              src={n1}
              alt=""
            />
          </div>
        </div>

        <div className="div5" style={{ background: "#fafafa" }}>
          <br />
          <br />
        
          <center>
            <h2 id="j">RECOMMENDED ITINERARIES IN ALLEPPEY</h2>
            <br />
            <strong>
              <p>
                Nothing but the best. Browse the hotels that can feature in your
                bespoke Goa itinerary
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>
          <div className="fhotel">
            <div className="d1">
              <img
                src={i1}
                alt=""
              />
            </div>
            <div
              className="d2"
              style={{
                background: "white",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                height:"319px"
              }}
            >
              <br />
              <div className="d21">
                <h4>Alleppey Backwater Village Tour</h4>
                <br />
                
                <p style={{ textAlign:"justify" }}>
                Engage in a guided tour of nearby villages to witness the local way of life, traditional occupations, and cultural practices.
                </p>
                <br /> <br />
                
              </div>
            </div>
          </div>
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img
                    src={i2}
                    alt=""
                  />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Local Handicraft Shopping</h4>
                    <br />
                    
                    <p style={{ textAlign:"justify" }}>
                    Discover local markets for traditional Kerala handicrafts, including intricately woven textiles, wood carvings, and unique souvenirs.
                    </p>
                    <br />
                    
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img
                    src={t1}
                    alt=""
                  />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Alleppey Beach</h4>
                    <br />
                   
                    <p style={{ textAlign:"justify" }}>
                    Relax on the pristine sands of Alleppey Beach, where gentle waves and breathtaking sunsets offer the perfect backdrop for unwinding.

                    </p>
                    <br />
                    
                  </div>
                </div>
              </div>
            </div>
          </center>

        
          <center>
            <h2 id="k">RECOMMENDED HOTELS IN ALLEPPEY</h2>
            <br />
            <strong>
              <p>
              Alleppey boasts a selection of luxurious houseboats and resorts that provide a perfect blend of comfort and breathtaking waterfront views.
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>
        
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img
                    src={h1}
                    alt=""
                  />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Punnamada Resort</h4>
                    <br />
                    
                    <p style={{ textAlign:"justify" }}>
                    Immerse yourself in luxury at Punnamada Resort, offering elegant villas with private pools, rejuvenating Ayurvedic spa treatments, and impeccable service in a serene backwater setting.
                    </p>
                    <br />
                    
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img
                    src={h2}
                    alt=""
                  />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Lake Palace Resort</h4>
                    <br />
                    
                    <p style={{ textAlign:"justify" }}>
                    Experience lakeside elegance at Lake Palace Resort, where spacious rooms, lush gardens, and a range of amenities create an ambiance of tranquility and indulgence.
                    </p>
                    <br />

                  </div>
                </div>
              </div>
            </div>
          </center>
          <br/>
          <center>
            <h2 id="r">RECOMMENDED RESTAURANTS  IN ALLEPPEY</h2>
            <br />
            <strong>
              <p>
              Indulge in a culinary journey that highlights local flavors and global cuisines.


              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>

     
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img
                   src={r1}
                    alt=""
                  />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Vembanad Restaurant</h4>
                    <br />
                    <p style={{ textAlign:"justify" }}>
                    Savor gourmet dishes at Vembanad, where the menu showcases traditional Kerala cuisine alongside international delights. The restaurant's waterfront setting complements its exquisite offerings.
                    </p>{" "}
                    <br />
                  
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img
                    src={r2}
                    alt=""
                  />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Arabian Grill</h4>
                    <br />
                    <p style={{ textAlign:"justify" }}>
                    Enjoy a fusion of Indian and Middle Eastern flavors at Arabian Grill, where the freshest ingredients and impeccable presentation elevate your dining experience.
                    </p>{" "}
                    <br />
                   
                  </div>
                </div>
              </div>
            </div>
          </center>
        </div>
      </div>
    
      <center>
        <h2 id="k">WELLNESS AND RELAXATION</h2>
        <br />
        <strong>
          <p>
          Rejuvenate your senses at Alleppey's luxury wellness centers and spas.


          </p>
        </strong>
        <br />
        <br />
        <br />
      </center>
      <center>
        {/* this is two templates */}

        <div className="hotel">
         

          <div className="h2" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg2">
              <img
                src={s1}
                alt=""
              />
              <div className="dp2">
                <br />
                <h4 style={{ textAlign: "start" }}>Ayurvedic Spa at Punnamada Resort</h4>
                <br />
                
                <p style={{ textAlign:"justify" }}>
                Immerse yourself in holistic wellness at the Ayurvedic spa, offering traditional treatments, yoga sessions, and relaxation by the tranquil waters.
                </p>
                <br />
                
              </div>
            </div>
          </div>
        </div>
      </center>
      

      <center>
        <h2 id="j"> EXCLUSIVE  EXPERIENCES </h2>
        <br />
        <strong>
          <p>
          Elevate your Alleppey journey with extraordinary activities:


          </p>
        </strong>
        <br />
        <br />
        <br />
      </center>

     
      <center>
        {/* this is two templates */}

        <div className="hotel">
          <div className="h1" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg1">
              <img
                src={e2}
                alt=""
              />
              <div className="dp1">
                <br />
                <h4 style={{ textAlign: "start" }}>Private Sunrise Houseboat Cruise</h4>
                <br />
                <p style={{ textAlign:"justify" }}>
                Arrange for a private sunrise houseboat cruise to witness the beauty of the backwaters bathed in the golden hues of dawn.
                </p>{" "}
                <br />
                
              </div>
            </div>
          </div>

          <div className="h2" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg2">
              <img
                src={e1}
                alt=""
              />
              <div className="dp2">
                <br />
                <h4 style={{ textAlign: "start" }}>Ayurvedic Wellness Retreat</h4>
                <br />
                <p style={{ textAlign:"justify" }}>
                Immerse yourself in a rejuvenating Ayurvedic wellness retreat, where expert therapies and holistic practices enhance your well-being.
                </p>{" "}
                <br />
               
              </div>
            </div>
          </div>
        </div>
      </center>
    </>
  );
}
