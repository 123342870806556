import React from "react";
import { AiFillYoutube } from "react-icons/ai";
import { BiLogoPinterestAlt } from "react-icons/bi";
import { AiOutlineInstagram } from "react-icons/ai";
import { BiLogoFacebookCircle } from "react-icons/bi";
import "./footer.css";
function Footer() {
  return (
    <>
      <div className="divl">
        <br />
        <div className="g2">
          <ul className="lis">
            <li className="lid">
              
              <h6 id="he2">SHARE YOUR TRAVELS WITH US</h6>
              <br />
              <div className="soc">
                <a href="https://www.youtube.com/channel/UCbM2gxv97zBBOLK-VRki2kQ" target="blank"  style={{color:"#999"}}>
                <p>
                  <AiFillYoutube className="icon" />
                </p>
                </a>
                <a href="https://instagram.com/enchantxp?igshid=NzZhOTFlYzFmZQ==" target="blank" style={{color:"#999"}}>
                <p>
                  <AiOutlineInstagram className="icon" />
                </p>
                </a>
                <a href="https://www.facebook.com/enchantxp" target="blank" style={{color:"#999"}}>
                <p>
                  <BiLogoFacebookCircle className="icon" />
                </p>
                </a>
              </div>
              <h6 id="he3"><a href="https://onelink.to/jyd663" style={{color:"#999"}}>Download The App</a></h6>

               <h6 id="he3" href="">Enchantxp</h6>
              {/* <h6 id="he4">SELECT THE COUNTRY</h6> */}
            </li>
           
            <li id="l3">
              <h6 id="he6">USEFUL INFORMATION</h6>

              <div className="dib">
                <a href="/about" style={{color:"#999"}}><p>About Us</p></a>
                
                {/* <p>Sustainabilty</p> */}
                <a href="/about1"  style={{color:"#999"}}><p>Online enquiry</p></a>
            {/* <a href="">
                <p>Frequently asked questions</p>
                </a> */}
                
                  <a href="/about1">
                <p style={{color:"#999"}}> Get in Touch</p>
                </a>
                
<a href="/about1" style={{color:"#999"}}>
                <p>Booking Conditions</p>
                </a>
                <a href="/privacy-policy" style={{color:"#999"}}>                <p>Privacy policy</p>
</a>
                
                <a href="/terms-conditions" style={{color:"#999"}}> <p>Terms And Conditions</p></a>
                <a href="/datacollection&protectionpolicy" style={{color:"#999"}}><p>Data Collection & Protection Policy</p>
</a>
              </div>
            </li>
            
            <li id="l4">
              <h6 id="he7">POPULAR DESTINATIONS</h6>
              <div className="DIV4">
                <a href="/singapore">
                <p  style={{color:'#999'}}>Singapore</p>
                </a>
                <a href="/alleppey">
                <p  style={{color:'#999'}}>Alleppey</p>
                </a>  <a href="/dubai">
                <p  style={{color:'#999'}}>Dubai</p>
                </a>  <a href="/shimla">
                <p  style={{color:'#999'}}>Shimla</p>
                </a>  <a href="jodhpur">
                <p  style={{color:'#999'}}>Jodhpur</p>
                </a>  <a href="/goa">
                <p  style={{color:'#999'}}>Goa</p>
                </a >  <a href="/australia">
                <p style={{color:'#999'}}>Australia</p>
                </a>
              </div>
            </li>
            <li style={{ flexBasis: "calc(20% - 20px)" }}>
              <h6 id="he8">EXPERIENCES</h6>
              <div className="div5">
                <p style={{ overflow: "hidden" }}>Family</p>
                <p style={{ overflow: "hidden" }}>Honeymoons</p>
                <p>Beaches</p>
                <p>Safari</p>
                <p>Adventures</p>
                <p>Staycation</p>
                
                  
              </div>
            </li>
          </ul>
          <div class="address" style={{textAlign:"center"}}>
              Enchant Experience Pte. Ltd.<br/>
      7 Temasek Boulevard, #12-07 Suntec Tower One<br/>
                Singapore, 038987<br/>
                  +65 8333 7861<br/>
</div>
    
    <div className="div">
      <p style={{textAlign:"end",color:"black"}}>&copy;&nbsp;2023,Cambliss Pvt. Ltd.</p>
    </div>


        </div>
      </div>
    </>
  );
}

export default Footer;
