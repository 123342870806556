import React, { useState } from "react";
import "./destination.css";
import a from "./images/Amalfi.jpg";
import t1 from "../Enchant images/Amalfi Coast/Breathtaking Coastal Adventures/istockphoto-1126056048-612x612.webp";
import i1 from "../Enchant images/Amalfi Coast/Breathtaking Coastal Adventures/Boat Excursions to Capri.jpg";
import i2 from "../Enchant images/Amalfi Coast/Breathtaking Coastal Adventures/Path of the Gods Hike.jpg";
import r1 from "../Enchant images/Amalfi Coast/Culinary Excellence with a Sea View/Don Alfonso 1890.jpg";
import r2 from "../Enchant images/Amalfi Coast/Culinary Excellence with a Sea View/La Sponda.jpg";
import h1 from "../Enchant images/Amalfi Coast/Luxurious Cliffside Retreats/Le Sirenuse amalfi coast.jpg";
import h2 from "../Enchant images/Amalfi Coast/Luxurious Cliffside Retreats/belmond hotel caruso amalfi coast.jpg";
import s1 from "../Enchant images/Amalfi Coast/Seaside Relaxation and Wellness/E'SPArt The Art of Espa at Monastero Santa Rosa.jpg";
import e1 from "../Enchant images/Amalfi Coast/Exclusive Experiences/Helicopter Tour over the Coastline.jpg";
import e2 from "../Enchant images/Amalfi Coast/Exclusive Experiences/Private Cooking Class.jpg";
import b1 from "../Enchant images/Amalfi Coast/High-End Shopping and Boutiques/Amalfi's Craftsmanship.jpg";
import b2 from "../Enchant images/Amalfi Coast/High-End Shopping and Boutiques/Positano Boutiques.jpg";
export default function Amalfi() {
  const [activeLink, setActiveLink] = useState("overview");

  const scrollToSection = () => {
    const targetSection = document.getElementById("j");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("itineraries");
  };
  const scrollToSection1 = () => {
    const targetSection = document.getElementById("r");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("locations");
  };
  const scrollToSection2 = () => {
    const targetSection = document.getElementById("k");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("hotels");
  };

  return (
    <>
      <div className="div">
        <div className="destination_header">
          <img id="s" src={a} alt="" />
          <div className="he">
            <h1>LUXURY HOLIDAYS & HONEYMOONS IN AMALFI COAST</h1>{" "}
          </div>
        </div>

        <center>
          <div className="nav2">
            <div className="div3">
              <a href="">
                <p
                  className={` ${
                    activeLink === "overview" ? "active-link" : ""
                  }`}
                >
                  OVERVIEW
                </p>
              </a>
              <a onClick={scrollToSection}>
                <p
                  className={` ${
                    activeLink === "itineraries" ? "active-link" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  ITINERARIES
                </p>
              </a>

              <a onClick={scrollToSection2}>
                <p
                  className={` ${activeLink === "hotels" ? "active-link" : ""}`}
                  style={{ cursor: "pointer" }}
                >
                  HOTELS
                </p>
              </a>
              <a onClick={scrollToSection1}>
                <p
                  style={{ cursor: "pointer" }}
                  className={` ${
                    activeLink === "locations" ? "active-link" : ""
                  }`}
                >
                  RESTAURANTS
                </p>
              </a>
            </div>
          </div>
        </center>

        <br />
      

        <div className="div4">
          <div className="sec1">
            <br />
           
            <h2>
              WHY SHOULD YOU TRAVEL TO AMALFI COAST WITH US?
              <br />
              
            </h2>
            <br/>
            <p style={{textAlign:"justify"}}>
              Welcome to the stunning Amalfi Coast, where dramatic landscapes,
              azure waters, and upscale experiences combine to offer a luxurious
              and breathtaking escape. From charming coastal towns to exquisite
              cuisine and captivating views, the Amalfi Coast presents a perfect
              blend of opulence and coastal charm. Let's explore the world of
              luxury that awaits you in this enchanting destination. The Amalfi
              Coast, with its luxurious retreats, coastal adventures, and
              authentic charm, offers an unforgettable and opulent escape that
              captures both relaxation and natural beauty. From staying in
              elegant accommodations to savoring gourmet cuisine and engaging in
              curated activities, the Amalfi Coast promises an experience that
              embodies the essence of luxury and the allure of coastal allure.
            </p>
          </div>
          <div className="sec2">
            <br />
            <br />
            <img src={t1} alt="" />
          </div>
        </div>

        <div className="div5" style={{ background: "#fafafa" }}>
         <br/>  <br/>
          <center>
            <h2 id="j">RECOMMENDED ITINERARIES IN AMALFI COAST</h2>
            <br />
            <strong>
              <p>
                Experience the mesmerizing coastal beauty of the Amalfi Coast
                through curated activities.
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>

          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={i1} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>
                      Boat Excursions to Capri
                    </h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                      Embark on a private boat excursion to the island of Capri,
                      where you can explore hidden grottoes, swim in
                      crystal-clear waters, and enjoy exclusive coastal views.
                    </p>
                    <br />
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={i2} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>
                      {" "}
                      Path of the Gods Hike
                    </h4>
                    <br />
                    
                    <p style={{textAlign:"justify"}}>
                      Discover the Amalfi Coast's stunning vistas with a guided
                      hike along the Path of the Gods, a panoramic trail that
                      offers breathtaking views of the coastline.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </center>

         
          <center>
            <h2 id="r">RECOMMENDED RESTAURANTS IN AMALFI COAST</h2>
            <br />
            <strong>
              <p>
                Indulge in a culinary journey that celebrates the Amalfi Coast's
                gourmet dining scene.
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>
          {/* <div className="fhotel">
            <div className="d1">
              <img
                src="https://cdn.pixabay.com/photo/2016/11/18/14/05/brick-wall-1834784_1280.jpg"
                alt=""
              />
            </div>
            <div
              className="d2"
              style={{
                background: "white",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              <br />
              <div className="d21">
                <h4>Taj Exotica Goa</h4>
                <br />
                <br />
                <br />
                <p style={{ textAlign: "start" }}>
                  Set amidst palm-fringed gardens and overlooking the Arabian
                  Sea, Taj Exotica is an oasis of luxury. With spacious villas,
                  impeccable service, and stunning sea views, this resort
                  promises an unforgettable stay.
                </p>
                <br /> <br />
                <p
                  style={{
                    textAlign: "end",
                    fontWeight: "600",
                    fontSize: "14px",
                    letterSpacing: "2px",
                  }}
                >
               
                </p>
              </div>
            </div>
          </div> */}
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={r1} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}> Don Alfonso 1890</h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                      Savor gourmet dishes at Don Alfonso 1890 in Sant'Agata sui
                      Due Golfi, a Michelin-starred restaurant offering creative
                      cuisine inspired by local ingredients.
                    </p>
                    <br />
                    <p
                      style={{
                        textAlign: "end",
                        fontWeight: "600",
                        fontSize: "14px",
                        letterSpacing: "2px",
                      }}
                    ></p>
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={r2} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>La Sponda</h4>
                    <br />
                 
                    <p style={{textAlign:"justify"}}>
                      Enjoy a romantic dining experience at La Sponda in
                      Positano, where Mediterranean flavors are complemented by
                      stunning sea views and a candlelit ambiance.
                    </p>
                    <br />
                    <p
                      style={{
                        textAlign: "end",
                        fontWeight: "600",
                        fontSize: "14px",
                        letterSpacing: "2px",
                      }}
                    >
                      {/* Experience{">"}View */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </center>
          
          <center>
            <h2 id="k">LUXURIOUS CLIFFSIDE RETREATS</h2>
            <br />
            <strong>
              <p>
                The Amalfi Coast boasts a selection of luxurious cliffside
                hotels and villas that provide the perfect blend of comfort and
                panoramic views
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>

          {/* <div className="fhotel">
            <div className="d1">
              <img
                src="https://i0.wp.com/www.thelodhi.com/wp-content/uploads/sites/3/2020/10/room10-slider2-1.jpg?w=1300&ssl=1"
                alt=""
              />
            </div>
            <div
              className="d2"
              style={{
                background: "white",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                height: "270px",
              }}
            >
              <br />
              <div className="d21">
                <h4>Vinayak Family Restaurant</h4>
                <br />
                <p style={{ textAlign: "start" }}>
                  Experience traditional Goan cuisine at its finest. Savour
                  delectable seafood dishes, like prawn balchão and xacuti, in a
                  rustic yet luxurious setting.
                </p>
                <br /> <br />
                <p
                  style={{
                    textAlign: "end",
                    fontWeight: "600",
                    fontSize: "14px",
                    letterSpacing: "2px",
                  }}
                >
               
                </p>
              </div>
            </div>
          </div> */}
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={h2} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Belmond Hotel Caruso</h4>
                    <br />
                    <p style={{textAlign:"justify"}}>
                      Immerse yourself in luxury at Belmond Hotel Caruso,
                      offering opulent suites, infinity pools with stunning
                      vistas, and a serene atmosphere in Ravello.
                    </p>{" "}
                    <br />
                    <p
                      style={{
                        textAlign: "end",
                        fontWeight: "600",
                        fontSize: "14px",
                        letterSpacing: "2px",
                      }}
                    ></p>
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={h1} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Le Sirenuse</h4>
                    <br />
                    <p style={{textAlign:"justify"}}>
                      Experience elegance at Le Sirenuse in Positano, where
                      charming rooms, exquisite dining, and views of the
                      Mediterranean create an intimate ambiance.
                    </p>{" "}
                    <br />
                    <p
                      style={{
                        textAlign: "end",
                        fontWeight: "600",
                        fontSize: "14px",
                        letterSpacing: "2px",
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </center>
        </div>
      </div>
     
      <center>
        <h2 id="k"> SEA SIDE WELLNESS AND RELAXATION</h2>
        <br />
        <strong>
          <p>
            Rejuvenate your senses at the Amalfi Coast's luxury wellness centers
            and spas.
          </p>
        </strong>
        <br />
        <br />
        <br />
      </center>
      <center>
        {/* this is two templates */}

        <div className="hotel">
          <div className="h2" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg2">
              <img src={s1} alt="" />
              <div className="dp2">
                <br />
                <h4 style={{ textAlign: "start" }}>E'SPArt </h4>
                <br />
             
                <p style={{textAlign:"justify"}}>
                  The Art of Espa at Monastero Santa Rosa: Immerse yourself in
                  holistic wellness at E'SPArt, offering a range of treatments
                  inspired by Mediterranean traditions and modern relaxation
                  techniques.
                </p>{" "}
                <br />
                <p
                  style={{
                    textAlign: "end",
                    fontWeight: "600",
                    fontSize: "14px",
                    letterSpacing: "2px",
                  }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      </center>
     

      <center>
        <h2 id="j">EXCLUSIVE EXPERIENCES</h2>
        <br />
        <strong>
          <p>Elevate your Amalfi Coast journey with extraordinary activities</p>
        </strong>
        <br />
        <br />
        <br />
      </center>

      <center>
        {/* this is two templates */}

        <div className="hotel">
          <div className="h1" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg1">
              <img src={e2} alt="" />
              <div className="dp1">
                <br />
                <h4 style={{ textAlign: "start" }}>Private Cooking Class</h4>
                <br />
                <p style={{textAlign:"justify"}}>
                  Learn the secrets of traditional Amalfi Coast cuisine with a
                  private cooking class, guided by a local chef using fresh
                  local ingredients.
                </p>{" "}
                <br />
                <p
                  style={{
                    textAlign: "end",
                    fontWeight: "600",
                    fontSize: "14px",
                    letterSpacing: "2px",
                  }}
                ></p>
              </div>
            </div>
          </div>

          <div className="h2" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg2">
              <img src={e1} alt="" />
              <div className="dp2">
                <br />
                <h4 style={{ textAlign: "start" }}>
                  Helicopter Tour over the Coastline
                </h4>
                <br />
                <p style={{textAlign:"justify"}}>
                  Soar above the coast in a helicopter, experiencing
                  breathtaking aerial views of the Amalfi Coast's rugged cliffs,
                  charming villages, and azure waters.
                </p>{" "}
                <br />
                <p
                  style={{
                    textAlign: "end",
                    fontWeight: "600",
                    fontSize: "14px",
                    letterSpacing: "2px",
                  }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      </center>
 
      <center>
        <h2 id="k">HIGH-END SHOPPING AND BOUTIQUES</h2>
        <br />
        <strong>
          <p>
            Experience the Amalfi Coast's upscale shopping scene with designer
            boutiques and local crafts
          </p>
        </strong>
        <br />
        <br />
        <br />
      </center>
      <center>
        {/* this is two templates */}

        <div className="hotel">
          <div className="h1" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg1">
              <img src={b2} alt="" />
              <div className="dp1">
                <br />
                <h4 style={{ textAlign: "start" }}> Positano Boutiques</h4>
                <br />
           
                <p style={{textAlign:"justify"}}>
                  Explore Positano's charming boutiques, offering stylish
                  coastal fashion, handmade ceramics, and artisanal products.
                </p>
                <br />
                <p
                  style={{
                    textAlign: "end",
                    fontWeight: "600",
                    fontSize: "14px",
                    letterSpacing: "2px",
                  }}
                ></p>
              </div>
            </div>
          </div>

          <div className="h2" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg2">
              <img src={b1} alt="" />
              <div className="dp2">
                <br />
                <h4 style={{ textAlign: "start" }}>Amalfi's Craftsmanship</h4>
                <br />
               
                <p style={{textAlign:"justify"}}>
                  Immerse yourself in local craftsmanship in Amalfi, where you
                  can find handmade paper, limoncello, and intricate woodwork.
                </p>
                <br />
                <p
                  style={{
                    textAlign: "end",
                    fontWeight: "600",
                    fontSize: "14px",
                    letterSpacing: "2px",
                  }}
                >
                  {/* Experience{">"}View */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </center>
    </>
  );
}
