import React, { useState } from "react";
import "./destination.css";
import i from "./images/Milan.jpg";
import i1 from "../Enchant images/Milan/Artistic Treasures and Architectural Marvels/The Last Supper by Leonardo da Vinci.jpg";
import i2 from "../Enchant images/Milan/Artistic Treasures and Architectural Marvels/Duomo di Milano.jpg";
import b1 from "../Enchant images/Milan/High-Fashion Shopping and Boutiques/Quadrilatero della Moda.jpg";
import b2 from "../Enchant images/Milan/High-Fashion Shopping and Boutiques/Galleria Vittorio Emanuele II.jpg";
import h1 from "../Enchant images/Milan/Luxurious Accommodations/Bulgari Hotel Milan.jpg";
import h2 from "../Enchant images/Milan/Luxurious Accommodations/Armani Hotel Milano.jpg";
import r1 from "../Enchant images/Milan/Culinary Excellence/Il Luogo di Aimo e Nadia.jpg";
import r2 from "../Enchant images/Milan/Culinary Excellence/Pasticceria Marchesi.jpg";
import e1 from "../Enchant images/Milan/Exclusive Experiences/Private Fashion Tour.jpg";
import e2 from "../Enchant images/Milan/Exclusive Experiences/Helicopter Tour over.jpg";
import s1 from "../Enchant images/Milan/Wellness and Relaxation/Bvlgari Spa Milan.jpg";
import t1 from "../Enchant images/Milan/Opera and Cultural Performances/Teatro alla Scala.jpg";
export default function Milan() {
  const [activeLink, setActiveLink] = useState("overview");

  const scrollToSection = () => {
    const targetSection = document.getElementById("j");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("itineraries");
  };
  const scrollToSection1 = () => {
    const targetSection = document.getElementById("r");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("locations");
  };
  const scrollToSection2 = () => {
    const targetSection = document.getElementById("k");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("hotels");
  };

  return (
    <>
      <div className="div">
        <div className="destination_header">
          <img id="s" src={i} alt="" />
          <div className="he">
            <h1>EXPERIENCE ELEGANCE & FASHION IN MILAN</h1>
          </div>
        </div>

        <center>
          <div className="nav2">
            <div className="div3">
              <a href="">
                <p
                  className={` ${
                    activeLink === "overview" ? "active-link" : ""
                  }`}
                >
                  OVERVIEW
                </p>
              </a>
              <a onClick={scrollToSection}>
                <p
                  className={` ${
                    activeLink === "itineraries" ? "active-link" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  ITINERARIES
                </p>
              </a>

              <a onClick={scrollToSection2}>
                <p
                  className={` ${activeLink === "hotels" ? "active-link" : ""}`}
                  style={{ cursor: "pointer" }}
                >
                  HOTELS
                </p>
              </a>
              <a onClick={scrollToSection1}>
                <p
                  style={{ cursor: "pointer" }}
                  className={` ${
                    activeLink === "locations" ? "active-link" : ""
                  }`}
                >
                  RESTAURANTS
                </p>
              </a>
            </div>
          </div>
        </center>

        <br />
    

        <div className="div4">
          <div className="sec1">
            <br />
           
            <h2>
              WHY SHOULD YOU TRAVEL TO MILAN WITH US?
              <br />
             
            </h2>
            <br />
            <p style={{textAlign:"justify"}}>
              Welcome to the vibrant city of Milan, where fashion, culture, and
              luxury experiences merge to offer an elegant and cosmopolitan
              escape. From high-end shopping districts to historic architecture
              and world-class cuisine, Milan presents a perfect blend of
              opulence and artistic allure. Let's explore the world of luxury
              that awaits you in this captivating destination. Milan, with its
              luxurious hotels, fashion scene, and cultural treasures, offers a
              sophisticated and captivating escape that captures both modernity
              and historical charm. From staying in elegant accommodations to
              savoring gourmet cuisine and engaging in curated activities, Milan
              promises an unforgettable journey that embodies the essence of
              luxury and the allure of cosmopolitan living.
            </p>
          </div>
          <div className="sec2">
            
            <br />
            <img src={t1} alt="" />
          </div>
        </div>

        <div className="div5" style={{ background: "#fafafa" }}>
          <br />
          <br />
          
          <center>
            <h2 id="j">
            LET'S EXPLORE THE WORLD OF LUXURY THAT AWAITS YOU IN THIS CAPTIVATING DESTINATION!
            </h2>
            <br />
            <strong>
              <p>
                The ideal time for a visit to Milan spans from April through
                October.
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>

          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={i1} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>
                      The Last Supper by Leonardo da Vinci
                    </h4>
                   
                    <br />
                    <p style={{textAlign:"justify"}}>
                      Explore the iconic painting "The Last Supper" at the
                      Convent of Santa Maria delle Grazie, a UNESCO World
                      Heritage site.
                    </p>
                    <br />
                   
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={i2} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Duomo di Milano</h4>
                    <br />
                    
                    <p style={{textAlign:"justify"}}>
                      Discover the grandeur of the Duomo di Milano, a
                      magnificent cathedral adorned with intricate architecture
                      and panoramic views from its rooftop.
                    </p>
                    <br />
                  
                  </div>
                </div>
              </div>
            </div>
          </center>

        

          <center>
            <h2 id="k">HIGH-FASHION SHOPPING AND BOUTIQUES</h2>
            <br />
            <strong>
              <p>
                Indulge in Milan's reputation as a global fashion capital with
                upscale boutiques and designer stores
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>

          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={b1} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>
                      Quadrilatero della Moda
                    </h4>
                    <br />
                  
                    <p style={{textAlign:"justify"}}>
                      Explore the Quadrilatero della Moda, Milan's renowned
                      fashion district, home to luxury brands, high-end
                      boutiques, and flagship stores
                    </p>
                    <br />
                   
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={b2} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>
                      Galleria Vittorio Emanuele II
                    </h4>
                    
                    <br />
                    <p style={{textAlign:"justify"}}>
                      Immerse yourself in luxury shopping at Galleria Vittorio
                      Emanuele II, an iconic shopping arcade where you can find
                      designer labels and chic cafes.
                    </p>
                    <br />
                  
                  </div>
                </div>
              </div>
            </div>
          </center>

        
          <center>
            <h2 id="k">LUXURIOUS ACCOMODATIONS</h2>
            <br />
            <strong>
              <p>
                Milan boasts a selection of luxurious hotels that provide the
                perfect blend of comfort and style.
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>

          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={h1} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Bulgari Hotel Milan</h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                      Immerse yourself in luxury at Bulgari Hotel, offering
                      exquisite rooms, impeccable service, and a central
                      location near Milan's iconic attractions.
                    </p>
                    <br />
                  
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={h2} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Armani Hotel Milano</h4>
                    
                    <br />
                    <p style={{textAlign:"justify"}}>
                      Experience modern elegance at Armani Hotel Milano, where
                      designer interiors, personalized service, and gourmet
                      dining create a refined atmosphere.
                    </p>
                    <br />
                 
                  </div>
                </div>
              </div>
            </div>
          </center>

        
          <center>
            <h2 id="r">CULINARY EXCELLENCE</h2>
            <br />
            <strong>
              <p>
                Savor a culinary journey that showcases Milan's gourmet dining
                scene
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>

          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={r1} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>
                      Il Luogo di Aimo e Nadia
                    </h4>
                    <br />
                    <p style={{textAlign:"justify"}}>
                      Indulge in gourmet dishes at Il Luogo di Aimo e Nadia, a
                      Michelin-starred restaurant known for its innovative
                      Italian cuisine and refined atmosphere.
                    </p>{" "}
                    <br />
                 
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={r2} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Pasticceria Marchesi</h4>
                    <br />
                    <p style={{textAlign:"justify"}}>
                      Enjoy a taste of elegance at Pasticceria Marchesi, a
                      historic pastry shop where you can savor artisanal sweets
                      in a sophisticated setting.
                    </p>{" "}
                    <br />
                   
                  </div>
                </div>
              </div>
            </div>
          </center>
        </div>

        <center>
          <h2 id="k">WELLNESS AND RELAXATION</h2>
          <br />
          <strong>
            <p>
              Rejuvenate your senses at Milan's luxury wellness centers and
              spas.
            </p>
          </strong>
          <br />
          <br />
          <br />
        </center>
        <center>
          {/* this is two templates */}

          <div className="hotel">
            <div className="h2" style={{ padding: "10px", height: "400px" }}>
              <div className="dimg2">
                <img src={s1} alt="" />
                <div className="dp2">
                  <br />
                  <h4 style={{ textAlign: "start" }}> Bvlgari Spa Milan</h4>
                  <br />
                  
                  <p style={{textAlign:"justify"}}>
                    Immerse yourself in holistic wellness at Bvlgari Spa,
                    offering a range of treatments inspired by ancient Roman
                    traditions and modern relaxation techniques.
                  </p>
                  <br />
                
                </div>
              </div>
            </div>
          </div>
        </center>
      

        <center>
          <h2 id="j">EXCLUSIVE EXPERIENCES</h2>
          <br />
          <strong>
            <p>Elevate your Milan journey with extraordinary activities:</p>
          </strong>
          <br />
          <br />
          <br />
        </center>

        <center>
          {/* this is two templates */}

          <div className="hotel">
            <div className="h1" style={{ padding: "10px", height: "400px" }}>
              <div className="dimg1">
                <img src={e1} alt="" />
                <div className="dp1">
                  <br />
                  <h4 style={{ textAlign: "start" }}>Private Fashion Tour</h4>
                  <br />
                  <p style={{textAlign:"justify"}}>
                    Enjoy a personalized fashion tour with a local expert who
                    will guide you through Milan's style scene, from hidden
                    boutiques to exclusive showrooms.
                  </p>{" "}
                  <br />
                 
                </div>
              </div>
            </div>

            <div className="h2" style={{ padding: "10px", height: "400px" }}>
              <div className="dimg2">
                <img src={e2} alt="" />
                <div className="dp2">
                  <br />
                  <h4 style={{ textAlign: "start" }}>
                    Helicopter Tour over Milan
                  </h4>
                  <br />
                  <p style={{textAlign:"justify"}}>
                    Soar above the city in a helicopter, experiencing
                    breathtaking aerial views of Milan's architectural landmarks
                    and vibrant neighborhoods.
                  </p>{" "}
                  <br />
                 
                </div>
              </div>
            </div>
          </div>
        </center>
        <br />
        <br />

      
      </div>
    </>
  );
}
