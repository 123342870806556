import React, { useState } from "react";
import "./destination.css";
import t2 from "../Enchant images/Bali/Cultural and natural exploration/bali-2975787_1280.jpg";
import i from "./images/Bali.jpg";
import i1 from "../Enchant images/Bali/Cultural and natural exploration/Tegallalang Rice Terraces.jpg";
import i2 from "../Enchant images/Bali/Cultural and natural exploration/Uluwatu Temple.jpg";
import r1 from "../Enchant images/Bali/Culinary Delights/Kayuputi.jpg";
import r2 from "../Enchant images/Bali/Culinary Delights/Mozaic Restaurant Gastronomique.jpg";
import h1 from "../Enchant images/Bali/Luxurious beachfront resort/Amanusa.jpg";
import h2 from "../Enchant images/Bali/Luxurious beachfront resort/The Mulia Bali.jpg";
import s1 from "../Enchant images/Bali/Wellness and Relaxation/COMO Shambhala Retreat.jpg";
import e1 from "../Enchant images/Bali/Exclusive experience/Private Beach Picnic.jpg";
import e2 from "../Enchant images/Bali/Exclusive experience/Helicopter Tour.jpg";
import t1 from "../Enchant images/Bali/High-end shopping/Seminyak.jpg";
export default function Bali() {
  const [activeLink, setActiveLink] = useState("overview");

  const scrollToSection = () => {
    const targetSection = document.getElementById("j");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("itineraries");
  };
  const scrollToSection1 = () => {
    const targetSection = document.getElementById("r");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("locations");
  };
  const scrollToSection2 = () => {
    const targetSection = document.getElementById("k");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("hotels");
  };

  return (
    <>
      <div className="div">
        <div className="destination_header">
          <img id="s" src={i} alt="" />
          <div className="he">
            <h1>LUXURY HOLIDAYS & HONEYMOONS IN BALI</h1>
          </div>
        </div>

        <center>
          <div className="nav2">
            <div className="div3">
              <a href="">
                <p
                  className={` ${
                    activeLink === "overview" ? "active-link" : ""
                  }`}
                >
                  OVERVIEW
                </p>
              </a>
              <a onClick={scrollToSection}>
                <p
                  className={` ${
                    activeLink === "itineraries" ? "active-link" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  ITINARERIES
                </p>
              </a>

              <a onClick={scrollToSection2}>
                <p
                  className={` ${activeLink === "hotels" ? "active-link" : ""}`}
                  style={{ cursor: "pointer" }}
                >
                  HOTELS
                </p>
              </a>
              <a onClick={scrollToSection1}>
                <p
                  style={{ cursor: "pointer" }}
                  className={` ${
                    activeLink === "locations" ? "active-link" : ""
                  }`}
                >
                  RESTAURANTS
                </p>
              </a>
            </div>
          </div>
        </center>

        <br />
     

        <div className="div4">
          <div className="sec1">
            <br />
           
            <h2>
              WHY SHOULD YOU TRAVEL TO BALI WITH US?
              <br />
             
            </h2>
            <br/>
            <p style={{textAlign:"justify"}}>
              Welcome to the tropical paradise of Bali, where lush landscapes,
              rich culture, and upscale experiences come together to offer a
              luxurious and enchanting escape. Known for its breathtaking
              beaches, serene temples, and luxurious resorts, Bali presents a
              blend of opulence and natural beauty. Let's explore the world of
              luxury that awaits you in this captivating destination. Bali, with
              its luxurious beachfront resorts, cultural experiences, and
              natural beauty, offers an enchanting and opulent escape that
              captures both relaxation and exploration. From staying in elegant
              accommodations to savoring gourmet cuisine and engaging in curated
              activities, Bali promises an unforgettable journey that embodies
              the essence of luxury and the allure of tropical paradise
            </p>
          </div>
          <div className="sec2">
            <br />
            <br />
            <img src={t2} alt="" />
          </div>
        </div>

        <div className="div5" style={{ background: "#fafafa" }}>
          <br />
          <br />
          <br />
          <center>
            <h2 id="j">RECOMMENDED ITINARERIES IN BALI</h2>
            <br />
            <strong>
              <p>
                Engage with Bali's rich culture and natural beauty through
                curated experiences:
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>
          <div className="fhotel">
            <div className="d1">
              <img src={i2} alt="" />
            </div>
            <div
              className="d2"
              style={{
                background: "white",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              <br />
              <div className="d21">
                <h4>Uluwatu Temple</h4>
                <br />
               
                <p style={{textAlign:"justify"}}>
                  Visit the iconic Uluwatu Temple, a clifftop masterpiece that
                  overlooks the Indian Ocean and hosts traditional Kecak dance
                  performances.
                </p>
                <br /> <br />
              </div>
            </div>
          </div>
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={i1} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>
                      Tegallalang Rice Terraces
                    </h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                      Discover the beauty of Tegallalang Rice Terraces, a UNESCO
                      World Heritage site that showcases Bali's agricultural
                      landscape and intricate irrigation systems.
                    </p>
                    <br />
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={t1} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Seminyak</h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                      Explore Seminyak's fashion-forward boutiques, art
                      galleries, and designer stores, offering a blend of local
                      craftsmanship and international brands.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </center>

          <br />
        
          <center>
            <h2 id="k">RECOMMENDED HOTELS IN BALI</h2>
            <br />
            <strong>
              <p>
                Bali boasts a collection of luxurious beachfront resorts that
                provide a perfect blend of comfort and stunning ocean views.
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>

          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={h1} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Amanusa</h4>
                    <br />
                  
                    <p style={{textAlign:"justify"}}>
                      Immerse yourself in luxury at Amanusa, offering elegant
                      villas with private pools, personalized service, and
                      impeccable amenities amidst the tranquility of Nusa Dua.{" "}
                    </p>
                    <br />
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={h2} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>The Mulia Bali</h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                      Experience beachfront opulence at The Mulia Bali, where
                      expansive suites, pristine beaches, and gourmet dining
                      create an atmosphere of relaxation and indulgence.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </center>
          <br />
    
          <center>
            <h2 id="r">RECOMMENDED RESTAURANTS IN BALI</h2>
            <br />
            <strong>
              <p>
                Indulge in a culinary journey that celebrates local Balinese
                flavors and international cuisines.
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>

          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={r1} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Kayuputi</h4>
                    <br />
                    <p style={{textAlign:"justify"}}>
                      Enjoy a fusion of Asian and Western flavors at Kayuputi,
                      the signature beachfront restaurant known for its
                      degustation menus and panoramic ocean views.
                    </p>{" "}
                    <br />
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={r2} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>
                      Mozaic Restaurant Gastronomique
                    </h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                      Savor gourmet dishes at Mozaic, where Michelin-starred
                      cuisine inspired by Balinese ingredients awaits in an
                      intimate garden setting.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </center>
        </div>
      </div>
      <br />
   
      {/* ............................................................................. */}

      <center>
        <h2 id="k">WELLNESS AND RELAXATION</h2>
        <br />
        <strong>
          <p>
            Rejuvenate your senses at Bali's luxury wellness centers and spas.
          </p>
        </strong>
        <br />
        <br />
        <br />
      </center>
      <center>
        {/* this is two templates */}

        <div className="hotel">
          <div className="h2" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg2">
              <img src={s1} alt="" />
              <div className="dp2">
                <br />
                <h4 style={{ textAlign: "start" }}>COMO Shambhala Retreat</h4>
                <br />
                
                <p style={{textAlign:"justify"}}>
                  Immerse yourself in holistic wellness at COMO Shambhala
                  Retreat, offering traditional Balinese therapies, yoga
                  sessions, and relaxation in a serene jungle setting.
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
      </center>
      <br />
     
      {/* .........................................................................
       */}

      <center>
        <h2 id="j"> EXCLUSIVE EXPERIENCES</h2>
        <br />
        <strong>
          <p>Elevate your Bali journey with extraordinary activities:</p>
        </strong>
        <br />
        <br />
        <br />
      </center>

      <center>
        {/* this is two templates */}

        <div className="hotel">
          <div className="h1" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg1">
              <img src={e1} alt="" />
              <div className="dp1">
                <br />
                <h4 style={{ textAlign: "start" }}>Private Beach Picnic</h4>
                <br />
                <p style={{textAlign:"justify"}}>
                  Arrange for a private beach picnic, complete with gourmet
                  cuisine and personalized service, allowing you to enjoy Bali's
                  natural beauty in secluded luxury.
                </p>{" "}
                <br />
              </div>
            </div>
          </div>

          <div className="h2" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg2">
              <img src={e2} alt="" />
              <div className="dp2">
                <br />
                <h4 style={{ textAlign: "start" }}>Helicopter Tour</h4>
                <br />
                <p style={{textAlign:"justify"}}>
                  Soar above Bali in a helicopter for a breathtaking aerial view
                  of its stunning landscapes, including rice terraces,
                  volcanoes, and coastal vistas.
                </p>{" "}
                <br />
              </div>
            </div>
          </div>
        </div>
      </center>
    </>
  );
}
