import React, { useEffect, useState } from "react";
import { HiOutlineLocationMarker } from "react-icons/hi";
import "./all.css";
import { useLocation } from "react-router-dom";

import agr from './taj-mahal-866692_1280 (1).jpg'
import mau from './Mauritius.jpg'
import vis from './Vishakhapatnam.jpg'
import goa from './Goa.jpg'
import mum from './Mumbai.jpg'
import mun from './Munnar.jpg'
import gul from "./Gulmarg.jpg";
import jod from './Jodhpur.jpg'
import all from "./Alleppey (1).jpg";
import an from "./Andaman.jpg";
import ba from "./images/Bali.jpg";
import dal from "./Dalhousie.jpg";
import di from "./Diu.jpg";
import dub from "./Dubai.jpg";
import gok from "./Gokarna.jpg";
import gan from "./Gangtok.jpg";
import guw from "./Guwahati.jpg";
import kuma from "./Kumarakom.jpg";
import mal from "./Maldives (1).jpg";
import shi from './Shimla.jpg'
import rant from "./Ranthambore.jpg";
import sing from "./Singapore.jpg";
import srin from "./Srinagar.jpg";
import tur from "./Turkey.jpg";
import uda from "./Udaipur.jpg";
import ama from "./images/Amalfi.jpg";
import ams from "./Amsterdam.jpg";
import aus from "./Australia.jpg";
import bel from "./Belgium.jpg";
import bor from "./Bora Bora.jpg";
import can from "./Cancun.jpg";
import cap from "./Cape Town.jpg";
import cro from "./Croatia.jpg";
import egy from "./Egypt.jpg";
import fin from "./Finland.jpg";
import liz from "./Lizard Island.jpg";
import lon from "./London.jpg";
import mil from "./Milan.jpg";
import par from "./Paris.jpg";
import rom from "./Rome.jpg";
import sco from "./Scotland.jpg";
import sey from "./Seychelles.jpg";
import saint from "./St Lucia.jpg";
import swis from "./Switzerland.jpg";
import masai from "./The Maasai Mara.jpg";
import tul from "./Tulum.jpg";
import vie from "./Vietnam.jpg";
import mia from './Miami.jpg'
import mar from './Marrakech.jpg'
import del from './Delhi1.jpg'


export default function Bymonths(){
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedMonth = queryParams.get('month');
  console.log(selectedMonth);

  const Data = [
        {
          id: 1,
          imgsrc:del,
           
          destTitle: "New Delhi",
          location: "India",
          grade: "Cultaral Relax",
          fees: 100,
          bestmonths:['November', 'December', 'January', 'February'] ,
          description:
            "Delhi, a city of contrasts, seamlessly merges historic grandeur with contemporary dynamism. As the heart of India, it boasts iconic landmarks like the Red Fort and India Gate. Amidst the bustling streets, find luxury accommodations, diverse culinary experiences, and high-end shopping.",
        },
        {
          id: 2,
          imgsrc:
            jod,
          destTitle: "Jodhpur",
          location: "India",
          grade: "Cultaral Relax",
          fees: 200,
          bestmonths:['November', 'December', 'January', 'February'] ,

          description:
            "In the heart of Rajasthan's Thar Desert, Jodhpur stands as an oasis of regal magnificence. The city's majestic Mehrangarh Fort dominates the landscape, while its azure-painted houses evoke a sense of enchantment. Experience luxury in heritage hotels, savor Rajasthani cuisine, ",
        },
        {
          id: 3,
          imgsrc:
              agr,
          destTitle: "Agra",
          location: "India",
          grade: "Cultaral Relax",
          fees: 300,
          bestmonths:['November', 'December', 'January', 'February','March'] ,

          description:
            "Welcome to the iconic city of Agra, home to one of the world's most famous landmarks, the Taj Mahal. With its historical significance, opulent accommodations, and cultural richness, Agra offers a luxurious journey into India's royal heritage.Let's explore the world of luxury that awaits you in this captivating destination.",
        },
        {
          id: 4,
          imgsrc:
            goa,
          destTitle: "Goa",
          location: "India",
          grade: "Cultaral Relax",
          fees: 400,
          bestmonths:['November', 'December', 'January', 'February','March'] ,

          description:
            "Welcome to the ultimate guide to luxury travel in Goa, India's most sought-after destination for sun-soaked beaches, vibrant culture, and indulgent experiences. Whether you're looking for opulent resorts, exquisite dining, or rejuvenating spa treatments, Goa has it all.. ",
        },
        {
          id: 5,
          imgsrc:
            shi,
          destTitle: "Shimla",
          location: "India",
          grade: "Cultaral Relax",
          fees: 700,
          bestmonths:['March', 'April', 'May', 'June'],
          description:
            "Welcome to the picturesque hill station of Shimla, where the lush green landscapes, colonial charm offer a haven of luxury and relaxation. Nestled amidst the Himalayan foothills, Shimla is a perfect blend of natural beauty and refined experiences.",
        },
        {
          id: 6,
          imgsrc:
               mum,
          destTitle: `Mumbai`,
          location: "India",
          grade: "Cultaral Relax",
          fees: 600,
          bestmonths:['October','November', 'December', 'January', 'February','March'] ,

          description:
            "Explore Mumbai, a metropolis where glamour, culture, and luxury converge for an exquisite experience. As India's financial hub and a cultural melting pot, Mumbai offers upscale accommodations, gourmet dining, and exclusive experiences.",
        },
        {
          id: 7,
          imgsrc: cro,
          destTitle: "Croatia",
          location: "Croatia",
          grade: "Cultaral Relax",
          fees: 688,
          bestMonths: ["May", "June", "July", "August", "September", "October"],

          description:
            "Hyderabad, a city steeped in rich history and vibrant culture, welcomes tourists with open arms. Explore the iconic Charminar, savor the famed Hyderabadi cuisine, and immerse yourself in the blend of tradition and modernity that defines metropolis. ",
        },
        {
          id: 8,
          imgsrc: bel,
          destTitle: "Belgium",
          location: " Belgium",
          grade: "Cultaral Relax",
          fees: 899,
          bestMonths: ['April',"May", "June", "July", "August", "September", "October"],

          description:
            "Bangalore, known as the Silicon Valley of India, invites tourists to experience its dynamic blend of innovation and green spaces. Explore lush parks,  and indulge in a diverse culinary tapestry. Bangalore offers a glimpse into its cosmopolitan charm and vibrant urban life.",
        },
        {
          id: 9,
          imgsrc: sco,
          destTitle: "Scotland",
          location: "Scotland",
          grade: "Cultaral Relax",
          fees: 261,
          bestMonths: ["May", "June", "July", "August", "September"],

          description:
            "Chennai, a coastal gem on the Bay of Bengal, invites tourists to explore its unique blend of tradition and modernity. Discover the iconic Marina Beach, delve into the city's artistic heritage at museums and galleries, and savor South Indian delicacies that tantalize the taste buds. ",
        },
        {
          id: 10,
          imgsrc: swis,
          destTitle: "Switzerland",
          location: "Switzerland",
          grade: "Cultaral Relax",
          fees: 5682,
          bestMonths: ['April',"May", "June", "July", "August", "September"],

          description:
            "Kolkata, the cultural capital of India, welcomes tourists with its artistic fervor and historical significance. Immerse yourself in its colonial architecture. From the bustling Howrah Bridge to the tranquility of the Maidan, Kolkata offers a sensory journey through its literary heritage,",
        },
        {
          id: 11,
          imgsrc:
               vis,
          destTitle: "Vishakapatnam",
          location: "India",
          grade: "Cultaral Relax",
          fees: 687,
          bestmonths:['November', 'December', 'January', 'February'] ,

          description:
            "Welcome to the picturesque coastal city of Visakhapatnam, often referred to as Vizag. With its stunning beaches, cultural heritage, and upscale experiences, Visakhapatnam offers a luxurious escape that captures the essence of coastal beauty and modern indulgence.",
        },
        {
          id: 12,
          imgsrc:mau,
          destTitle: "Mauritius",
          location: "Countryin east Africa",
          grade: "Cultaral Relax",
          fees: 345,
          bestmonths:['May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],

          description:
            "thiruvananthapuram, the capital of Kerala, beckons tourists with its serene backwaters and cultural richness. Visit the exquisite Padmanabhaswamy Temple, unwind by the tranquil beaches, and savor the flavors of Kerala cuisine. This city harmoniously balances tradition  making it a gateway to the beauty",
        },
    
        {
          id: 13,
          imgsrc: gul,
    
          destTitle: "Gulmarg",
          location: "India",
          bestmonths:['November', 'December', 'January', 'February'] ,

        },
        {
          id: 14,
          imgsrc: dal,
          destTitle: "Dalhousie",
          location: "India",
          bestmonths:['December', 'January', 'February','March', 'April', 'May'],
        },
        {
          id: 15,
          imgsrc: gok,
          destTitle: "Gokarna",
          location: "India",
          bestmonths:['October','November','December', 'January', 'February','March',],
        },
        {
          id: 16,
          imgsrc: uda,
    
          destTitle: "Udaipur",
          location: "India",
          bestmonths:['November','December', 'January', 'February',]
        },
        {
          id: 17,
          imgsrc: di,
    
          destTitle: "Diu",
          location: "India",
          bestmonths:['October','November','December', 'January', 'February','March',],

        },
        {
          id: 18,
          imgsrc: srin,
          destTitle: "Srinagar",
          location: "India",
          bestmonths:['November','December', 'January', 'February',]

        },
        {
          id: 19,
          imgsrc: an,
          destTitle: "Andaman",
          location: "India",
          bestmonths:['October','November','December', 'January', 'February','March',,'April','May'],

        },
        {
          id: 20,
          imgsrc: gan,
          destTitle: "Gangtok",
          location: "India",
          bestmonths:['October','November','December','March',,'April','May'],

        },
        {
          id: 21,
          imgsrc: mun,
          destTitle: "Munnar",
          location: "India",
          bestmonths:['November','December', 'January', 'February',]
        },
        {
          id: 22,
          imgsrc: kuma,
          destTitle: "Kumarakom",
          location: "India",
          bestmonths:['November','December', 'January', 'February',]

        },
        {
          id: 23,
          imgsrc: all,
          destTitle: "Allepey",
          location: "India",
          bestmonths:['November','December', 'January', 'February',]

        },
        {
          id: 24,
          imgsrc: guw,
          destTitle: "Guwahati",
          location: "India",
          bestmonths: ['June', 'July', 'August', 'September'],
        },
    
        {
          id: 25,
          imgsrc: rant,
          destTitle: "Ranthambore",
          location: "India",
          bestmonths:['October','November','December', 'January', 'February','March',],

        },
        {
          id: 26,
          imgsrc: sing,
          destTitle: " Singapore",
          location: "India",
          bestmonths:['December', 'January', 'February','March',,'April','May','June'],

        },
        {
          id: 27,
          imgsrc: ba,
          destTitle: "Bali",
          location: "India",
          bestmonths: ['April','May','June', 'July', 'August', 'September','October'],

        },
    
        {
          id: 28,
          imgsrc: mal,
          destTitle: "Maldives",
          location: "India",
          bestmonths:['November','December', 'January', 'February','March','April',],

        },
    
        {
          id: 29,
          imgsrc: dub,
          destTitle: "Dubai",
          location: "India",
          bestmonths:['November','December', 'January', 'February','March'],

        },
        {
          id: 30,
          imgsrc: tur,
          destTitle: "Turkey",
          location: "India",
          bestmonths:['September', 'October','April', 'May'],
        },
    
        {
          id: 31,
          imgsrc: egy,
          destTitle: "Cairo",
          location: "India",
          bestmonths:['October','November','December', 'January', 'February','March','April'],

        },
    
        {
          id: 32,
          imgsrc:mar,
          destTitle: "Marrakech",
          location: "India",
          bestmonths:['March', 'April', 'May', 'September', 'October', 'November'],
        },
    
        {
          id: 33,
          imgsrc:vie,
          destTitle: "Vietnam",
          location: "India",
          bestmonths:['November','December', 'January', 'February','March','April'],

        },
        {
          id: 34,
          imgsrc:sey,
          destTitle: "Seychelles",
          location: "India",
          bestmonths:['April', 'May','October', 'November'],

        },
        {
          id: 35,
          imgsrc:lon,
          destTitle: "London",
          location: "India",
        bestmonths:  ['May', 'June', 'July', 'August', 'September', 'October'],
        },
    
        {
          id: 36,
          imgsrc:par,
          destTitle: "Paris",
          location: "India",
          bestmonths:['March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November'],
        },
    
        {
          id: 37,
          imgsrc:mil,
          destTitle: "Milan",
          location: "India",
          bestmonths:['April', 'May', 'June', 'July', 'August', 'September', 'October',]
        },
        {
          id: 38,
          imgsrc:rom,
          destTitle: "Rome",
          location: "India",
          bestmonths:['April', 'May', 'June', 'July', 'August', 'September', 'October',]

        },
        {
          id: 39,
          imgsrc:ama,
          destTitle: "Amalfi Coast",
          location: "India",
          bestmonths:['May', 'June', 'July', 'August', 'September',],
        },
        {
          id: 40,
          imgsrc:ams,
          destTitle: "Amsterdam",
          location: "India",
          bestmonths:['May', 'June', 'July', 'August',]
        },
        {
          id: 41,
          imgsrc:fin,
          destTitle: "Finland",
          location: "Finland",
          bestmonths:['May', 'June', 'July', 'August', 'September',],

        },
        {
          id: 42,
          imgsrc:aus,
          destTitle: "Australia",
          location: "Australia",
          bestMonths :['September', 'October', 'November', 'December', 'January', 'February', 'March', 'April', 'May'],
        },
    
        {
          id: 43,
          imgsrc:bor,
          destTitle: "Bora Bora",
          location: "French Polynesia",
          bestmonths:['April', 'May', 'June', 'July', 'August', 'September', 'October', 'November'],
        },
        {
          id: 44,
          imgsrc:tul,
          destTitle: "Tulum",
          location: "Mexico",
          bestMonths :['November', 'December', 'January', 'February', 'March', 'April', ],

        },
        {
          id: 45,
          imgsrc:mia,
          destTitle: "Miami",
          location: "USA",
          bestmonths:['March','April','May'],
        
        },
        {
          id: 46,
          imgsrc:cap,
          destTitle: "Cape Town",
          location: "South Africa",
         bestmonths: ['January', 'February', 'March', 'April'],
        },
        {
          id: 47,
          imgsrc:liz,
          destTitle: "Lizard Island",
          location: "Australia",
          bestmonths: ['May', 'June', 'July', 'August', 'September', 'October'],
        },
        {
          id: 48,
          imgsrc:masai,
          destTitle: "The Maasai Mara",
          location: "Kenya",
          bestmonths: [ 'June', 'July', 'August', 'September', 'October'],

        },
       
        {
          id: 49,
          imgsrc:saint,
          destTitle: "St. Lucia",
          location: "Country in the Caribbean",
          bestmonths:['December', 'January', 'February',],

        },
        {
          id: 50,
          imgsrc:can,
          destTitle: "Cancun",
          location: "Mexico",
          bestmonths:['December', 'January', 'February', 'March'],
        },
      ];
    
    
      const filteredData = Data.filter((item) =>
      item.bestmonths && item.bestmonths.includes(selectedMonth)
    );
    
      const sortedData = filteredData.slice().sort((a, b) => {
        const titleA = a.destTitle.toLowerCase();
        const titleB = b.destTitle.toLowerCase();
    
        if (titleA < titleB) {
          return -1;
        }
        if (titleA > titleB) {
          return 1;
        }
        return 0;
      });
      const [k, setk] = useState(sortedData);


      useEffect(()=>{
        const filteredData = Data.filter((item) =>
        item.bestmonths && item.bestmonths.includes(selectedMonth)
      );
      
        const sortedData = filteredData.slice().sort((a, b) => {
          const titleA = a.destTitle.toLowerCase();
          const titleB = b.destTitle.toLowerCase();
      
          if (titleA < titleB) {
            return -1;
          }
          if (titleA > titleB) {
            return 1;
          }
          return 0;

         
        });
        setk(sortedData)
      }, [selectedMonth])
      




    return <>
    <br /><br /><br /><br /><br />
    <center>
<br /><br />

<h3>Recommended Destinations in {selectedMonth}</h3>
 <br />
<br />

    <div className="secContent grid">
          {k.map(
            ({ id, imgsrc, destTitle, location, grade, fees, description }) => {
              const href = destTitle.toLowerCase() === " singapore"
    ? `/${destTitle.toLowerCase().substring(1)}`
    : `/${destTitle.toLowerCase()}`;
              return (
                <div key={id} className="singleDestination">
                  <div className="imageDiv">
                    <img src={imgsrc} alt={destTitle} />
                  </div>
                  <div className="cardInfo">
                    <center>
                      <h3
                      //   className="destTitle"
                      style={{color:"grey"}}
                      >
                        {destTitle} 
                        
                      </h3>
                    </center>

                    <span>
                      {" "}
                      <strong>
                        <a
                          href={href}
                          style={{ color: "black" }}
                        >
                          <p style={{ cursor: "pointer", textAlign: "end" ,fontSize:"11px"}}>
                            View{" >"}
                          </p>
                        </a>
                      </strong>
                    </span>
                  </div>
                </div>
              );
            }
          )}
        </div>
        </center>
    
    </>
}