import React, { useState } from "react";
import "./destination.css";
import i1 from '../Enchant images/Lizard Island/LzI_1.jpg'
import i2 from '../Enchant images/Lizard Island/LzI_2.jpg'
import i3 from '../Enchant images/Lizard Island/LzI_3.jpg'
import i4 from '../Enchant images/Lizard Island/LzI_4.jpg'
import i5 from '../Enchant images/Lizard Island/LzI_5.jpg'
import i6 from '../Enchant images/Lizard Island/LzI_6.jpg'
import i7 from '../Enchant images/Lizard Island/LzI_7.jpg'
import i8 from '../Enchant images/Lizard Island/LzI_8.jpg'
import i from "./images/LizardIsland.jpeg";
import t1 from '../Enchant images/Lizard Island/nature-3082832_1280.jpg'

export default function LizardIsland() {
  const [activeLink, setActiveLink] = useState("overview");

  const scrollToSection = () => {
    const targetSection = document.getElementById("accomodation");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("Accomodations");
  };
  const scrollToSection1 = () => {
    const targetSection = document.getElementById("beach");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("Beaches");
  };
  const scrollToSection2 = () => {
    const targetSection = document.getElementById("dive");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("Dive");
  };
  const scrollToSection3 = () => {
    const targetSection = document.getElementById("hotel");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("hotels");
  };
  const scrollToSection4 = () => {
    const targetSection = document.getElementById("tours");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("tours");
  };
  const scrollToSection5 = () => {
    const targetSection = document.getElementById("relax");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("relax");
  };
  const scrollToSection6 = () => {
    const targetSection = document.getElementById("luxury");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("luxury");
  };
  const scrollToSection7 = () => {
    const targetSection = document.getElementById("nature");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("nature");
  };

  return (
    <>
      <div className="div">
        <div className="destination_header">
          <img id="s" src={i} alt="" />
          <div className="he">
            <h1>
              EXPERIENCE THE PINNACLE OF LUXURY AND NATURAL BEAUTY OF THE GREAT BARRIER REEF AT LIZARD ISLAND
            </h1>
          </div>
        </div>

      

        <br />

        <div className="div4">
          <div className="sec1">
            <br />
           
            <h2>
              WHY SHOULD YOU TRAVEL TO LIZARD ISLAND WITH US?
              <br />
             
            </h2>
            <br />
            <p style={{textAlign:"justify"}}>
            Welcome to Lizard Island, a haven of exclusivity and natural beauty nestled in the heart of Australia's Great Barrier Reef. Situated on the northern edge of the reef, Lizard Island offers a remote and luxurious escape for travelers seeking a pristine paradise.

Lizard Island's blend of natural beauty, luxury accommodations, and commitment to preserving its unique environment make it an extraordinary destination for travelers seeking a secluded and opulent retreat. Whether you're exploring the vibrant underwater world, savoring gourmet cuisine with ocean views, or simply unwinding on the island's pristine shores, Lizard Island promises an unparalleled luxury experience that will create cherished memories for a lifetime
            </p>
          </div>
          <div className="sec2">
            <br />
            
            <img
              src={t1}
              alt=""
            />
          </div>
        </div>
        <div className="div5" style={{ background: "#fafafa" }}>
          <br />
          <br />
          <br />
          <center>
            <h2 id="j">
              LIZARD ISLAND PROMISES AN UNPARALLELED LUXURY EXPERIENCE
            </h2>
            <br />
            <strong>
              <p>
                The optimal period to visit Lizard Island is from May through
                October
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>
        
        <br/>
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={i1} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}> Exquisite Accommodations</h4>
                    <br />
                    
                    <p style={{textAlign:"justify"}}>
                    Luxurious beachfront suites and pavilions with panoramic ocean
                  views.
                  
                  Private decks, plunge pools, and outdoor daybeds for ultimate
                  relaxation.
                  
                  Impeccable interiors blending modern comforts with natural
                  elegance.
                    </p>
                    <br />
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={i2} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Pristine Beaches and Coral Reefs</h4>
                    <br />
                 
                    <p style={{textAlign:"justify"}}>
                    Access to some of the Great Barrier Reef's most vibrant coral
                  gardens.
                  
                  Snorkeling and scuba diving in crystal-clear waters teeming
                  with marine life.
                  
                  Private beach setups for sunbathing, picnics, and romantic
                  moments.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </center>

         
          <br />
        
          
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={i3} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Exclusive Dive and Snorkel Experiences</h4>
                    <br />
                  
                    <p style={{textAlign:"justify"}}>
                    Personalized dive experiences led by expert guides to explore
                  the underwater wonders.
                  
                  Guided snorkel tours to vibrant reef sites showcasing diverse
                  coral and fish species.
                  
                  Private charters for customized diving and snorkeling
                  adventures.
                    </p>
                    <br />
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={i4} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Culinary Delights</h4>
                    <br />
                  
                    <p style={{textAlign:"justify"}}>
                    Gourmet dining experiences with panoramic ocean views at the
                  Salt Water Restaurant.
                  
                  Fresh seafood, local produce, and a carefully curated wine
                  list.
                  
                  Beachfront dining options and intimate candlelit dinners on
                  the sand.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </center>

        
          <br />
        
        
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={i5} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Private Tours and Activities</h4>
                    <br />
              
                    <p style={{textAlign:"justify"}}>
                    Private guided hikes to explore the island's lush landscapes
                  and hiking trails.
                
                  Stand-up paddleboarding, kayaking, and windsurfing in the calm
                  waters.
                
                  Sunset cruises, fishing charters, and wildlife watching
                  excursions.
                    </p>
                    <br />
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={i6} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Relaxation and Wellness</h4>
                    <br />
                  
                    <p style={{textAlign:"justify"}}>
                    Tranquil spa treatments using organic and natural products.
                  
                  Yoga sessions in serene settings overlooking the ocean.
                  
                  Wellness programs that focus on rejuvenation and balance.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </center>

       
        
          <br />
        
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={i7} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Secluded Luxury</h4>
                    <br />
                  
                    <p style={{textAlign:"justify"}}>
                    Limited number of guests on the island, ensuring an exclusive
                  and tranquil atmosphere.
                  
                  Unspoiled natural beauty with miles of pristine coastline to
                  explore.
                  
                  Privacy and seclusion for a truly intimate escape.
                    </p>
                    <br />
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={i8} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Nature and Wildlife</h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                    Opportunities to spot unique wildlife, including nesting sea
                  turtles and exotic birds.
                
                  Guided nature walks and educational talks to learn about the
                  island's ecosystem.
                  
                  Discovering secluded beaches and hidden coves that offer a
                  sense of adventure.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </center>

          
        </div>
      </div>
    </>
  );
}
