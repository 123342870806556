
import './App.css'

import Privacypolicy from './Components/privacypolicy';
import Experience from './Components/Destination/experiences';
import Footer from './Components/Footer/Footer';
import About from './Components/About';
import Amalfi from './Components/Destination/Amalficoast';
import Terms from './Components/Terms';
import Home from './Components/Home/Home';
import Singapore from './Components/Destination/Singapore';
import Ranthambore from './Components/Destination/Ranthambore';
import Vishakapatnam from './Components/Destination/Vishakapatnam';
import Munnar from './Components/Destination/Munnar';
import Andaman from './Components/Destination/Andaman';
import Destination from './Components/Destination/Destination';
import { Route,Routes } from 'react-router-dom';
import Guwahti from './Components/Destination/Guwahti';
import All from './Components/Alldestinations.js/All';
import MegaMenu from './Components/megamenu/menu';
import Bymonth from './Components/Bymonth/Bymonth';
import Bali from './Components/Destination/Bali';
import Slider1 from './Components/Alldestinations.js/Slider1';
import Mumbai from './Components/Destination/Mumbai';
import Rome from './Components/Destination/Rome';
import Goa from './Components/Destination/Goa';
import Kumarkakom from './Components/Destination/Kumarkakom';
import Jodhapur from './Components/Destination/Jodhapur';
import Gulmarg from './Components/Destination/Gulmarg';
import Dalhousie from './Components/Destination/Dalhousie';
import Udaipur from './Components/Destination/Udaipur';
import Shimla from './Components/Destination/Shimla';
import Gokarna from './Components/Destination/Gokarna';
import Srinagar from './Components/Destination/Srinagar';
import Diu from './Components/Destination/Diu';
import Agra from './Components/Destination/Agra';
import Gangtok from './Components/Destination/Gangtok';
import Maldives from './Components/Destination/Maldives';
import Dubai from './Components/Destination/Dubai';
import Turkey from './Components/Destination/Turkey';
import Allepey from './Components/Destination/Allepey';
import Bymonths from './Components/Alldestinations.js/Bymonths';
import Vietnam from './Components/Destination/Veitnam';
import Amsterdam from './Components/Destination/Amsterdam';
import Australia from './Components/Destination/Australia';
import Belgium from './Components/Destination/Belgium';
import Logis from './Logis';
import LizardIsland from './Components/Destination/LizardIsland';
import London from './Components/Destination/London';
import Marrakech from './Components/Destination/Marrakech';
import Mauritius from './Components/Destination/Mauritius';
import Miami from './Components/Destination/Miami';
import Milan from './Components/Destination/Milan';
import Login from './Components/Navbar/Login';
import BoraBora from './Components/Destination/Borabora';
import Egypt from './Components/Destination/CairoEgypt';
import CapeTown from './Components/Destination/CapeTown';
import Cancun from './Components/Destination/Cuncun';
import Croatia from './Components/Destination/Croatia';
import Finland from './Components/Destination/Finland';
import Paris from './Components/Destination/Paris';
import Scotland from './Components/Destination/Scotland';
import Seychelles from './Components/Destination/Seychelles';
import Lucia from './Components/Destination/StLucia';
import Switzerland from './Components/Destination/Switzerland';

import MasaaiMara from './Components/Destination/MasaaiMara';
import Tulum from './Components/Destination/Tulum';
import Datacollection from './datacollectionandprotectionpolicy';

function App() {
  return (
    

    <div className="App">
      <MegaMenu/>
           
      <Routes >
      <Route path='/experience' element={<Experience/>} />
      <Route path='/about1' element={<Logis/>} />

      <Route path='/miami' element={<Miami/>} />
      

      <Route path='/lizard island' element={<LizardIsland/>} />
<Route path='/marrakech' element={<Marrakech/>} />
      <Route path='/tulum' element={<Tulum/>} />
      <Route path='/the maasai mara' element={<MasaaiMara/>} />
      <Route path='/switzerland' element={<Switzerland/>} />
      <Route path='/st. lucia' element={<Lucia/>} />
      <Route path='/Seychelles' element={<Seychelles/>} />
      <Route path='/scotland' element={<Scotland/>} />
      <Route path='/paris' element={<Paris/>} />
      {/* <Route path='/finland' element={<Finland/>} />
      <Route path='/croatia' element={<Croatia/>} />
      <Route path='/cancun' element={<Cancun/>} />
      <Route path='/cape town' element={<CapeTown/>} />
      <Route path='/cairo' element={<Egypt/>} /> */}
      {/* <Route path='/bora bora' element={<BoraBora/>} />
      <Route path='/belgium' element={<Belgium/>} />
      <Route path='/australia' element={<Australia/>} />
      
      <Route path='/mauritius' element={<Mauritius/>} />
      <Route path='/amsterdam' element={<Amsterdam/>} /> */}
      <Route path='/milan' element={<Milan/>} />
      <Route path='/london' element={<London/>} />





        <Route path='/new delhi' element={<Destination/>} />
        <Route path='/' element={<Home/>} />
        <Route path='/gokarna' element={<Gokarna/>} />
        <Route path='/bymonth' element={<Bymonth/>} />
        <Route path='/bymonths' element={<Bymonths/>} />
        <Route path='/amalfi coast' element={<Amalfi/>} />

        <Route path='/alleppey' element={<Allepey/>} />
        <Route path='/vietnam' element={<Vietnam/>} />

        <Route path='/udaipur' element={<Udaipur/>} />
        <Route path='/agra' element={<Agra/>} />
        <Route path='/bali' element={<Bali/>} />
        {/* <Route path='/dubai' element={<Dubai/>} />
        <Route path='/turkey' element={<Turkey/>} />
        <Route path='/about' element={<About/>} /> */}
        <Route path='/terms-conditions' element={<Terms/>} />
        <Route path='/privacy-policy' element={<Privacypolicy/>} />
        <Route path='/datacollection&protectionpolicy' element={<Datacollection/>} />
        {/* <Route path='/singapore' element={<Singapore/>} />
        <Route path='/maldives' element={<Maldives/>} />

        <Route path='/andaman' element={<Andaman/>} />
        <Route path='/vishakapatnam' element={<Vishakapatnam/>} /> */}

        {/* <Route path='/srinagar' element={<Srinagar/>} />
        <Route path='/goa' element={<Goa/>} />
        <Route path='/gangtok' element={<Gangtok/>} />
        <Route path='/kumarakom' element={<Kumarkakom/>} />

        <Route path='/jodhpur' element={<Jodhapur/>} />
        <Route path='/dalhousie' element={<Dalhousie/>} />
        <Route path='/rome' element={<Rome/>} />
        <Route path='/munnar' element={<Munnar/>} />
        <Route path='/guwahati' element={<Guwahti/>} />

        <Route path='/mumbai' element={<Mumbai/>} />
        <Route path='/ranthambore' element={<Ranthambore/>} />

        <Route path='/gulmarg' element={<Gulmarg/>} />
        <Route path='/diu' element={<Diu/>} />
        <Route path='/shimla' element={<Shimla/>} /> */}

        {/* <Route path='/all' element={<All/>} /> */}
        <Route path='/by' element={<Bymonth/>} />
<Route path='/slider' element={<Slider1/>} />
        <Route path='/menu' element={<MegaMenu/>} />
        <Route path='*' element={<Home/>}/>

      </Routes>
<br /><br /><br /> <br /><br /> <br /><br /> <br /><br />  
    <Footer />

 
    </div>
  );
}

export default App;
