import React, { useState } from "react";
import "./destination.css";
import i from "./images/Tulum.jpg";
import i1 from "../Enchant images/Tulum/T_1.jpg";
import i2 from "../Enchant images/Tulum/T_2.jpg";
import i3 from "../Enchant images/Tulum/T_3.jpg";
import i4 from "../Enchant images/Tulum/T_4.jpg";
import i5 from "../Enchant images/Tulum/T_4_b.jpg";
import i6 from "../Enchant images/Tulum/T_5.jpg";
import i7 from "../Enchant images/Tulum/T_6.jpg";
import i8 from "../Enchant images/Tulum/T_7.jpg";
export default function Tulum() {
  const [activeLink, setActiveLink] = useState("overview");

  const scrollToSection = () => {
    const targetSection = document.getElementById("accomodation");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("Accomodations");
  };
  const scrollToSection1 = () => {
    const targetSection = document.getElementById("beach");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("Beaches");
  };
  const scrollToSection2 = () => {
    const targetSection = document.getElementById("dive");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("Dive");
  };
  const scrollToSection3 = () => {
    const targetSection = document.getElementById("hotel");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("hotels");
  };
  const scrollToSection4 = () => {
    const targetSection = document.getElementById("tours");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("tours");
  };
  const scrollToSection5 = () => {
    const targetSection = document.getElementById("relax");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("relax");
  };
  const scrollToSection6 = () => {
    const targetSection = document.getElementById("luxury");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("luxury");
  };
  const scrollToSection7 = () => {
    const targetSection = document.getElementById("nature");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("nature");
  };

  return (
    <>
      <div className="div">
        <div className="destination_header">
          <img id="s" src={i} alt="" />
          <div className="he">
            <h1>TULUM : AN EXCLUSIVE ESCAPE FOR TRAVELLERS </h1>
          </div>
        </div>

        <br />

        <div className="div4">
          <div className="sec1">
            <br />

            <h2>
              WHY SHOULD YOU TRAVEL TO TULUM WITH US?
              <br />
            </h2>
            <br />

            <p style={{ textAlign: "justify" }}>
              Welcome to Tulum, a captivating destination on the Riviera Maya
              that seamlessly blends luxury with bohemian charm. With its
              pristine beaches, ancient Mayan ruins, and upscale accommodations,
              Tulum offers an exclusive escape for travelers seeking a blend of
              relaxation, culture, and indulgence. Tulum's combination of
              natural beauty, cultural richness, and luxurious offerings make it
              an enticing destination for travelers seeking an upscale and
              holistic getaway. Whether you're unwinding on the beach, exploring
              ancient ruins, or indulging in gourmet cuisine, Tulum promises a
              luxurious escape that embraces both relaxation and adventure.
              Here's a glimpse of the luxurious experiences that await you in
              this tropical paradise:
            </p>
          </div>
          <div className="sec2">
            <br />

            <img src={i7} alt="" />
          </div>
        </div>
        <div className="div5" style={{ background: "#fafafa" }}>
          <br />
          <br />
          <br />
          <center>
            <h2 id="j">
              WELCOME TO TULUM, A CAPTIVATING DESTINATION ON THE RIVIERA MAYA!{" "}
            </h2>
            <br />
            <strong>
              <p>
                seamless blending of luxury with bohemian charm. With its
                pristine beaches, ancient Mayan ruins, and upscale
                accommodations{" "}
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>
        
         
          <br />

          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={i1} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Beachfront Luxury Resorts</h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                    Upscale beachfront hotels and resorts offering direct access
                  to the Caribbean Sea.
                  
                  Stylish eco-friendly bungalows, villas, and suites with modern
                  amenities.
                  
                  Private infinity pools, rooftop terraces, and ocean-view
                  accommodations.
                    </p>
                    <br />
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={i2} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Mayan Ruins and Cultural Exploration</h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                    Guided tours of the ancient Tulum Ruins, perched on a cliff
                  overlooking the sea.
                
                  Private archaeological excursions to nearby ruins like Coba
                  and Muyil.
                  
                  Opportunities to learn about Mayan history, architecture, and
                  traditions from expert guides.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </center>

         
          <br />
          {/* dive */}
        
          <br />
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={i3} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Gourmet Dining and Culinary Experiences</h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                    Acclaimed restaurants offering gourmet fusion cuisine with a
                  focus on local ingredients.
                  
                  Beachfront dining with stunning sunset views and sea breezes.
                  
                  Farm-to-table experiences and cooking classes showcasing
                  traditional Mexican flavors.
                    </p>
                    <br />
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={i4} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Wellness and Spa Retreats</h4>
                    <br />
                  
                    <p style={{textAlign:"justify"}}>
                    Exclusive wellness retreats and spa resorts offering holistic
                  treatments.
                  
                  Yoga and meditation sessions in idyllic settings overlooking
                  the sea.
                  
                  Healing rituals inspired by ancient Mayan practices and
                  natural elements.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </center>

        
          <br />
          <center>
            

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={i6} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Private Beach Activities</h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                    Beachfront cabanas and lounges for exclusive relaxation and
                  sunbathing. Private beach picnics and romantic dinners under
                  the stars. Water sports such as paddleboarding, snorkeling,
                  and kayaking in the crystal-clear waters.
                    </p>
                    <br />
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={i5} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Boutique Shopping and Art</h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                    Chic boutiques and artisanal shops featuring unique fashion
                  and décor items.
                  
                  Local markets offering handmade crafts, textiles, and
                  traditional artwork.
                  
                  Art galleries showcasing contemporary and indigenous Mexican
                  art.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </center>

         <br/>
         <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={i8} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Cenote Exploration</h4>
                    <br />
                    
                    <p style={{textAlign:"justify"}}>
                    Private tours to explore the cenotes (natural sinkholes) that
                  dot the region. Swimming, snorkeling, and diving in these
                  pristine, turquoise freshwater pools. Underground cenote tours
                  with experienced guides revealing their unique beauty.
                    </p>
                    <br />
                  </div>
                </div>
              </div>

            
            </div>
          </center>

        
          <br />
        </div>
      </div>
    </>
  );
}
