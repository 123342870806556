import React, { useState } from "react";
import "./home.css"; // Import your SCSS file
import { GrLocation } from "react-icons/gr";
import BlackButton from "../Navbar/Blackbutton";
import Main from "../Main/Main";
import i from "./Extra Homepage.jpg";
import p2 from "./LandingPage_1.jpg";
import v from "./home.mp4";
import WhiteButton from "../Navbar/Whitebutton";
import si from "./buildings-647400_1280 (1).jpg";
import jod from "./Jodhpur.jpg";
import go from "./Goa.jpg";
import par from "./Paris.jpg";
import shi from "./Shimla.jpg";
import aus from "./Australia.jpg";
import dub from "./Dubai.jpg";
import alle from "./Alleppey (1).jpg";
import pd from './a.pdf'
import { useMediaQuery } from "react-responsive";

function Home() {
  const [searchTerm, setSearchTerm] = useState("");
  const containerStyle = {
    position: "relative",
    display: "inline-block",
    
  };

  const imageStyle = {
    height: "auto",
    width: "100%",
  };
  const isSmallScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const isSmallScreen1 = useMediaQuery({ query: "(max-width: 325px)" });

  const overlayTextStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "white",
    
    fontSize: isSmallScreen1?"24px":"26px",
    fontWeight: "bold",
    textAlign: "center",
    letterSpacing: "6px",
    

  };


  return (
    <>
      <br />
      <br />
      <br />
      <section className="home" style={{ position: "relative" }}>
        <video
          src={v}
          muted
          autoPlay
          loop
          // height={400}
        ></video>
        <div
          style={{
            position: "absolute",
            top: isSmallScreen ? "50%":"27%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "white",
            textAlign: "center",
          }}
        >
          <h1 id="hometext">
            Embark on an unparalleled journey where experience becomes a way of
            life, and every experience is a celebration
          </h1>
        </div>
      </section>
      <br/> 


      <div style={{maxWidth:"1600px",margin:"0 auto"}}>
      <div class="section" style={{ position: "relative" }}>
        <div class="section1">
          <img src={i} alt="" style={{ height: "auto" ,paddingBottom:"7px"}} />
        </div>
        <div class="section2">
          <div class="col__copy default-typography">
            <h3 style={{ wordSpacing: "5px" }}>LUXURY TRAVEL CURATORS</h3>
            <br />
            <p style={{ lineHeight: "22px", textAlign: "justify" }}>
              In a world full of unexplored possibilities, luxury is defined by
              the exceptional experiences and individualized attention to your
              travel wishes. We play a vital role in curating these journeys
              that would not only pamper but also inspire and transform your
              travel diaries. We specialize in providing high-end, bespoke
              travel experiences for individuals and groups seeking the pinnacle
              of comfort, exclusivity, and personalized service.
            </p>{" "}
            <br />
            <p style={{ lineHeight: "22px", textAlign: "justify" }}>
              Create memories that would last a lifetime, relax with a sense of
              pampering, and immerse in the opportunity to explore the world in
              the most exclusive and indulgent way possible. Whether it's a
              romantic getaway, an adventure-filled expedition, or a cultural
              immersion, we have it sorted for you and we aim to exceed every
              expectation.
            </p>
            <br />
            <br />
            <div className="divu">
              <a href="/about">
                <BlackButton text="ABOUT US" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="section" style={{ position: "relative" }}>
        <div class="vection2">
          <div class="col__copy default-typography">
            <h3 style={{ letterSpacing: "2px" }}>ENCHANTXP</h3>
            <br />
            <p style={{ lineHeight: "22px", textAlign: "justify" }}>
              Let's pack your travel with meaningful connections and lasting
              memories while you gain a profound appreciation for the
              destination's culture, people, and environment. <br />
              <br />
              Enchant your travel with comfort,exuberance, purpose and depth.
            </p>

            {/* <div className="divu">
              <BlackButton text="ABOUT US" />
            </div> */}
          </div>
        </div>
        <div class="vection1">
          <img src={p2} alt="" />
        </div>
      </div>

      {/* ///////////////////////////////// */}
      <br /> 
      <center>
      <h2 >MOST POPULAR DESTINATIONS</h2>
        
           

            </center>












      <div class="section" style={{ position: "relative",paddingTop:"17px"}}>
        <div class="section1" style={containerStyle}>
          <img src={si} alt="" style={imageStyle} />
          <div style={overlayTextStyle}>SINGAPORE</div>
        </div>
        <div class="section2">
          <br />
          <div class="col__copy default-typography">
            {/* <h3 style={{ wordSpacing: "5px" }}></h3> */}
            <br />

            <p style={{ lineHeight: "22px", textAlign: "justify" }}>
              Singapore, where modern elegance, cultural diversity, and upscale
              experiences converge to offer a luxurious and unforgettable
              getaway. From iconic landmarks to world-class dining and exclusive
              shopping, Singapore presents a blend of opulence and innovation.
            </p>
            <br />
          
            <div className="divu">
              <a href="/singapore">
                <BlackButton text="EXPLORE MORE" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div id="p10" class="vection" style={{ position: "relative" ,}}>
        <div class="vection2">
          <br /> <br />
          <div class="col__copy default-typography">
            {/* <h3 style={{ wordSpacing: "5px" }}>LUXURY TRAVEL CURATORS</h3> */}
            <br />

            <p style={{ lineHeight: "22px", textAlign: "justify" }}>
              Alleppey, often referred to as the "Venice of the East." Nestled
              amidst Kerala's backwaters, Alleppey offers a luxurious escape
              that combines serene landscapes, opulent accommodations, and
              unique experiences. Let's explore the world of opulence that
              awaits you in this enchanting destination.
            </p>
            <br />
           
            <div className="divu">
              <a href="/alleppey">
                <BlackButton text="EXPLORE MORE" />
              </a>
            </div>
          </div>
        </div>
        <div class="vection1" style={containerStyle}>
          <img src={alle} alt="" style={imageStyle} />
          <div style={overlayTextStyle}>ALLEPPEY</div>
        </div>
      </div>
      <div id="p11" class="section" style={{ position: "relative", }}>
        <div class="section1" style={containerStyle}>
          <img src={alle} alt="" style={imageStyle} />
          <div style={overlayTextStyle}>ALLEPPEY</div>
        </div>
        <div class="section2">
          <br /> 
          <div class="col__copy default-typography">
            {/* <h3 style={{ wordSpacing: "5px" }}>LUXURY TRAVEL CURATORS</h3> */}
            <br />

            <p style={{ lineHeight: "22px", textAlign: "justify" }}>
              Alleppey, often referred to as the "Venice of the East." Nestled
              amidst Kerala's backwaters, Alleppey offers a luxurious escape
              that combines serene landscapes, opulent accommodations, and
              unique experiences. Let's explore the world of opulence that
              awaits you in this enchanting destination.
            </p>
            <br />
            
            <div className="divu">
              <a href="/alleppeyt">
                <BlackButton text="EXPLORE MORE" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="section" style={{ position: "relative",}}>
        <div class="section1" style={containerStyle}>
          <img src={dub} alt="" style={imageStyle} />
          <div style={overlayTextStyle}>DUBAI</div>
        </div>
        <div class="section2">
          <br /> 
          <div class="col__copy default-typography">
            {/* <h3 style={{ wordSpacing: "5px" }}>LUXURY TRAVEL CURATORS</h3> */}
            <br />

            <p style={{ lineHeight: "22px", textAlign: "justify" }}>
              Dubai, where futuristic architecture, extravagant experiences, and
              upscale offerings create a luxurious escape like no other. From
              towering skyscrapers to opulent shopping malls and world-class
              dining, Dubai offers a blend of opulence and innovation.
            </p>
            <br />
            
            <div className="divu">
              <a href="/dubai">
                <BlackButton text="EXPLORE MORE" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="vection" id="p8" style={{ position: "relative", }}>
        <div class="vection2">
          <br />
          <div class="col__copy default-typography">
            {/* <h3 style={{ wordSpacing: "5px" }}>LUXURY TRAVEL CURATORS</h3> */}
            <br />

            <p style={{ lineHeight: "22px", textAlign: "justify" }}>
              Australia,where diverse landscapes, vibrant cities, and upscale
              experiences come together to offer a luxurious and unforgettable
              escape. From stunning beaches to unique wildlife and world-class
              cuisine, Australia presents a perfect blend of opulence and
              natural beauty.
            </p>
            <br />
           
            <div className="divu">
              <a href="/australia">
                <BlackButton text="EXPLORE MORE" />
              </a>
            </div>
          </div>
        </div>
        <div class="vection1" style={containerStyle}>
          <img src={aus} alt="" style={imageStyle} />
          <div style={overlayTextStyle}>AUSTRALIA</div>
        </div>
      </div>

      <div class="section" id="p9" style={{ position: "relative", }}>
        <div class="section1" style={containerStyle}>
          <img src={aus} alt="" style={imageStyle} />
          <div style={overlayTextStyle}>AUSTRALIA</div>
        </div>
        <div class="section2">
          <br /> 
          <div class="col__copy default-typography">
            {/* <h3 style={{ wordSpacing: "5px" }}>LUXURY TRAVEL CURATORS</h3> */}
            <br />

            <p style={{ lineHeight: "22px", textAlign: "justify" }}>
              Australia,where diverse landscapes, vibrant cities, and upscale
              experiences come together to offer a luxurious and unforgettable
              escape. From stunning beaches to unique wildlife and world-class
              cuisine, Australia presents a perfect blend of opulence and
              natural beauty.
            </p>
          
            <br />
            <div className="divu">
              <a href="/australia">
                <BlackButton text="EXPLORE MORE" />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* ///////////////////////////////// */}

      <div class="section" style={{ position: "relative", }}>
        <div class="section1" style={containerStyle}>
          <img src={shi} alt="" style={imageStyle} />
          <div style={overlayTextStyle}>SHIMLA</div>
        </div>
        <div class="section2">
          <br /> 
          <div class="col__copy default-typography">
            {/* <h3 style={{ wordSpacing: "5px" }}>LUXURY TRAVEL CURATORS</h3> */}
            <br />

            <p style={{ lineHeight: "22px", textAlign: "justify" }}>
              Shimla, where the lush green landscapes, colonial charm, and
              breathtaking vistas offer a haven of luxury and relaxation.
              Nestled amidst the Himalayan foothills, Shimla is a perfect blend
              of natural beauty, elegant accommodations, and refined
              experiences.
            </p>
            <br />
           
            <div className="divu">
              <a href="/shimla">
                <BlackButton text="EXPLORE MORE" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="vection" id="p12" style={{ position: "relative", }}>
        <div class="vection2">
          <br /> 
          <div class="col__copy default-typography">
            {/* <h3 style={{ wordSpacing: "5px" }}>LUXURY TRAVEL CURATORS</h3> */}
            <br />

            <p style={{ lineHeight: "22px", textAlign: "justify" }}>
              Paris, where timeless beauty, artistic inspiration, and upscale
              experiences come together to offer a luxurious and romantic
              escape. From iconic landmarks to world-class cuisine and haute
              couture, Paris presents a perfect blend of opulence and cultural
              allure.
            </p>
            
            <br />
            <div className="divu">
              <a href="/paris">
                <BlackButton text="EXPLORE MORE" />
              </a>
            </div>
          </div>
        </div>
        <div class="vection1" style={containerStyle}>
          <img src={par} alt="" style={imageStyle} />
          <div style={overlayTextStyle}>PARIS</div>
        </div>
      </div>

      <div class="section" id="p13" style={{ position: "relative",}}>
        <div class="section1" style={containerStyle}>
          <img src={par} alt="" style={imageStyle} />
          <div style={overlayTextStyle}>PARIS</div>
        </div>
        <div class="section2">
          <br /> 
          <div class="col__copy default-typography">
            {/* <h3 style={{ wordSpacing: "5px" }}>LUXURY TRAVEL CURATORS</h3> */}
            <br />

            <p style={{ lineHeight: "22px", textAlign: "justify" }}>
              Paris, where timeless beauty, artistic inspiration, and upscale
              experiences come together to offer a luxurious and romantic
              escape. From iconic landmarks to world-class cuisine and haute
              couture, Paris presents a perfect blend of opulence and cultural
              allure.
            </p>
         
            <br />
            <div className="divu">
              <a href="/paris">
                <BlackButton text="EXPLORE MORE" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="section" style={{ position: "relative" }}>
        <div class="section1" style={containerStyle}>
          <img src={go} alt="" style={imageStyle} />
          <div style={overlayTextStyle}>GOA</div>
        </div>
        <div class="section2">
          <br /> 
          <div class="col__copy default-typography">
            {/* <h3 style={{ wordSpacing: "5px" }}>LUXURY TRAVEL CURATORS</h3> */}
            <br />

            <p style={{ lineHeight: "22px", textAlign: "justify" }}>
              Goa, India's most sought-after destination for sun-soaked beaches,
              vibrant culture, and indulgent experiences. Whether you're looking
              for opulent resorts, exquisite dining, or rejuvenating spa
              treatments, Goa has it all.
            </p>
          
            <br />
            <div className="divu">
              <a href="/goa">
                <BlackButton text="EXPLORE MORE" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div id="p6" class="vection" style={{ position: "relative" ,}}>
        <div class="vection2">
          <br /> 
          <div class="col__copy default-typography">
            {/* <h3 style={{ wordSpacing: "5px" }}>LUXURY TRAVEL CURATORS</h3> */}
            <br />

            <p style={{ lineHeight: "22px", textAlign: "justify" }}>
              Jodhpur, where luxury and history intertwine to create an
              unparalleled experience for discerning travelers. Known as the
              "Blue City" for its iconic azure-hued buildings, Jodhpur offers a
              regal retreat filled with opulent palaces, rich culture, and
              majestic landscapes.
            </p>
            <br />
           
            <div className="divu">
              <a href="/jodhpur">
                <BlackButton text="EXPLORE MORE" />
              </a>
            </div>
          </div>
        </div>
        <div class="vection1" style={containerStyle}>
          <img src={jod} alt="" style={imageStyle} />
          <div style={overlayTextStyle}>JODHPUR</div>
        </div>
      </div>
      <div id="p7" class="section" style={{ position: "relative" ,}}>
        <div class="section1" style={containerStyle}>
          <img src={jod} alt="" style={imageStyle} />
          <div style={overlayTextStyle}>JODHPUR</div>
        </div>
        <div class="section2">
          <br /> 
          <div class="col__copy default-typography">
            {/* <h3 style={{ wordSpacing: "5px" }}>LUXURY TRAVEL CURATORS</h3> */}
            <br />

            <p style={{ lineHeight: "22px", textAlign: "justify" }}>
            Jodhpur, where luxury and history intertwine to create an
              unparalleled experience for discerning travelers. Known as the
              "Blue City" for its iconic azure-hued buildings, Jodhpur offers a
              regal retreat filled with opulent palaces, rich culture, and
              majestic landscapes.
            </p>
            <br />
            
            <div className="divu">
              <a href="/jodhpur">
                <BlackButton text="EXPLORE MORE" />
              </a>
            </div>
          </div>
        </div>
      </div>




      <Main/></div>
    </>
  );
}

export default Home;
