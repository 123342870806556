import React, { useState } from "react";

import "./main.css";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { HiOutlineClipboardCheck } from "react-icons/hi";
import { FaGreaterThan } from "react-icons/fa";
import BlackButton from "../Navbar/Blackbutton";
import Slider from "react-slick";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import Slider1 from "../Alldestinations.js/Slider1";
function Main() {
 


  return (
    <>
    <br /><br /> <br />
    <Slider1 />
     
     
     <div className="tak" style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingTop: "30px" }}>
     <a href="/all"><BlackButton text="EXPLORE MORE" /></a>

     </div>

     <div className="div" >
      <p style={{textAlign:"end",color:"white"}}>&copy;&nbsp;2023,Cambliss Pvt. Ltd.</p>
    </div>

      
    </>
  );
}

export default Main;