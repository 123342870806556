import "./slider.css";
import { useState } from "react";
import Slider from "react-slick";
import pd from './a.pdf'
import pd1 from './b.pdf'
import pd2 from './c.pdf'
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import imgd from './1_50.jpg'
import all from "./Alleppey (1).jpg"
import dub from "./Dubai.jpg";
import aus from './Australia (1).jpg'
import shi from './Shimla (1).jpg'
import sing from './buildings-647400_1280.jpg'
import jod from './Jodhpur.jpg'
import par from './Paris (1).jpg'
import goa from './Goa (1).jpg'
import { Link, useNavigate ,useLinkClickHandler} from "react-router-dom";
import tur from './Turkey.jpg'
import sco from './Scotland.jpg'
import sey from './Seychelles.jpg'
import mun from './Munnar.jpg'
import mil from './Mumbai.jpg'
import mal from './Maldives (1).jpg'
import swi from './Switzerland.jpg'
import tul from './Tulum.jpg'

import BlackButton from "../Navbar/Blackbutton";
const images = [
  tur,
  sco,
 sey,tur,sco,
 sey


];
const images1 =[
  "S I N G A P O R E",
  "A L L E P P E Y",
  "D U B A I",
  "S H I M L A",
  "P A R I S",
  "J O D H P U R",
  "G O A",
  "A U S T R A L I A",

]
// const images2 =[
//   pd,
//   "alleppey",
//   "dubai",
//   "shimla",
//   "paris",
//   "jodhpur",
//   "goa",
//   "australia",

// ]
const images2 =[
  pd,
  pd1,    pd2,
  pd,pd1,    pd2,
  

]



function Slider1() {
  const NextArrow = ({ onClick }) => {

    return (
      <div className="arrow next" onClick={onClick}>
        <FaArrowRight />
      </div>
    );
    
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  };

  const [imgIndex, setImgIndex] = useState(0);
  const navigate = useNavigate();
  const imageClickHandler = useLinkClickHandler();
  const handleImageClick = (idx) => {
    // Scroll to the top of the page
    

    // Use the custom click handler to navigate to the destination page
    // imageClickHandler(() => navigate(images2[idx]));
    navigate(`${images2[idx]}`);
    window.scrollTo(0, 0);
    console.log("this is vijay");
  };
  
  const settings = {
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 3, // Adjust this number to control the number of visible slides
    centerMode: true,
    centerPadding: "0", // Set padding to control spacing between cards
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImgIndex(next),
  };

  const handleBrochure = (data) => {
    window.location.href = data; // Redirect to the URL specified in the 'data' parameter
  }
  const handleClick = (idx) => {
    handleBrochure(images2[idx]);
  }
  return (
<center>
    <div className="Ap">
     
      <h3 style={{wordSpacing:"6px", marginTop:"-20px"}}>TRAVEL RECOMMENDATIONS</h3>
<br />
      <Slider {...settings} >
        {images.map((imgd, idx) => (
          <div className="dib" >
          <div
            className={idx === imgIndex ? "slide activeSlide" : "slide"}
            key={idx}
          >
         
            <a href={`${images2[idx]}`}>
        <img src={imgd} alt={idx}    
 />      
        </a>
            
        
          
          </div>
          </div>
        ))}
      </Slider>

      
    </div>
    </center>

  );
}

export default Slider1;