
export default function Datacollection() {
  return (
    <>
      <div className="destination_header">
        <img id="s" src="./image.jpg" alt="" />
        <div className="he">
          <h1>Enchantxp Mobile App Data Collection and Protection Policy</h1>
        </div>
      </div>
      <br />
      <br />
<div style={{maxWidth:"1200px",margin:"0 auto"}}>
      <div className="ab2" style={{ paddingLeft: "30px", padding: "30px" }}>
        <h3>1. Introduction</h3>
        <br />

        <p style={{ textAlign: "justify" }}>
          Enchantxp Mobile App ("Enchantxp," "we," "our," or "us") is committed
          to ensuring the privacy and protection of user data. This Data
          Collection and Protection Policy outlines how Enchantxp collects and
          handles user data, as well as the purposes for which it is collected.
          By using the Enchantxp Mobile App, you agree to the terms and
          practices described in this policy.
        </p>

        <br />

        <h3>2. Obligations</h3>
        <br />
        <p>Enchantxp collects the following user data:</p>
        <br />
        <p style={{ textAlign: "justify" }}>
          - First Name: To address the user personally.
        </p>
        <p style={{ textAlign: "justify" }}>
          - Last Name: To validate the user's full name.
        </p>
        <p style={{ textAlign: "justify" }}>
          - Email Id: For sending official communications from Enchantxp and
          sending deleted account details.
        </p>
        <p style={{ textAlign: "justify" }}>
          - Mobile Number: For user login via OTP.
        </p>
        <p style={{ textAlign: "justify" }}>
          - WhatsApp Number: For sending notifications via WhatsApp chat.
        </p>
        <p style={{ textAlign: "justify" }}>
          - Flight Boarding Pass: For sending it in scheduled notifications.
        </p>
        <p style={{ textAlign: "justify" }}>
          - Hotel Booking Voucher: For sending it in scheduled notifications.
        </p>
        <p style={{ textAlign: "justify" }}>
          - Land Package Details: For sending it in scheduled notifications.{" "}
        </p>
        <br />
        <p>
          Additionally, we may request users to inform us if their registered
          mobile number and WhatsApp number are the same or not for enhanced
          user experience.
        </p>

  <br />
        <h3>3. Data Usage</h3>
        <br />
        <p>
          Enchantxp uses the collected data only for the following purposes:
        </p>
        <br />
        <p style={{ textAlign: "justify" }}>
          - Sending push notifications and in-app notifications via the
          Enchantxp Mobile App.
        </p>
        <p style={{ textAlign: "justify" }}>
          - Sending notifications via WhatsApp chat.
        </p>
        <p style={{ textAlign: "justify" }}>- Addressing users personally.</p>
        <p style={{ textAlign: "justify" }}>-Validating user's full name.</p>
        <p style={{ textAlign: "justify" }}>
          - Sending official communications from Enchantxp.
        </p>
        <p style={{ textAlign: "justify" }}>
          - Sending deleted account details.{" "}
        </p>
        <p style={{ textAlign: "justify" }}>
          -Sending scheduled notifications, including flight boarding passes,
          hotel booking vouchers, and land package details.{" "}
        </p>
        <br />

        <h3>4. Data Protection</h3>

        <br />
        <p style={{ textAlign: "justify" }}>
          We take data protection seriously and employ industry-standard
          security measures to protect user data from unauthorized access,
          disclosure, alteration, or destruction. These measures include but are
          not limited to encryption, access controls, and regular security
          assessments.
        </p>
        <br />
        <h3>5. Data Sharing and Disclosure</h3>
        <br />
        <p>
          Enchantxp does not share or disclose user data with third parties,
          except in the following circumstances:
        </p>
        <br />

        <p style={{ textAlign: "justify" }}>
          - When required by law or legal process.
        </p>
        <p style={{ textAlign: "justify" }}>
          - To comply with law enforcement requests.
        </p>
        <p style={{ textAlign: "justify" }}>
          - To protect our rights, privacy, safety, or property.
        </p>
        <p style={{ textAlign: "justify" }}>
          - In the event of a merger, acquisition, or sale of all or a portion
          of our assets, where user data may be transferred to the acquiring
          entity.
        </p>
        <br />
        <h3>6. User Rights</h3>
        <br />
        <p>Users have the following rights regarding their data:</p>
        <br />

        <p style={{ textAlign: "justify" }}>
          - The right to access their data.
        </p>
        <p style={{ textAlign: "justify" }}>
          - The right to correct inaccuracies in their data.
        </p>
        <p style={{ textAlign: "justify" }}>
          - The right to request data deletion.
        </p>
        <p style={{ textAlign: "justify" }}>
          - The right to object to data processing.{" "}
        </p>

        <p style={{ textAlign: "justify" }}>- The right to data portability.</p>

        <br />
        <p>
          To exercise these rights or for any data-related inquiries, users can
          contact us at [Contact Email].
        </p>
        <br />
        <h3>7. Changes to this Policy</h3>
        <br />
        <p style={{ textAlign: "justify" }}>
          Enchantxp reserves the right to update or modify this Data Collection
          and Protection Policy at any time. Any changes will be communicated
          through the Enchantxp Mobile App. Continued use of the app after such
          changes implies acceptance of the updated policy.
        </p>
        <br />
        <h3>8. All the details collected here are optional for the user.</h3>
        <br />
        <p style={{ textAlign: "justify" }}>
        Gender - Male, Female, Third Gender<br/>
        Children Bday - DOB of the child or childrenDOB - User Date of Birth<br/>
        Anniversary - User Anniversary <br/>
        Occupation - User Occupation<br/>
        Preferences - Travel preferences - Beach, Mountains, Wildlife etc <br/>
        Approx Yearly Income (Self/Family) - Income Range<br/>
        </p>
        <br/>
        <h3>9. Contact Information</h3>
        <br />
        <p style={{ textAlign: "justify" }}>
        If you have any questions or concerns regarding this policy or the data practices of Enchantxp, please contact us at:
        </p>
        <br />
       
        <p style={{ textAlign: "justify" }}>
        info@enchantxp.com <br/>
7 Temasek Boulevard, #12-07 Suntec Tower One <br/>
Singapore, 038987 <br/>
+65 8333 7861 <br/>

        </p>
        <br />

        <p style={{ textAlign: "justify" }}>
        By using the Enchantxp Mobile App, you acknowledge that you have read, understood, and agree to the terms of this Data Collection and Protection Policy.
        </p>
        <br />
        
        <br />
       
      </div></div>
    </>
  );
}