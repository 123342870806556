import React, { useState } from "react";
import "./destination.css";
import i from "./images/St Lucia.jpg";
import i1 from '../Enchant images/St Lucia/St.Lucia_1.jpg'
import i2 from '../Enchant images/St Lucia/St.Lucia_2.jpg'
import i3 from '../Enchant images/St Lucia/St.Lucia_3.jpg'
import i4 from '../Enchant images/St Lucia/St.Lucia_4.jpg'
import i5 from '../Enchant images/St Lucia/St.Lucia_5.jpg'
import i6 from '../Enchant images/St Lucia/St.Lucia_6.jpg'
import i7 from '../Enchant images/St Lucia/St.Lucia_7.jpg'
import t1 from '../Enchant images/St Lucia/St.Lucia_cover.jpg'
export default function Lucia() {
  const [activeLink, setActiveLink] = useState("overview");

  const scrollToSection = () => {
    const targetSection = document.getElementById("accomodation");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("Accomodations");
  };
  const scrollToSection1 = () => {
    const targetSection = document.getElementById("beach");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("Beaches");
  };
  const scrollToSection2 = () => {
    const targetSection = document.getElementById("dive");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("Dive");
  };
  const scrollToSection3 = () => {
    const targetSection = document.getElementById("hotel");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("hotels");
  };
  const scrollToSection4 = () => {
    const targetSection = document.getElementById("tours");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("tours");
  };
  const scrollToSection5 = () => {
    const targetSection = document.getElementById("relax");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("relax");
  };
  const scrollToSection6 = () => {
    const targetSection = document.getElementById("luxury");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("luxury");
  };
  const scrollToSection7 = () => {
    const targetSection = document.getElementById("nature");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("nature");
  };

  return (
    <>
      <div className="div">
        <div className="destination_header">
          <img id="s" src={i} alt="" />
          <div className="he">
            <h1>
             
EXPERIENCE  CARIBBEAN GEM, LUSH LANDSCAPES & PRISTINE BEACHES
              
            </h1>
          </div>
        </div>

      

        <br />





        <div className="div4">
          <div className="sec1">
            <br />
           
            <h2>
              WHY SHOULD YOU TRAVEL TO ST.LUCIA WITH US?
              <br />
              
            </h2>
            <br />
            
            <p style={{textAlign:"justify"}}>
            Welcome to St. Lucia, a Caribbean gem where lush landscapes, pristine beaches, and luxury experiences converge to create an unforgettable getaway. Nestled in the Eastern Caribbean Sea, St. Lucia offers a harmonious blend of natural beauty, vibrant culture, and high-end accommodations that cater to discerning travelers.

St. Lucia offers an enticing blend of natural beauty, cultural richness, and luxurious experiences. Whether you're basking on the beach, exploring rainforests, indulging in spa treatments, or immersing yourself in local culture, St. Lucia promises a luxurious journey that captures the essence of Caribbean elegance and relaxation.


            </p>
          </div>
          <div className="sec2">
            <br />
           
            <img
              src={t1}
              alt=""
            />
          </div>
        </div>
        <div className="div5" style={{ background: "#fafafa" }}>
          <br />
          <br />
          <br />
          <center>
            <h2 id="j">
            NESTLED IN THE EASTERN CARIBBEAN SEA
            </h2>
            <br />
            <strong>
              <p>
              St. Lucia offers a harmonious blend of natural beauty, vibrant culture, and high-end accommodations that cater to discerning travelers.
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>
          {/* accomodations */}
         

          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={i1} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Luxury Resorts And Private Villas</h4>
                    <br />
                    
                    <p style={{textAlign:"justify"}}>
                    Exclusive beachfront resorts with elegant suites and private bungalows.<br/>
Luxurious villas with private pools, jacuzzis, and panoramic views.
Impeccable interiors that blend modern comforts with Caribbean aesthetics.
                    </p>
                    <br />
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={i2} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Breathtaking  Natural Wonders</h4>
                   
                    <br />
                    <p style={{textAlign:"justify"}}>
                    Exploring the iconic Pitons, two volcanic peaks that rise dramatically from the sea.<br/>
Guided hikes through lush rainforests, cascading waterfalls, and exotic flora and fauna.
Experiencing the magical Sulphur Springs and therapeutic mud baths.
</p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </center>
          <br />
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={i3} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Gourmet Dining and Culinary Delight</h4>
                    <br />
                    
                    <p style={{textAlign:"justify"}}>
                    Fine-dining restaurants offering oceanfront views and exquisite fusion cuisine.
Fresh seafood, local spices, and international flavors crafted by acclaimed chefs.
Private dining experiences on the beach, under the stars, or on secluded cliffs.
                    </p>
                    <br />
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={i4} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Wellness and Spa Retreats</h4>
                    <br />
                    
                    <p style={{textAlign:"justify"}}>
                    Luxury spas offering holistic treatments inspired by the island's natural elements.
Yoga and meditation sessions in serene settings, often overlooking the Caribbean Sea.
Wellness programs that focus on rejuvenation, relaxation, and inner balance.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </center>


      
          <br />
          {/* Culinery Delights */}
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={i5} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Water Adventures and Marine Exploration</h4>
                    <br />
                    
                    <p style={{textAlign:"justify"}}>
                  
Private yacht charters to explore hidden coves, secluded beaches, and nearby islands.
Snorkeling and scuba diving to discover vibrant coral reefs and marine life.
Whale and dolphin watching excursions for a close encounter with marine creatures.
                    </p>
                    <br />
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={i6} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}> Island Tours and Cultural Experiences</h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                    Exploring the charming town of Soufrière and its colorful markets and historical sites.
Visiting local fishing villages, interacting with locals, and experiencing the warm hospitality.
Immersing yourself in St. Lucian traditions, music, dance, and cultural festivals.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </center>

       
        
          <br />
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={i7} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Golf and Sporting Activities</h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                 
Championship golf courses with stunning views of the Caribbean Sea and surrounding landscapes.<br/>
Tennis courts, water sports, and adventure activities for active pursuits.
                    </p>
                    <br />
                  </div>
                </div>
              </div>

           
            </div>
          </center>

          
   
          <br />
       
        </div>
      </div>
    </>
  );
}
