import React, { useState } from "react";
import "./destination.css";
import a from "./images/Scotland.jpg";
import i1 from '../Enchant images/Scotland/Sc_1.jpg'
import i2 from '../Enchant images/Scotland/Sc_2.jpg'

import i3 from '../Enchant images/Scotland/Sc_3.jpg'
import i4 from '../Enchant images/Scotland/Sc_4.jpg'
import i5 from '../Enchant images/Scotland/Sc_5.jpg'
import i6 from '../Enchant images/Scotland/Sc_6.jpg'
import i7 from '../Enchant images/Scotland/Sc_7_b.jpg'
import t1 from '../Enchant images/Scotland/Sc_cover.jpg'
import i8 from '../Enchant images/Scotland/Sc_8.jpg'
import i9 from '../Enchant images/Scotland/Sc_9.jpg'
import i10 from '../Enchant images/Scotland/Sc_7.jpg'
export default function Scotland() {
  const [activeLink, setActiveLink] = useState("overview");

  const scrollToSection = () => {
    const targetSection = document.getElementById("j");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("itineraries");
  };
  const scrollToSection1 = () => {
    const targetSection = document.getElementById("r");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("locations");
  };
  const scrollToSection2 = () => {
    const targetSection = document.getElementById("k");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("hotels");
  };

  return (
    <>
      <div className="div">
        <div className="destination_header">
          <img id="s" src={a} alt="" />
          <div className="he">
            <h1>
            WELCOME TO THE RUGGEDLY BEAUTIFUL AND CULTURALLY RICH LAND OF SCOTLAND!




</h1>{" "}
          </div>
        </div>

        <center>
          <div className="nav2">
            <div className="div3">
              <a href="">
                <p
                  className={` ${
                    activeLink === "overview" ? "active-link" : ""
                  }`}
                >
                  OVERVIEW
                </p>
              </a>
              <a onClick={scrollToSection}>
                <p
                  className={` ${
                    activeLink === "itineraries" ? "active-link" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  ITINERARIES
                </p>
              </a>
            
              <a onClick={scrollToSection2}>
                <p
                  className={` ${activeLink === "hotels" ? "active-link" : ""}`}
                  style={{ cursor: "pointer" }}
                >
                  HOTELS
                </p>
              </a>
              <a onClick={scrollToSection1}>
                <p
                  style={{ cursor: "pointer" }}
                  className={` ${
                    activeLink === "locations" ? "active-link" : ""
                  }`}
                >
                  RESTAURANTS
                </p>
              </a>
            </div>
          </div>
        </center>

        <br />
     

        <div className="div4">
          <div className="sec1">
            <br />
          
            <h2>
              WHY SHOULD YOU TRAVEL TO SCOTLAND WITH US?
              <br />
              
            </h2>
            <br/>
            <p style={{textAlign:"justify"}}>
            Welcome to the ruggedly beautiful and culturally rich land of Scotland, where ancient castles, breathtaking landscapes, and upscale experiences come together to offer a luxurious and captivating escape. From historic sites to world-class whisky and refined cuisine, Scotland presents a perfect blend of opulence and Highland charm. Let's explore the world of luxury that awaits you in this enchanting country.

Scotland, with its historic charm, natural wonders, and cultural treasures, offers an unforgettable and opulent escape that captures both adventure and refined living. From staying in elegant accommodations to savoring gourmet cuisine and engaging in curated activities, Scotland promises an experience that embodies the essence of luxury and the allure of Highland heritage.            </p>
          </div>
          <div className="sec2">
            <br />
           
            <img
              src={t1}
              alt=""
            />
          </div>
        </div>

        <div className="div5" style={{ background: "#fafafa" }}>
          <br />
          <br />
          
          <center>
            <h2 id="j">RECOMMENDED ITINERARIES IN SCOTLAND</h2>
            <br />
            <strong>
              <p>
              Engage with Scotland's rich history and ancient wonders through curated experiences
</p>
            </strong>
            <br />
            <br />
            <br />
          </center>

          <center>
           

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img
                    src={i1}
                    alt=""
                  />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Stirling Castle</h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                    Discover Stirling Castle, where you can step back in time to experience the royal history and architectural beauty of Scotland.                    </p>
                    <br />
                  
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img
                    src={i3}
                    alt=""
                  />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}> Edinburgh Castle</h4>
                    <br />
                    
                    <p style={{textAlign:"justify"}}>
                     Explore Edinburgh Castle, a historic fortress perched atop Castle Rock, offering panoramic views of the city and a glimpse into Scottish history.                    </p>
                    <br />
                   
                  </div>
                </div>
              </div>
            </div>
          </center>

          
          <center>
            <h2 id="r">CULINARY EXCELLENCE AND WHISKEY TASTINGS</h2>
            <br />
            <strong>
              <p>
              Indulge in a culinary journey that celebrates Scotland's gourmet dining scene and whisky heritage.              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>
        
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img
                    src={i8}
                    alt=""
                  />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>The Kitchin</h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                    Savor gourmet dishes at The Kitchin in Edinburgh, a Michelin-starred restaurant offering a seasonal menu inspired by local Scottish produce.                    </p>
                    <br />
                   
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img
                    src={i2}
                    alt=""
                  />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>   Whisky Tours</h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                 Enjoy private whisky tastings and tours at Scotland's renowned distilleries, experiencing the flavors and history of Scotland's national drink.                    </p>
                    <br />
                  
                  </div>
                </div>
              </div>
            </div>
          </center>
         
          <center>
            <h2 id="k">LUXURIOUS HIGHLAND TREATS</h2>
            <br />
            <strong>
              <p>
              Scotland boasts a selection of luxurious lodges, country estates, and historic castles that provide the perfect blend of comfort and Highland splendor.

              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>

          
          <center>
           

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img
                    src={i5}
                    alt=""
                  />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>
                    Gleneagles
                    </h4>
                    <br />
                    <p style={{textAlign:"justify"}}>
                    Immerse yourself in luxury at Gleneagles, offering elegant rooms, golf courses, and a tranquil countryside setting amidst the rolling hills.
                    </p>{" "}
                    <br />
                  
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img
                    src={i9}
                    alt=""
                  />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>
                    Cameron House on Loch Lomond
                    </h4>
                    <br />
                    <p style={{textAlign:"justify"}}>
                     Experience the grandeur of Cameron House, known for its luxurious suites, scenic Loch Lomond views, and spa facilities.                    </p>{" "}
                    <br />
                  
                  </div>
                </div>
              </div>
            </div>
          </center>
        </div>
      </div>
   
      <center>
        <h2 id="k">WELLNESS AND RELAXATION</h2>
        <br />
        <strong>
          <p>
          Rejuvenate your senses at Scotland's luxury wellness centers and spas.



          </p>
        </strong>
        <br />
        <br />
        <br />
      </center>
      <center>
      

        <div className="hotel">
          <div className="h2" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg2">
              <img
                src={i6}
                alt=""
              />
              <div className="dp2">
                <br />
                <h4 style={{ textAlign: "start" }}>
                The Spa at Gleneagles
                </h4>
                <br />
               
                <p style={{textAlign:"justify"}}>
               Immerse yourself in holistic wellness at The Spa at Gleneagles, offering a range of treatments inspired by Scottish nature and relaxation techniques.                </p>{" "}
                <br />
              
              </div>
            </div>
          </div>
        </div>
      </center>
   
      <center>
        <h2 id="j">EXCLUSIVE EXPERIENCES</h2>
        <br />
        <strong>
          <p>
          Elevate your Scottish journey with extraordinary activities:
</p>
</strong>
        <br />
        <br />
        <br />
      </center>

      <center>
       

        <div className="hotel">
          <div className="h1" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg1">
              <img
                src={i10}
                alt=""
              />
              <div className="dp1">
                <br />
                <h4 style={{ textAlign: "start" }}> Private Highland Safari</h4>
                <br />
                <p style={{textAlign:"justify"}}>
               Experience a private Highland safari, where you can spot wildlife, enjoy scenic walks, and experience the natural beauty of the Highlands.
                </p>{" "}
                <br />
               
              </div>
            </div>
          </div>

          <div className="h2" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg2">
              <img
                src={i7}
                alt=""
              />
              <div className="dp2">
                <br />
                <h4 style={{ textAlign: "start" }}>  Private Piping and Dancing Experience</h4>
                <br />
                <p style={{textAlign:"justify"}}>
              Learn traditional Scottish piping and Highland dancing with a private lesson, immersing yourself in Scottish culture.                </p>{" "}
                <br />
              
              </div>
            </div>
          </div>
        </div>

        <p style={{textAlign:"end",color:"white"}}>&copy;&nbsp;2023,Cambliss Pvt. Ltd.</p>

      </center>
      <br />
      {/* <center>
        <h2 id="k">HIGH FASHION SHOPPING AND ART GALLERIES</h2>
        <br />
        <strong>
          <p>
          Experience Scotland's upscale shopping scene with luxury boutiques and local craftsmanship:



          </p>
        </strong>
        <br />
        <br />
        <br />
      </center>
      <center>

        <div className="hotel">
          <div className="h1" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg1">
              <img
                src="https://images.unsplash.com/photo-1611892440504-42a792e24d32?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aG90ZWwlMjByb29tfGVufDB8fDB8fHww&auto=format&fit=crop&w=600&q=60"
                alt=""
              />
              <div className="dp1">
                <br />
                <h4 style={{ textAlign: "start" }}> Edinburgh's Multrees Walk</h4>
                <br />
                <br />
                <p style={{ textAlign: "start" }}>
               Explore Multrees Walk in Edinburgh, a high-end shopping destination featuring international designer brands and luxury boutiques.                </p>
                <br />
                <p
                  style={{
                    textAlign: "end",
                    fontWeight: "600",
                    fontSize: "14px",
                    letterSpacing: "2px",
                  }}
                ></p>
              </div>
            </div>
          </div>

          <div className="h2" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg2">
              <img
                src="https://images.unsplash.com/photo-1582719478250-c89cae4dc85b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8aG90ZWwlMjByb29tfGVufDB8fDB8fHww&auto=format&fit=crop&w=600&q=60"
                alt=""
              />
              <div className="dp2">
                <br />
                <h4 style={{ textAlign: "start" }}> Glasgow's Merchant City</h4>
                <br />
                <br />
                <p style={{ textAlign: "start" }}>
               Immerse yourself in local art galleries and designer boutiques in Glasgow's vibrant Merchant City district.                </p>
                <br />
                <p
                  style={{
                    textAlign: "end",
                    fontWeight: "600",
                    fontSize: "14px",
                    letterSpacing: "2px",
                  }}
                >
                </p>
              </div>
            </div>
          </div>
        </div>
      </center> */}
      <br/>
      <br/>
      {/* <center>
            <h2 id="k">HIGHLAND ADVENTURES AND OUTDOOR ESCAPES
</h2>
            <br />
            <strong>
              <p>
              Experience Scotland's natural beauty and outdoor activities through curated adventures:

</p></strong>
            <br />
            <br />
            <br />
          </center>
          <center>

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img
                    src="https://images.unsplash.com/photo-1611892440504-42a792e24d32?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aG90ZWwlMjByb29tfGVufDB8fDB8fHww&auto=format&fit=crop&w=600&q=60"
                    alt=""
                  />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}> Private Highland Tours</h4>
                    <br />
                    <br />
                    <p style={{ textAlign: "start" }}>
                    Embark on private guided tours through the Scottish Highlands, enjoying scenic drives, picturesque lochs, and charming villages.</p>
                    <br />
                    <p
                      style={{
                        textAlign: "end",
                        fontWeight: "600",
                        fontSize: "14px",
                        letterSpacing: "2px",
                      }}
                    ></p>
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img
                    src="https://images.unsplash.com/photo-1582719478250-c89cae4dc85b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8aG90ZWwlMjByb29tfGVufDB8fDB8fHww&auto=format&fit=crop&w=600&q=60"
                    alt=""
                  />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Golfing in St. Andrews</h4>
                    <br />
                    <br />
                    <p style={{ textAlign: "start" }}>
                    Tee off at St. Andrews, known as the "Home of Golf," with its iconic Old Course and world-class golfing experiences. </p>                   <br />
                    <p
                      style={{
                        textAlign: "end",
                        fontWeight: "600",
                        fontSize: "14px",
                        letterSpacing: "2px",
                      }}
                    >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </center> */}
    </>
  );
}
