import React, { useState } from "react";
import "./destination.css";

import t1 from "../Enchant images/Switzerland/Sw_cover.jpg";
import i1 from "../Enchant images/Switzerland/Sw_1.jpg";
import i2 from "../Enchant images/Switzerland/Sw_2.jpg";
import i3 from "../Enchant images/Switzerland/Sw_3.jpg";
import i4 from "../Enchant images/Switzerland/Sw_4.jpg";
import i5 from "../Enchant images/Switzerland/Sw_5.jpg";
import i6 from "../Enchant images/Switzerland/Sw_6.jpg";
import s1 from "../Enchant images/Switzerland/s1.jpg";
import a from "./images/Switzerland.jpg";
import n1 from "../Enchant images/Switzerland/n1.webp";
import n2 from "../Enchant images/Switzerland/n2.webp";
import n3 from "../Enchant images/Switzerland/n3.jpg";
import n4 from "../Enchant images/Switzerland/n4.jpeg";

export default function Switzerland() {
  const [activeLink, setActiveLink] = useState("overview");

  const scrollToSection = () => {
    const targetSection = document.getElementById("j");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("itineraries");
  };
  const scrollToSection1 = () => {
    const targetSection = document.getElementById("r");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("locations");
  };
  const scrollToSection2 = () => {
    const targetSection = document.getElementById("k");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("hotels");
  };

  return (
    <>
      <div className="div">
        <div className="destination_header">
          <img id="s" src={a} alt="" />
          <div className="he">
            <h1>
              WELCOME TO THE BREATHTAKING AND SOPHISTICATED DESTINATION OF
              SWITZERLAND!
            </h1>{" "}
          </div>
        </div>

        <center>
          <div className="nav2">
            <div className="div3">
              <a href="">
                <p
                  className={` ${
                    activeLink === "overview" ? "active-link" : ""
                  }`}
                >
                  OVERVIEW
                </p>
              </a>
              <a onClick={scrollToSection}>
                <p
                  className={` ${
                    activeLink === "itineraries" ? "active-link" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  ITINERARIES
                </p>
              </a>

              <a onClick={scrollToSection2}>
                <p
                  className={` ${activeLink === "hotels" ? "active-link" : ""}`}
                  style={{ cursor: "pointer" }}
                >
                  HOTELS
                </p>
              </a>
              <a onClick={scrollToSection1}>
                <p
                  style={{ cursor: "pointer" }}
                  className={` ${
                    activeLink === "locations" ? "active-link" : ""
                  }`}
                >
                  RESTAURANTS
                </p>
              </a>
            </div>
          </div>
        </center>

        <br />

        <div className="div4">
          <div className="sec1">
            <br />

            <h2>
              WHY SHOULD YOU TRAVEL TO SWITZERLAND WITH US?
              <br />
            </h2>
            <br />
            <p style={{ textAlign: "justify" }}>
              Welcome to the breathtaking and sophisticated destination of
              Switzerland, where pristine alpine landscapes, world-class
              hospitality, and upscale experiences converge to offer a luxurious
              and enchanting escape. From majestic mountains to gourmet cuisine
              and unparalleled wellness, Switzerland presents a perfect fusion
              of opulence and natural beauty. Let's explore the world of luxury
              that awaits you in this captivating country. Switzerland, with its
              alpine luxury, gourmet delights, and unparalleled landscapes,
              offers an extraordinary and opulent escape that captures both
              adventure and refined living. From staying in elegant
              accommodations to savoring gourmet cuisine and engaging in curated
              activities, Switzerland promises an experience that embodies the
              essence of luxury and the allure of natural beauty.{" "}
            </p>
          </div>
          <div className="sec2">
            <br />

            <img src={t1} alt="" />
          </div>
        </div>

        <div className="div5" style={{ background: "#fafafa" }}>
          <br />
          <br />

          <center>
            <h2 id="j">RECOMMENDED ITINERARIES IN SWITZERLAND</h2>
            <br />
            <strong>
              <p>
                Experience Switzerland's natural beauty and outdoor activities
                through curated adventures
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>

          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={n3} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>
                      {" "}
                      Private Helicopter Tours
                    </h4>
                    <br />
                  
                    <p style={{ textAlign: "justify" }}>
                      Soar above the Swiss Alps on a private helicopter tour,
                      taking in panoramic views of snow-capped peaks, valleys,
                      and pristine lakes.
                    </p>
                    <br />
                 
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={n4} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>
                      {" "}
                      Swiss Glacier Express
                    </h4>
                    <br />
                  
                    <p style={{ textAlign: "justify" }}>
                      Embark on a luxurious train journey on the Glacier
                      Express, passing through spectacular landscapes and
                      connecting Switzerland's iconic destinations.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </center>

          <center>
            <h2 id="r">CULINARY EXCELLENCE AND SWISS DELICACIES</h2>
            <br />
            <strong>
              <p>
                Indulge in a culinary journey that celebrates Switzerland's
                gourmet dining scene and Swiss specialties.
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>
          {/* <div className="fhotel">
            <div className="d1">
              <img
                src="https://cdn.pixabay.com/photo/2016/11/18/14/05/brick-wall-1834784_1280.jpg"
                alt=""
              />
            </div>
            <div
              className="d2"
              style={{
                background: "white",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              <br />
              <div className="d21">
                <h4>Taj Exotica Goa</h4>
                <br />
                <br />
                <br />
                <p style={{ textAlign: "start" }}>
                  Set amidst palm-fringed gardens and overlooking the Arabian
                  Sea, Taj Exotica is an oasis of luxury. With spacious villas,
                  impeccable service, and stunning sea views, this resort
                  promises an unforgettable stay.
                </p>
                <br /> <br />
                <p
                  style={{
                    textAlign: "end",
                    fontWeight: "600",
                    fontSize: "14px",
                    letterSpacing: "2px",
                  }}
                >
               
                </p>
              </div>
            </div>
          </div> */}
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={i6} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>
                      {" "}
                      Cheval Blanc, St. Moritz
                    </h4>
                    <br />
                   
                    <p style={{ textAlign: "justify" }}>
                      Savor gourmet dishes at Cheval Blanc, a Michelin
                      three-star restaurant in St. Moritz, offering creative
                      cuisine in a sophisticated setting.
                    </p>
                    <br />
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={i2} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}> Fondue and Raclette</h4>
                    <br />
                   
                    <p style={{ textAlign: "justify" }}>
                      Enjoy traditional Swiss fondue and raclette experiences,
                      where you can savor melted cheese and local delights in
                      cozy chalets.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </center>

          <center>
            <h2 id="k">LUXURIOUS ALPINE RETREATS</h2>
            <br />
            <strong>
              <p>
                Switzerland boasts a collection of luxurious alpine resorts and
                mountain lodges that provide the perfect blend of comfort and
                stunning views.
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>

          {/* <div className="fhotel">
            <div className="d1">
              <img
                src="https://i0.wp.com/www.thelodhi.com/wp-content/uploads/sites/3/2020/10/room10-slider2-1.jpg?w=1300&ssl=1"
                alt=""
              />
            </div>
            <div
              className="d2"
              style={{
                background: "white",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                height: "270px",
              }}
            >
              <br />
              <div className="d21">
                <h4>Vinayak Family Restaurant</h4>
                <br />
                <p style={{ textAlign: "start" }}>
                  Experience traditional Goan cuisine at its finest. Savour
                  delectable seafood dishes, like prawn balchão and xacuti, in a
                  rustic yet luxurious setting.
                </p>
                <br /> <br />
                <p
                  style={{
                    textAlign: "end",
                    fontWeight: "600",
                    fontSize: "14px",
                    letterSpacing: "2px",
                  }}
                >
               
                </p>
              </div>
            </div>
          </div> */}
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={i1} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>
                      Badrutt's Palace Hotel, St. Moritz
                    </h4>
                    <br />
                    <p style={{ textAlign: "justify" }}>
                      Immerse yourself in luxury at Badrutt's Palace Hotel,
                      offering elegant rooms, impeccable service, and panoramic
                      vistas of the Engadin Valley.
                    </p>{" "}
                    <br />
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={i5} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>The Chedi Andermatt</h4>
                    <br />
                    <p style={{ textAlign: "justify" }}>
                      Experience alpine chic at The Chedi Andermatt, known for
                      its contemporary design, upscale amenities, and access to
                      pristine slopes.
                    </p>{" "}
                    <br />
                  
                  </div>
                </div>
              </div>
            </div>
          </center>
        </div>
      </div>

      <center>
        <h2 id="k">WELLNESS AND RELAXATION</h2>
        <br />
        <strong>
          <p>
            Rejuvenate your senses at Switzerland's luxury wellness centers and
            spas.
          </p>
        </strong>
        <br />
        <br />
        <br />
      </center>
      <center>
        {/* this is two templates */}

        <div className="hotel">
          <div className="h2" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg2">
              <img src={s1} alt="" />
              <div className="dp2">
                <br />
                <h4 style={{ textAlign: "start" }}>Grand Resort Bad Ragaz</h4>
                <br />
                
                <p style={{ textAlign: "justify" }}>
                  Immerse yourself in holistic wellness at Grand Resort Bad
                  Ragaz, offering thermal baths, spa treatments, and relaxation
                  amid natural beauty.
                </p>{" "}
                <br />
                <p
                  style={{
                    textAlign: "end",
                    fontWeight: "600",
                    fontSize: "14px",
                    letterSpacing: "2px",
                  }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      </center>

      <center>
        <h2 id="j"> EXCLUSIVE EXPERIENCES</h2>
        <br />
        <strong>
          <p>Elevate your Swiss journey with extraordinary activities </p>
        </strong>
        <br />
        <br />
        <br />
      </center>

      <center>
        {/* this is two templates */}

        <div className="hotel">
          <div className="h1" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg1">
              <img src={n2} alt="" />
              <div className="dp1">
                <br />
                <h4 style={{ textAlign: "start" }}>Private Mountain Picnics</h4>
                <br />
                <p style={{ textAlign: "justify" }}>
                  Experience a private mountain picnic amidst stunning
                  landscapes, enjoying gourmet cuisine and panoramic views.
                </p>{" "}
                <br />
               
              </div>
            </div>
          </div>

          <div className="h2" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg2">
              <img
                src="https://images.pexels.com/photos/1126728/pexels-photo-1126728.jpeg?auto=compress&cs=tinysrgb&w=600"
                alt=""
              />
              <div className="dp2">
                <br />
                <h4 style={{ textAlign: "start" }}>
                  Private Chocolate and Cheese Tasting
                </h4>
                <br />
                <p style={{ textAlign: "justify" }}>
                  Indulge in a private chocolate and cheese tasting experience,
                  where you can sample Swiss delights in picturesque settings.
                </p>{" "}
                <br />
             
              </div>
            </div>
          </div>
        </div>
      </center>

      <center>
        <h2 id="k">HIGH-END SHOPPING AND SWISS WATCHES</h2>
        <br />
        <strong>
          <p>
            Experience Switzerland's upscale shopping scene with luxury
            boutiques and renowned watchmakers:
          </p>
        </strong>
        <br />
        <br />
        <br />
      </center>
      <center>
        {/* this is two templates */}

        <div className="hotel">
          <div className="h1" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg1">
              <img src={n1} alt="" />
              <div className="dp1">
                <br />
                <h4 style={{ textAlign: "start" }}>Bahnhofstrasse, Zurich </h4>
                <br />
                
                <p style={{ textAlign: "justify" }}>
                  Explore Bahnhofstrasse, Zurich's renowned shopping street,
                  featuring international luxury brands, fine jewelry, and watch
                  boutiques.{" "}
                </p>
                <br />
              
              </div>
            </div>
          </div>

          <div className="h2" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg2">
              <img src={i4} alt="" />
              <div className="dp2">
                <br />
                <h4 style={{ textAlign: "start" }}>Swiss Watch Tours</h4>
                <br />
             
                <p style={{ textAlign: "justify" }}>
                  Immerse yourself in Swiss watchmaking heritage with private
                  tours and visits to prestigious watch manufactures.
                </p>
                <br />
               
              </div>
            </div>
          </div>
        </div>
      </center>
    </>
  );
}
