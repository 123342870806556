import React, { useState } from 'react';
import './bymonth.css';
import { Link } from 'react-router-dom';

const Bymonth = ({ onMonthClick }) => {
  const months = [
    'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December'
  ];

  return (
    <>
      <div className="month-grid-container">
        <div className="month-grid">
          {months.map((month, index) => (
            <div
              key={index}
              className="month-item"
              onClick={() => onMonthClick(month)}
            >
              <a href={`/bymonths?month=${month}`}>{month}</a>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Bymonth;

