import './navbar.css'


export default function BlackButton({ text }){


    return <>
    


    <button className="custom-button" >{text}</button>



    </>
}
