import React, { useState } from "react";
import "./destination.css";
import a from "./images/Paris.jpg";
import t1 from '../Enchant images/Paris/Cultural and Artistic Treasures/paris-2499022_1280.jpg'
import i1 from '../Enchant images/Paris/Cultural and Artistic Treasures/Louvre Museum.jpg'
import i2 from '../Enchant images/Paris/Cultural and Artistic Treasures/Palace of Versailles.jpg'
import r1 from '../Enchant images/Paris/Culinary Excellence/Le Jules Verne.jpg'
import r2 from '../Enchant images/Paris/Culinary Excellence/Ladurée.jpg'
import h2 from '../Enchant images/Paris/Luxurious Accommodations/Le Meurice.jpg'
import h1 from '../Enchant images/Paris/Luxurious Accommodations/The Ritz Paris.jpg'
import s1 from '../Enchant images/Paris/Wellness and Relaxation/Dior Institut at Plaza Athénée.jpg'
import e1 from '../Enchant images/Paris/Exclusive Experiences/Private Art Gallery Tour.jpg'
import e2 from '../Enchant images/Paris/Exclusive Experiences/Fashion Tour with Personal Shopper.jpg'
import b1 from '../Enchant images/Paris/High-Fashion Shopping and Boutiques/Champs-Élysées.jpg'
import b2 from '../Enchant images/Paris/High-Fashion Shopping and Boutiques/Galeries Lafayette.jpg'
import n1 from '../Enchant images/Paris/Romantic Seine River and Eiffel Tower Views/Seine River Cruise.jpg'
import n2 from '../Enchant images/Paris/Romantic Seine River and Eiffel Tower Views/Eiffel Tower Champagne Experience.jpg'
export default function Paris() {
  const [activeLink, setActiveLink] = useState("overview");

  const scrollToSection = () => {
    const targetSection = document.getElementById("j");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("itineraries");
  };
  const scrollToSection1 = () => {
    const targetSection = document.getElementById("r");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("locations");
  };
  const scrollToSection2 = () => {
    const targetSection = document.getElementById("k");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("hotels");
  };

  return (
    <>
      <div className="div">
        <div className="destination_header">
          <img id="s" src={a} alt="" />
          <div className="he">
            <h1>
              PARIS PRESENTS A PERFECT BLEND OF OPULENCE AND CULTURAL ALLURE
            </h1>{" "}
          </div>
        </div>

        <center>
          <div className="nav2">
            <div className="div3">
              <a href="">
                <p
                  className={` ${
                    activeLink === "overview" ? "active-link" : ""
                  }`}
                >
                  OVERVIEW
                </p>
              </a>
              <a onClick={scrollToSection}>
                <p
                  className={` ${
                    activeLink === "itineraries" ? "active-link" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  ITINERARIES
                </p>
              </a>
            
              <a onClick={scrollToSection2}>
                <p
                  className={` ${activeLink === "hotels" ? "active-link" : ""}`}
                  style={{ cursor: "pointer" }}
                >
                  HOTELS
                </p>
              </a>
              <a onClick={scrollToSection1}>
                <p
                  style={{ cursor: "pointer" }}
                  className={` ${
                    activeLink === "locations" ? "active-link" : ""
                  }`}
                >
                  RESTAURANTS
                </p>
              </a>
            </div>
          </div>
        </center>

        <br />
      

        <div className="div4">
          <div className="sec1">
            <br />
           
            <h2>
              WHY SHOULD YOU TRAVEL TO PARIS WITH US?
              <br />
            
            </h2>
            <br/>
            <p style={{textAlign:"justify"}}>
            Welcome to the enchanting city of Paris, where timeless beauty, artistic inspiration, and upscale experiences come together to offer a luxurious and romantic escape. From iconic landmarks to world-class cuisine and haute couture, Paris presents a perfect blend of opulence and cultural allure. Let's delve into the world of luxury that awaits you in this captivating destination.

Paris, with its luxurious hotels, gourmet dining, and artistic treasures, offers an enchanting and opulent escape that captures both romance and cultural refinement. From staying in elegant accommodations to savoring gourmet cuisine and engaging in curated activities, Paris promises an unforgettable journey that embodies the essence of luxury and the allure of artistic sophistication.
            </p>
          </div>
          <div className="sec2">
            <br />
            
            <img
              src={t1}
              alt=""
            />
          </div>
        </div>

        <div className="div5" style={{ background: "#fafafa" }}>
          <br />
          <br />
          
          <center>
            <h2 id="j">RECOMMENDED  ITINERARIES
 IN PARIS</h2>
            <br />
            <strong>
              <p>
              Engage with Paris' rich culture and artistic heritage through curated experiences:

</p>
            </strong>
            <br />
            <br />
            <br />
          </center>

          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img
                    src={i2}
                    alt=""
                  />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Louvre Museum</h4>
                    <br />
                    
                    <p style={{textAlign:"justify"}}>
                     Explore the world-renowned Louvre Museum, home to iconic masterpieces like the Mona Lisa and the Venus de Milo.
                    </p>
                    <br />
                   
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img
                    src={i1}
                    alt=""
                  />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}> Palace of Versailles</h4>
                    <br />
                 
                    <p style={{textAlign:"justify"}}>
                     Discover the opulence of the Palace of Versailles, a UNESCO World Heritage site known for its grand architecture, lavish interiors, and extensive gardens.
                    </p>
                    <br />
                  
                  </div>
                </div>
              </div>
            </div>
          </center>

         
          <center>
            <h2 id="r">CULINARY EXCELLENCE</h2>
            <br />
            <strong>
              <p>
              Indulge in a culinary journey that celebrates Paris' gourmet dining scene.
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>
       
          <center>

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img
                    src={r1}
                    alt=""
                  />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Le Jules Verne</h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                     Savor gourmet dishes at Le Jules Verne, an exclusive restaurant located within the Eiffel Tower, offering panoramic views and Michelin-starred cuisine.
                    </p>
                    <br />
                  
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img
                    src={r2}
                    alt=""
                  />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}> Ladurée</h4>
                    <br />
                    
                    <p style={{textAlign:"justify"}}>
                   Enjoy a sensory experience at Ladurée, where you can savor exquisite macarons and pastries in an elegant and historic setting.
                    </p>
                    <br />
                   
                  </div>
                </div>
              </div>
            </div>
          </center>
        
          <center>
            <h2 id="k">LUXURIOUS ACCOMODATIONS</h2>
            <br />
            <strong>
              <p>
              Paris boasts a selection of opulent hotels that provide the perfect blend of comfort and elegance.


              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>

          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img
                    src={h1}
                    alt=""
                  />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>
                    The Ritz Paris
                    </h4>
                    <br />
                    <p style={{textAlign:"justify"}}>
                    Immerse yourself in luxury at The Ritz, a historic hotel offering sumptuous suites, personalized service, and a location near iconic attractions.

                    </p>{" "}
                    <br />
                   
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img
                    src={h2}
                    alt=""
                  />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>
                    Le Meurice
                    </h4>
                    <br />
                    <p style={{textAlign:"justify"}}>
                   Experience refined luxury at Le Meurice, a palace hotel known for its regal interiors, exquisite dining, and proximity to the Louvre.
                    </p>{" "}
                    <br />
                   
                  </div>
                </div>
              </div>
            </div>
          </center>
        </div>
      </div>
   
      <center>
        <h2 id="k">WELLNESS AND RELAXATION</h2>
        <br />
        <strong>
          <p>
          Rejuvenate your senses at Paris' luxury wellness centers and spas.


          </p>
        </strong>
        <br />
        <br />
        <br />
      </center>
      <center>
        {/* this is two templates */}

        <div className="hotel">
          <div className="h2" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg2">
              <img
                src={s1}
                alt=""
              />
              <div className="dp2">
                <br />
                <h4 style={{ textAlign: "start" }}>
                Dior Institut at Plaza Athénée
                </h4>
                <br />
              
                <p style={{textAlign:"justify"}}>
                Immerse yourself in holistic wellness at the Dior Institut, offering a range of treatments that combine French beauty expertise with relaxation techniques.
                </p>{" "}
                <br />
               
              </div>
            </div>
          </div>
        </div>
      </center>
      
    

      <center>
        <h2 id="j">EXCLUSIVE EXPERIENCES</h2>
        <br />
        <strong>
          <p>
          Elevate your Parisian journey with extraordinary activities          </p>
        </strong>
        <br />
        <br />
        <br />
      </center>

      <center>
        {/* this is two templates */}

        <div className="hotel">
          <div className="h1" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg1">
              <img
                src={e1}
                alt=""
              />
              <div className="dp1">
                <br />
                <h4 style={{ textAlign: "start" }}>Private Art Gallery Tour</h4>
                <br />
                <p style={{textAlign:"justify"}}>
                 Embark on a private tour of Paris' renowned art galleries, accompanied by an expert guide who will provide insights into the city's artistic treasures.
                </p>{" "}
                <br />
               
              </div>
            </div>
          </div>

          <div className="h2" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg2">
              <img
                src={e2}
                alt=""
              />
              <div className="dp2">
                <br />
                <h4 style={{ textAlign: "start" }}>Fashion Tour with Personal Shopper</h4>
                <br />
                <p style={{textAlign:"justify"}}>
                 Enjoy a personalized shopping experience with a fashion expert who will guide you through the city's trendiest boutiques and help you curate your Parisian style.
                </p>{" "}
                <br />
             
              </div>
            </div>
          </div>
        </div>
      </center>
      <br />
      <center>
        <h2 id="k">HIGH FASHION SHOPPING AND BOUTIQUES</h2>
        <br />
        <strong>
          <p>
          Experience Paris' status as a global fashion capital with luxury boutiques and designer stores:



          </p>
        </strong>
        <br />
        <br />
        <br />
      </center>
      <center>
        {/* this is two templates */}

        <div className="hotel">
          <div className="h1" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg1">
              <img
                src={b1}
                alt=""
              />
              <div className="dp1">
                <br />
                <h4 style={{ textAlign: "start" }}>  Champs-Élysées</h4>
                <br />
                
                <p style={{textAlign:"justify"}}>
                Explore the Champs-Élysées, lined with flagship stores of luxury brands, high-end fashion boutiques, and stylish cafes.                </p>
                <br />
               
              </div>
            </div>
          </div>

          <div className="h2" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg2">
              <img
                src={b2}
                alt=""
              />
              <div className="dp2">
                <br />
                <h4 style={{ textAlign: "start" }}>Galeries Lafayette:</h4>
                <br />
               
                <p style={{textAlign:"justify"}}>
                Immerse yourself in luxury shopping at Galeries Lafayette, an iconic department store known for its designer selections and elegant Art Nouveau architecture.                </p>
                <br />
               
              </div>
            </div>
          </div>
        </div>
      </center>
    
      <center>
            <h2 id="k">ROMANTIC SEINE RIVER AND EIFFEL TOWER VIEWS</h2>
            <br />
            <strong>
              <p>
              Experience the romance of Paris with exclusive activities and breathtaking views             </p>
            </strong>
            <br />
            <br />
            <br />
          </center>
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img
                    src={n1}
                    alt=""
                  />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}> Seine River Cruise</h4>
                    <br />
                
                    <p style={{textAlign:"justify"}}>
                   Set sail on a private Seine River cruise, enjoying enchanting views of Paris' landmarks while sipping champagne and indulging in gourmet cuisine.                    </p>
                    <br />
                 
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img
                    src={n2}
                    alt=""
                  />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}> Eiffel Tower Champagne Experience</h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                     Elevate your Eiffel Tower visit with a champagne experience at the summit, enjoying sparkling views of the city's glittering lights.                    </p>
                    <br />
                 
                  </div>
                </div>
              </div>
            </div>
          </center>
    </>
  );
}
