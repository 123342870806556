import React, { useState } from "react";
import "./destination.css";
import i from "./images/Delhi1.jpg";
import i2 from "../Enchant images/Delhi/Cultural Immersion/qutub-minar-2155776_1280.jpg";
import n1 from '../Enchant images/Delhi/Historical Exploration/N1.jpeg'
export default function Destination() {
  const [activeLink, setActiveLink] = useState("overview");

  const scrollToSection = () => {
    const targetSection = document.getElementById("j");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("itineraries");
  };
  const scrollToSection1 = () => {
    const targetSection = document.getElementById("r");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("locations");
  };
  const scrollToSection2 = () => {
    const targetSection = document.getElementById("k");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("hotels");
  };

  return (
    <>
      <br />
      <br />
      <div className="div">
        <div className="destination_header">
          <img
            src="https://cdn.pixabay.com/photo/2020/02/04/20/30/delhi-4819250_1280.jpg"
            alt=""
            id="s"
          />

          <div className="he">
            <h1>LUXURY HOLIDAYS & HONEYMOONS IN NEW DELHI</h1>
          </div>
        </div>

        <center>
          <div className="nav2">
            <div className="div3">
              <a href="">
                <p
                  className={` ${
                    activeLink === "overview" ? "active-link" : ""
                  }`}
                >
                  OVERVIEW
                </p>
              </a>
              <a onClick={scrollToSection}>
                <p
                  className={` ${
                    activeLink === "itineraries" ? "active-link" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  ITINERARIES
                </p>
              </a>

              <a onClick={scrollToSection2}>
                <p
                  className={` ${activeLink === "hotels" ? "active-link" : ""}`}
                  style={{ cursor: "pointer" }}
                >
                  HOTELS
                </p>
              </a>
              <a onClick={scrollToSection1}>
                <p
                  style={{ cursor: "pointer" }}
                  className={` ${
                    activeLink === "locations" ? "active-link" : ""
                  }`}
                >
                  RESTAURANTS
                </p>
              </a>
            </div>
          </div>
        </center>

        <br />

        <div className="div4">
          <div className="sec1">
            <br />

            <h2>
              WHY SHOULD YOU TRAVEL TO DELHI WITH US?
              <br />
            </h2>
            <br />
            <p style={{textAlign:"justify"}}>
              Welcome to the vibrant capital city of India, Delhi, where ancient
              history meets modern luxury. With its rich cultural heritage,
              opulent accommodations, and exquisite dining experiences, Delhi
              offers a blend of elegance and grandeur that caters to discerning
              travelers. Let's explore the world of luxury that awaits you in
              this dynamic and diverse city. Delhi, with its luxurious
              accommodations, rich history, and diverse experiences, offers a
              fusion of elegance and cultural immersion. From staying in
              historic hotels to savoring gourmet cuisine and engaging in
              exclusive activities, Delhi promises an unforgettable journey that
              captures the essence of luxury and heritage.
            </p>
          </div>
          <div className="sec2">
            <br />
            
            <img
              src="https://cdn.pixabay.com/photo/2016/11/18/07/19/buildings-1833543_1280.png"
              alt=""
            />
          </div>
        </div>

        <div className="div5" style={{ background: "#fafafa" }}>
          <br />
          <br />
          
          <center>
            <h2 id="j">RECOMMENDED ITINERARIES IN DELHI</h2>
            <br />
            <strong>
              <p>
                Nothing but the best. Browse the hotels that can feature in your
                bespoke Delhi itinerary
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>
          <div className="fhotel">
            <div className="d1">
              <img
                src="https://cdn.pixabay.com/photo/2020/02/02/17/22/ancient-4813648_1280.jpg"
                alt=""
              />
            </div>
            <div
              className="d2"
              style={{
                background: "white",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              <br />
              <div className="d21">
                <h4>Humayun's Tomb</h4>
                <br />
              
               
                <p style={{textAlign:"justify"}}>
                  A historic gem, this luxury hotel offers a timeless blend of
                  colonial charm and modern sophistication. From opulent suites
                  to world-class amenities, The Imperial promises an
                  unforgettable stay.
                </p>
                <br /> <br />
              </div>
            </div>
          </div>
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={i2} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Qutub Minar</h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                      A contemporary oasis in the heart of the city, The Lodhi
                      offers spacious suites, a world-class spa, and
                      personalized service that ensures a lavish experience.
                    </p>
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img
                    src="https://cdn.pixabay.com/photo/2013/03/14/16/46/lotus-temple-93564_1280.jpg"
                    alt=""
                  />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Lotus temple</h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                      Renowned for its North Indian cuisine, Bukhara offers a
                      rustic yet elegant dining experience. Savor classic dishes
                      cooked in tandoors and enjoy the cozy ambiance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </center>

         
          <center>
            <h2 id="k">RECOMMENDED HOTELS IN DELHI</h2>
            <br />
            <strong>
              <p>
                Nothing but the best. Browse the hotels that can feature in your
                bespoke Delhi itinerary
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>
        
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img
                    src="https://images.unsplash.com/photo-1611892440504-42a792e24d32?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aG90ZWwlMjByb29tfGVufDB8fDB8fHww&auto=format&fit=crop&w=600&q=60"
                    alt=""
                  />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>The Lodhi</h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                      A contemporary oasis in the heart of the city, The Lodhi
                      offers spacious suites, a world-class spa, and
                      personalized service that ensures a lavish experience.
                    </p>
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img
                    src="https://theimperialindia.com/wp-content/uploads/2022/11/A19I7687-op-1024x683.jpg"
                    alt=""
                  />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>
                    The Imperial
                    </h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                    A historic gem, this luxury hotel offers a timeless blend of
                  colonial charm and modern sophistication. From opulent suites
                  to world-class amenities, The Imperial promises an
                  unforgettable stay.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </center>
      
          <center>
            <h2 id="r">RECOMMENDED RESTAURANTS IN DELHI</h2>
            <br />
            <strong>
              <p>
                Nothing but the best. Browse the hotels that can feature in your
                bespoke Delhi itinerary
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>

          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img
                                   src="https://i0.wp.com/www.thelodhi.com/wp-content/uploads/sites/3/2020/10/room10-slider2-1.jpg?w=1300&ssl=1"

                    alt=""
                  />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Indian Accent</h4>
                    <br />
                    
                    <p style={{textAlign:"justify"}}>
                    A historic gem, this luxury hotel offers a timeless blend of
                  colonial charm and modern sophistication. From opulent suites
                  to world-class amenities, The Imperial promises an
                  unforgettable stay.
                    </p>
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img
                    src={n1}
                    alt=""
                  />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>
                      Bukhara at ITC Maurya
                    </h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                      Renowned for its North Indian cuisine, Bukhara offers a
                      rustic yet elegant dining experience. Savor classic dishes
                      cooked in tandoors and enjoy the cozy ambiance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </center>
        </div>
      </div>
      <br />
    
    </>
  );
}
