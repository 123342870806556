import i from "./Navbar/ice-7660302_1280.webp";
export default function Privacypolicy() {
  return (
    <>
      <div className="destination_header">
        <img id="s" src={i} alt="" />
        <div className="he">
          <h1 style={{letterSpacing:"10px"}}>PRIVACY POLICY</h1>
        </div>
      </div>
      <br />
      <br />
     
<div style={{maxWidth:"1200px",margin:"0 auto"}}>
      <div className="ab2" style={{ paddingLeft: "30px", padding: "30px" }}>
        <h3>Privacy Policy</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          Your Privacy Matters. Enchantxp’s mission is to connect the world’s
          professionals to allow them to be more productive and successful.
          Central to this mission is our commitment to be transparent about the
          data we collect about you, how it is used and with whom it is shared.
          This Privacy Policy applies when you use our platform (described
          below). We offer our users choices about the data we collect, use and
          share as described in this Privacy Policy, Cookie Policy, Settings and
          our Help Center.
        </p>
        <br />
        <h3>View our Privacy Policies</h3> <br />
        <p>Table of Contents</p>
        <br />
        <ol>
          <li>1.Introduction</li>
          <li>2.Data We Collect</li>
          <li>3.How We Use Your Data</li>
          <li>4.How We Share Information</li>
          <li>5.Your Choices And Obligations</li>
          <li>6.Other Important Informations</li>
        </ol>
        <br /> 
        <h3>   Introduction</h3> <br />
        <p style={{textAlign:"justify"}}>
        
          We are a community building/management digital platform for
          social/professional/personal usage. People use our platform to find
          and be found for social/business/personal use opportunities, to
          connect with others and find/share information. Our Privacy Policy
          applies to any Member enrolling on our platform. Our registered users
          (“Members”) share their professional identities, engage with their
          network, exchange knowledge and professional insights, post and view
          relevant content, learn and develop skills, and find business and
          career opportunities. Content and data on some of our Platform is
          viewable as per the user registration.
        </p>
        <br />
        <h3>Platform</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          This Privacy Policy, including our <strong>Cookie Policy</strong>{" "}
          applies to your use of our platform. This Privacy Policy applies to
          Enchantxp.network, Enchantxp-branded apps, Enchantxp Learning and
          other Enchantxp-related sites, apps, communications and platform (“
          <strong>Platform</strong> "), including in-app branding/marketing
          platform, which is our advertising network, the “Apply with Enchantxp”
          and “Share with Enchantxp” plugins, but excluding platform that state
          that they are offered under a different privacy policy.
        </p>
        <br />
        <h3> Change in policies</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          Changes to the Privacy Policy apply to your use of our Platform after
          the “effective date.” Enchantxp (“we” or “us”) can modify this Privacy
          Policy, and if we make material changes to it, we will provide notice
          through our notification platform, or by other means, to provide you
          the opportunity to review the changes before they become effective. If
          you object to any changes, you may{" "}
          <strong> close your account</strong>. You acknowledge that your
          continued use of our Platform after we publish or send a notice about
          our changes to this Privacy Policy means that the collection, use and
          sharing of your personal data is subject to the updated Privacy
          Policy, as of its effective date.
        </p>
        <br />
        <h3>1.Data We Collect</h3>
        <br />
        <p>1.1 Data You Provide To Us</p>
        <br />
        <p>You provide data to create an account with us.</p> <br />
        <p style={{textAlign:"justify"}}>
          <strong>Registration</strong> To create an account you need to provide
          data including your name, & mobile number & gets verified by an
          autosubmit OPT verification system.{" "}
          <strong>
            If you register for a premium Service, you will need to provide
            payment (e.g., credit card) and billing information.*
          </strong>
          You create your Enchantxp profile (a complete profile helps you get
          the most from our Platform).
        </p>
        <br />
        <p style={{textAlign:"justify"}}>
          {" "}
          <strong>Profile</strong> You have <strong>choices</strong> about the
          information on your profile, such as your email, id proof, skills,
          photo, <strong>city or area</strong> and endorsements. Some Members
          may choose to complete a separate <strong>ProFinder profile.</strong>{" "}
          You don’t have to provide additional information on your profile;
          however, profile information helps you to get more from our Platform,
          including helping potential audience and business opportunities find
          you. It’s your choice whether to include{" "}
          <strong> sensitive information</strong>.On your profile and to make
          that sensitive information public. Please do not post or add personal
          data to your profile that you would not want to be publicly available.
          You give other data to us, such as by syncing your address book or
          calendar.
        </p>
        <br />
        <p style={{textAlign:"justify"}}>
          <strong>Posting and Uploading</strong> We collect personal data from
          you when you provide, post or upload it to our Platform, such as when
          you fill out a form, (e.g., with demographic data or salary), respond
          to a survey, or submit a resume or fill out a job application on our
          Platform. If you opt to import your address book, we receive your
          contacts (including contact information your service provider(s) or
          app automatically added to your address book when you communicated
          with addresses or numbers not already in your list). If you sync your
          contacts or calendars with our Platform, we will collect your address
          book and calendar meeting information to keep growing your network by
          suggesting connections for you and others, and by providing
          information about events, e.g. times, places, attendees and contacts.
          You don’t have to post or upload personal data; though if you don’t,
          it may limit your ability to grow and engage with your network over
          our Platform.
        </p>
        <br />
        <p>
          {" "}
          <strong>1.2 Data From Others</strong> Others may post or write about
          you.
        </p>
        <br />
        <p style={{textAlign:"justify"}}>
          <strong> Content and News</strong> You and others may post content
          that includes information about you (as part of articles, posts,
          comments, videos) on our Platform. Unless you <strong>opt-out</strong>
          , we collect public information about you, such as
          professional-related news and accomplishments (e.g., patents granted,
          professional recognition, conference speakers, projects, etc.) and
          make it available as part of our Platform (e.g. suggestions for your
          profile, or notifications to others of{" "}
          <strong> mentions in the news)</strong> ). Others may sync their
          contacts or calendar with our Platform.
        </p>
        <br />
        <p style={{textAlign:"justify"}}>
          <strong> Contact and Calendar Information</strong> We receive personal
          data (including contact information) about you when others import or
          sync their contacts or calendar with our Platform, associate their
          contacts with Member profiles, scan and upload business cards, or send
          messages using our Platform (including invites or connection
          requests). If you or others opt-in to sync email accounts with our
          Platform, we will also collect “email header” information that we can
          associate with Member profiles. Customers and partners may provide
          data to us.
        </p>
        <br />
        <h3>Partners</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          We receive personal data (e.g., your job title and work email address)
          about you when you use the platform of our customers and partners,
          such as employers or prospective employers and applicant tracking
          systems providing us job application data.
        </p>
        <br />
        <strong>Related Companies and Other Platform</strong>
        <br />
        <p style={{textAlign:"justify"}}>
          We receive data about you when you use some of the other platform
          provided by us or our affiliates , including Microsoft. For example,
          you may choose to send us information about your contacts in Microsoft
          apps and platform, such as Outlook, for improved professional
          networking activities on our Platform.
        </p>
        <br />
        <h3>1.3 Service Use</h3> <br /> <br />
        <p style={{textAlign:"justify"}}>
          We log your visits and use of our Platform, including mobile apps. We
          log usage data when you visit or otherwise use our Platform, including
          our sites, app and platform technology, such as when you view or click
          on content (e.g., learning video) or ads (on or off our sites and
          apps), perform a search, install or update one of our mobile apps,
          share articles or apply for jobs. We use log-ins, cookies, device
          information and internet protocol (“IP”) addresses to identify you and
          log your use.
        </p>
        <br />
        <h3>1.4 Cookies and Similar Technologies</h3>
        <br />
        <br />
        <p style={{textAlign:"justify"}}>
          We collect data through cookies and similar technologies. As further
          described in our <strong>Cookie Policy</strong> , we use cookies and
          similar technologies (e.g., pixels and ad tags) to collect data (e.g.,
          device IDs) to recognize you and your device(s) on, off and across
          different platform and devices where you have engaged with our
          Platform. We also allow some others to use cookies as described in our
          Cookie Policy. If you are outside the Designated Countries, we also
          collect (or rely on others who collect) information about your device
          where you have not engaged with our Platform (e.g., ad ID, IP address,
          operating system and browser information) so we can provide our
          Members with relevant ads and better understand their effectiveness.{" "}
          <strong>Learn more</strong>. You can <strong>opt out</strong>
          out from our use of data from cookies and similar technologies that
          track your behavior on the sites of others for ad targeting and other
          ad-related purposes. For Visitors, the controls are{" "}
          <strong>here</strong>.
        </p>
        <br />
        <br />
        <p style={{textAlign:"justify"}}>
          We receive data from your devices and networks, including location
          data. When you visit or leave our Platform (including some plugins and
          our cookies or similar technology on the sites of others), we receive
          the URL of both the site you came from and the one you go to and the
          time of your visit. We also get information about your network and
          device (e.g., IP address, proxy server, operating system, web browser
          and add-ons, device identifier and features, cookie IDs and/or ISP, or
          your mobile carrier). If you use our Platform from a mobile device,
          that device will send us data about your location based on your phone
          settings. We will ask you to opt-in before we use GPS or other tools
          to identify your precise location.
        </p>{" "}
        <br />
        <h3>1.6 Messages</h3> <br />
        <p style={{textAlign:"justify"}}>

          If you communicate through our Platform, we learn about that. We
          collect information about you when you send, receive, or engage with
          messages in connection with our Platform. For example, if you get a
          Enchantxp connection request, we track whether you have acted on it
          and will send you reminders. We also use automatic{" "}
          <strong>scanning technology</strong>
          on messages to support and protect our site. For example, we use this
          technology to suggest possible responses to messages and to manage or
          block content that violates our User Agreement or{" "}
          <strong>Professional Community Policies</strong> from our Platform.
        </p>{" "}
        <br />
        <h3>1.7 Workplace and School Provided Information</h3> <br />
        <p style={{textAlign:"justify"}}>
          When your organization (e.g., employer or school) buys a premium
          Service for you to use, they give us data about you.
        </p>{" "}
        <br />
        <p style={{textAlign:"justify"}}>
          Others buying our Platform for your use, such as your employer or your
          school, provide us with personal data about you and your eligibility
          to use the Platform that they purchase for use by their workers,
          students or alumni. For example, we will get contact information for “
          <strong>Company Page</strong>” administrators and for authorizing
          users of our premium Platform, such as our recruiting, sales or
          learning products.
        </p>
        <br />
        <br />
        <h3>1.8 Sites and Platform of Others</h3> <br />
        <p style={{textAlign:"justify"}}>
          We get data when you visit sites that include our ads, cookies or some
          of our plugins or when you log-in to others’ platform with your
          Enchantxp account. We receive information about your visits and
          interaction with platform provided by others when you log-in with
          Enchantxp or visit others’ platform that include some of our plugins
          (such as “Apply with Enchantxp”) or our ads, cookies or similar
          technologies
        </p><br/>
        <h3>1.9 Other</h3>
        <p style={{textAlign:"justify"}}>
          We are improving our Platform, which means we get new data and create
          new ways to use data. Our Platform are dynamic, and we often introduce
          new features, which may require the collection of new information. If
          we collect materially different personal data or materially change how
          we collect, use or share your data, we will notify you and may also
          modify this Privacy Policy.
        </p><br/>
        <h2>2. How We Use Your Data</h2> <br />
        <p style={{textAlign:"justify"}}>
          We use your data to provide, support, personalize and develop our
          Platform. How we use your personal data will depend on which Platform
          you use, how you use those Platform and the choices you make in your{" "}
          <strong>settings</strong> . We use the data that we have about you to
          provide and personalize our Platform, including with the help of
          automated systems and inferences we make, so that our Platform
          (including ads) can be more relevant and useful to you and others.
        </p>
        <br />
        <h3>2.1 Platform</h3> <br />
        <p style={{textAlign:"justify"}}>
          Our Platform help you connect with others, find and be found for work
          and business opportunities, stay informed, get training and be more
          productive. We use your data to authorize access to our Platform and
          honor your settings.
        </p>{" "}
        <br />
        <h3>Stay Connected</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          Our Platform allow you to stay in touch and up to date with
          colleagues, partners, clients, and other professional contacts. To do
          so, you can “connect” with the professionals who you choose, and who
          also wish to “connect” with you. Subject to your and their{" "}
          <strong> settings</strong>, when you connect with other Members, you
          will be able to search each others’ connections in order to exchange
          professional opportunities. We use data about you (such as your
          profile, profiles you have viewed or data provided through address
          book uploads or partner integrations) to help others find your
          profile, suggest connections for you and others (e.g. Members who
          share your contacts or job experiences) and enable you to invite
          others to become a Member and connect with you. You can also opt-in to
          allow us to use your precise location or proximity to others for
          certain tasks (e.g. to suggest other <strong>nearby</strong>
          Members for you to connect with, calculate the commute to a new job,
          or notify your connections that you are at a professional event). It
          is your choice whether to invite someone to our Platform, send a
          connection request, or allow another Member to become your connection.
          When you invite someone to connect with you, your invitation will
          include your network and basic profile information (e.g., name,
          profile photo, job title, region). We will send invitation reminders
          to the person you invited. You can <strong>choose</strong> whether or
          not to share your own list of connections with your connections.
          Visitors have <strong>choices</strong>
          about how we use their data. <strong> Stay Informed</strong> Our
          Platform allow you to stay informed about news, events and ideas
          regarding professional topics you care about, and from professionals
          you respect. Our Platform also allow you to improve your professional
          skills, or learn new ones. We use the data we have about you (e.g.,
          data you provide, data we collect from your engagement with our
          Platform and inferences we make from the data we have about you), to
          personalize our Platform for you, such as by recommending or ranking
          relevant content and conversations on our Platform. We also use the
          data we have about you to suggest skills you could add to your profile
          and skills that you might need to pursue your next opportunity. So, if
          you let us know that you are interested in a new skill (e.g., by
          watching a learning video), we will use this information to
          personalize content in your feed, suggest that you follow certain
          members on our site, or suggest related learning content to help you
          towards that new skill. We use your content, activity and other data,
          including your name and photo, to provide notices to your network and
          others. For example, subject to your <strong>settings</strong>, we may
          notify others that you have updated your profile, posted content, took
          a social action , used a feature, made new connections or were{" "}
          <strong>mentioned in the news.</strong>.
        </p>
        <br />
        <h3>Career</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          Our Platform allow you to explore careers, evaluate educational
          opportunities, and seek out, and be found for, career opportunities.
          Your profile can be found by those looking to hire (for a job or a
          <strong> specific task</strong> ) or be hired by you. We will use your
          data to recommend jobs or mentees, show you and others relevant
          professional contacts (e.g., who work at a company, in an industry,
          function or location or have certain skills and connections). You can
          signal that you are
          <strong> interested</strong> in changing jobs and share information
          with recruiters. We will use your data to recommend jobs to you and
          you to recruiters. We may use automated systems to provide content and
          recommendations to help make our Platform more relevant to our
          Members, Visitors and customers. Keeping your profile accurate and
          up-to-date may help you better connect to others and to opportunities
          through our Platform
        </p>{" "}
        <br />
        <h3>Productivity</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          Our Platform allow you to collaborate with colleagues, search for
          potential clients, customers, partners and others to do business with.
          Our Platform allow you to communicate with other Members and schedule
          and prepare meetings with them. If your <strong>settings</strong>{" "}
          allow, we scan messages to provide “bots” or similar tools that
          facilitate tasks such as scheduling meetings, drafting responses,
          summarizing messages or recommending next steps.{" "}
          <strong>Learn more</strong>
        </p>
        <br />
        <h3>2.2 Premium Platform</h3> <br />
        <p style={{textAlign:"justify"}}>
          Our premium Platform help paying users to search for and contact
          Members through our Platform, such as searching for and contacting job
          candidates, sales leads and co-workers, manage talent and promote
          content through social media.
        </p>{" "}
        <br />
        <p style={{textAlign:"justify"}}>
          We sell premium Platform that provide our customers and subscribers
          with customized-search functionality and tools (including messaging
          and activity alerts) as part of our talent, marketing and sales
          solutions. Customers can export limited information from your profile,
          such as name, headline, current company, current title, and general
          location (e.g., Dublin), such as to manage sales leads or talent,
          unless you <strong>opt-out</strong>. We do not provide contact
          information to customers as part of these premium Platform without
          your consent. Premium Platform customers can store information they
          have about you in our premium Platform, such as a resume or contact
          information or sales history. The data stored about you by these
          customers is subject to the policies of those customers. Other
          enterprise Platform and features that use your data include{" "}
          <strong> TeamLink and Elevate</strong> (social promotion of content).
        </p>{" "}
        <br />
        <h3>2.3 Communications</h3> <br />
        <p style={{textAlign:"justify"}}>
          We contact you and enable communications between Members. We offer
          settings to control what messages you receive and how often you
          receive some types of messages. We will contact you through email,
          mobile phone, notices posted on our websites or apps, messages to your
          Enchantxp inbox, and other ways through our Platform, including text
          messages and push notifications. We will send you messages about the
          availability of our Platform, security, or other service-related
          issues. We also send messages about how to use our Platform, network
          updates, reminders, job suggestions and promotional messages from us
          and our partners. You may change your communication{" "}
          <strong>preferences </strong>at any time. Please be aware that you
          cannot opt out of receiving service messages from us, including
          security and legal notices. We also enable
          <strong> communications</strong> between you and others through our
          Platform, including for example{" "}
          <strong>invitations, InMail, groups </strong> and{" "}
          <strong> messages</strong> between connections.
        </p>
        <br />
        <h3>2.4 Advertising</h3> <br />
        <p style={{textAlign:"justify"}}>
          We serve you tailored ads both on and off our Platform. We offer you
          choices regarding personalized ads, but you cannot opt-out of seeing
          other ads. We target (and measure the performance of) ads to Members,
          Visitors and others both on and off our Platform directly or through a
          variety of partners , using the following data, whether separately or
          combined:
        </p>{" "}
        <br />
        <ul>
          <li>
            1.Data from advertising technologies on and off our Platform,
            pixels, ad tags, cookies, and device identifiers;
          </li>
          <li>
            2.Member-provided information (e.g., profile, contact information,
            title and industry);
          </li>
          <li>
            3.Data from your use of our Platform (e.g., search history, feed,
            content you read, who you follow or is following you, connections,{" "}
            <strong>groups</strong> participation, page visits, videos you
            watch, clicking on an ad, etc.), including as described in Section
            1.3;
          </li>
          <li>
            4.Information from advertising partners, vendors and{" "}
            <strong>publishers</strong> ; and
          </li>
          <li >
            5.Information inferred from data described above (e.g., using job
            titles from a profile to infer industry, seniority, and compensation
            bracket; using graduation dates to infer age or using first names or
            pronoun usage to infer gender; using your feed activity to infer
            your interests; or using device data to recognize you as a Member).
            We will show you ads called <strong>sponsored content</strong> which
            look similar to non-sponsored content, except that they are labeled
            as advertising (e.g., as “ad” or “sponsored”). If you take a social
            action (such as like, comment or share) on these ads, your action is
            associated with your name and viewable by others, including the
            advertiser. Subject to your <strong>settings</strong>, if you take a
            social action on the Enchantxp Platform, that action may be
            mentioned with related ads.
          </li>
        </ul>
        <br />
        <strong>Ad Choices</strong> <br />
        <p style={{textAlign:"justify"}}>
          We adhere to <strong>self-regulatory principles</strong> for
          interest-based advertising and participate in industry{" "}
          <strong>opt-outs </strong> from such ads. This does not opt you out of
          receiving advertising; you will continue to get other ads by
          advertisers not listed with these self regulatory tools. You can also{" "}
          <strong>opt-out</strong> specifically from our uses of certain
          categories of data to show you more relevant ads. For Visitors, the
          setting is <strong>here</strong>.
        </p>{" "}
        <br />
        <strong>Info to Ad Providers</strong>
        <br />
        <p style={{textAlign:"justify"}}>
          We do not share your personal data with any third-party advertisers or
          ad networks except for: (i) hashed IDs or device identifiers (to the
          extent they are personal data in some countries); (ii) with your
          separate permission (e.g., in a lead generation form) or (iii) data
          already visible to any users of the Platform (e.g., profile). However,
          if you view or click on an ad on or off our Platform, the ad provider
          will get a signal that someone visited the page that displayed the ad,
          and they may, through the use of mechanisms such as cookies, determine
          it is you. Advertising partners can associate personal data collected
          by the advertiser directly from you with hashed IDs or device
          identifiers received from us. In such instances, we seek to
          contractually require such advertising partners to obtain your
          explicit, opt-in consent before doing so.
        </p><br/>
        <h3>2.5 Marketing</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          We promote our Platform to you and others. In addition to advertising
          our Platform, we use Members’ data and content for invitations and
          communications promoting membership and network growth, engagement and
          our Platform, such as by showing your connections that you have used a
          feature on our Platform.
        </p>
        <br />
        <h3>2.6 Developing Platform and Research</h3>
        <br />
        <p style={{textAlign:"justify"}}>We develop our Platform and conduct research.</p> <br />
        <strong>Service Development</strong> <br />
        <p style={{textAlign:"justify"}}>
          We use data, including public feedback, to conduct{" "}
          <strong>research</strong> and development for our Platform in order to
          provide you and others with a better, more intuitive and personalized
          experience, drive membership growth and engagement on our Platform,
          and help connect professionals to each other and to economic
          opportunity.
        </p>{" "}
        <br />
        <strong>Other Research</strong> <br />
        <p style={{textAlign:"justify"}}>
          We seek to create economic opportunity for Members of the global
          workforce and to help them be more productive and successful. We use
          the personal data available to us to research social, economic and
          workplace trends, such as jobs availability and skills needed for
          these <strong>jobs</strong> and policies that help bridge the gap in
          various industries and geographic areas. In some cases, we work with
          trusted third parties to perform this research, under{" "}
          <strong>controls</strong> that are designed to protect your privacy.
          We publish or allow others to publish economic insights, presented as
          aggregated data rather than personal data.
        </p>{" "}
        <br />
        <strong>Surveys</strong> <br />
        <p style={{textAlign:"justify"}}>
          Polls and surveys are conducted by us and others through our Platform.
          You are not obligated to respond to polls or surveys, and you have
          choices about the information you provide. You may{" "}
          <strong>opt-out</strong> of survey invitations.
        </p>{" "}
        <br />
        <h3>2.7 Customer Support</h3> <br />
        <p style={{textAlign:"justify"}}>
          We use data to help you and fix problems. We use data (which can
          include your communications) to investigate, respond to and resolve
          complaints and for Service issues (e.g., bugs).
        </p>{" "}
        <br />
        <h3>2.8 Insights That Do Not Identify You</h3> <br />
        <p style={{textAlign:"justify"}}>
          We use data to generate insights that do not identify you. We use your
          data to produce and share insights that do not identify you. For
          example, we may use your data to generate statistics about our
          members, their profession or industry, to calculate ad impressions
          served or clicked on, or to publish visitor demographics for a Service
          or create demographic workforce insights.
        </p>{" "}
        <br />
        <h3>2.9 Security and Investigations</h3> <br />
        <p style={{textAlign:"justify"}}>
          We use data for security, fraud prevention and investigations. We use
          your data (including your communications) for security purposes or to
          prevent or investigate possible fraud or other violations of our{" "}
          <strong>User Agreement</strong> and/or attempts to harm our
          Members,Visitors or others.
        </p>{" "}
        <br /> <h3>3. How We Share Information</h3> <br />
        <h3>3.1 Our Platform</h3> <br />
        <p style={{textAlign:"justify"}}>
          Any data that you include on your profile and any content you post or
          social action (e.g., likes, follows, comments, shares) you take on our
          Platform will be seen by others, consistent with your settings.
        </p>{" "}
        <br />
        <strong>Profile</strong> <br />
        <p style={{textAlign:"justify"}}>
          Your profile is fully visible to all Members and customers of our
          Platform. Subject to your <strong> settings</strong>, it can also be
          visible to others on or off of our Platform (e.g., Visitors to our
          Platform or users of third- party search engines). As detailed in our{" "}
          <strong>Help Center</strong>, your degree of connection with the
          viewing Member, the subscriptions they may have, their{" "}
          <strong>usage of our Platform</strong>, access channels and search
          types (e.g., by name or by keyword) impact the availability of your
          profile and whether they can view certain fields in your profile.{" "}
          <strong>Posts, Likes, Follows, Comments, Messages</strong> Our
          Platform allow viewing and sharing information including through
          posts, likes, follows and comments.
        </p>{" "}
        <br />
        <li style={{ listStyle: "disc" }}>
          When you share an article or a post (e.g., an update, image, video or
          article) publicly it can be viewed by everyone and re-shared anywhere
          (subject to your settings). Members, Visitors and others will be able
          to find and see your publicly-shared content, including your name (and
          photo if you have provided one).
        </li>{" "}
        <li style={{ listStyle: "disc" }}>
          In a group, posts are visible to others in the group. Your membership
          in groups is public and part of your profile, but you can change
          visibility in your settings.
        </li>{" "}
        <li style={{ listStyle: "disc" }}>
          Any information you share through companies’ or other organizations’
          pages on our Platform will be viewable by it and others who visit
          those pages.
        </li>
        <li style={{ listStyle: "disc" }}>
          When you follow a person or organization, you are visible to others
          and that “page owner” as a follower.
        </li>{" "}
        <li style={{ listStyle: "disc" }}>
          We let senders know when you act on their message, subject to your
          settings where applicable.
        </li>{" "}
        <li style={{ listStyle: "disc" }}>
          Subject to your settings, we let a Member know when you view their
          profile.
        </li>{" "}
        <li style={{ listStyle: "disc" }}>
          When you like or re-share or comment on another’s content (including
          ads), others will be able to view these “social actions” and associate
          it with you (e.g., your name, profile and photo if you provided it).
          Your employer can see how you use Platform they provided for your work
          (e.g. as a recruiter or sales agent) and related information. We will
          not show them your job searches or personal messages.
        </li>
        <br />
        <strong>Enterprise Accounts</strong>
        <br />
        <p style={{textAlign:"justify"}}>
          Your employer may offer you access to our enterprise Platform such as
          Recruiter, Sales Navigator, Enchantxp Learning or our advertising
          Campaign Manager. Your employer can review and manage your use of such
          enterprise Platform. Depending on the enterprise Service, before you
          use such Service, we will ask for permission to share with your
          employer relevant data from your profile or use of our non-enterprise
          Platform. For example, users of <strong>Sales Navigator</strong> will
          be asked to share their “social selling index”, a score calculated in
          part based on their personal account activity. We understand that
          certain activities such as job hunting and personal messages are
          sensitive, and so we do not share those with your employer unless you
          choose to share it with them through our Platform (for example, by
          applying for a new position in the same company or mentioning your job
          hunting in a message to a co-worker through our Platform). Subject to
          your <strong>settings</strong>, when you use workplace tools and
          platform (e.g., interactive employee directory tools) certain of your
          data may also be made available to your employer or be connected with
          information we receive from your employer to enable these tools and
          platform.
        </p>{" "}
        <br />
        <h3>3.2 Communication Archival</h3> <br />
        <p style={{textAlign:"justify"}}>
          Regulated Members may need to store communications outside of our
          Service. Some Members (or their employers) need, for legal or
          professional compliance, to archive their communications and social
          media activity, and will use platform of others to provide these
          archival platform. We enable archiving of messages by and to those
          Members outside of our Platform. For example, a financial advisor
          needs to archive communications with her clients through our Platform
          in order to maintain her professional financial advisor license.
        </p>{" "}
        <br />
        <h3>3.3 Others’ Platform</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          You may link your account with others’ platform so that they can look
          up your contacts’ profiles, post your shares on such platforms, or
          enable you to start conversations with your connections on such
          platforms. Excerpts from your profile will also appear on the platform
          of others. Subject to your <strong>settings</strong>, other platform
          may look up your profile. When you opt to link your account with other
          platform, personal data will become available to them. The sharing and
          use of that personal data will be described in, or linked to, a
          consent screen when you opt to link the accounts. For example, you may
          link your Twitter or WeChat account to share content from our Platform
          into these other platform, or your email provider may give you the
          option to upload your Enchantxp contacts into its own service.
          Third-party platform have their own privacy policies, and you may be
          giving them permission to use your data in ways we would not. You may{" "}
          <strong>revoke</strong> the link with such accounts. Subject to your{" "}
          <strong>settings</strong>, excerpts from your
          <strong> profile</strong> will appear on the platform of others (e.g.,
          search engine results, mail and calendar applications that show a user
          limited profile data of the person they are meeting or messaging,
          social media aggregators , talent and lead managers). “Old” profile
          information remains on these platform until they update their data
          cache with changes you made to your profile.
        </p>{" "}
        <br />
        <h3>3.4 Related Platform</h3> <br />
        <p style={{textAlign:"justify"}}>
          We share your data across our different Platform and Enchantxp
          affiliated entities. We will share your personal data with our
          affiliates to provide and develop our Platform. We may combine
          information internally across the different Platform covered by this
          Privacy Policy to help our Platform be more relevant and useful to you
          and others. For example, we may personalize your feed or job
          recommendations based on your learning history.
        </p>{" "}
        <br />
        <h3>3.5 Service Providers</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          We may use others to help us with our Platform. We use others to help
          us provide our Platform (e.g., maintenance, analysis, audit, payments,
          fraud detection, marketing and development). They will have access to
          your information as reasonably necessary to perform these tasks on our
          behalf and are obligated not to disclose or use it for other purposes.
        </p>{" "}
        <br />
        <h3>3.6 Legal Disclosures</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          We may need to share your data when we believe it’s required by law or
          to help protect the rights and safety of you, us or others. It is
          possible that we will need to disclose information about you when
          required by law, subpoena, or other legal process or if we have a good
          faith belief that disclosure is reasonably necessary to (1)
          investigate, prevent or take action regarding suspected or actual
          illegal activities or to assist government enforcement agencies; (2)
          enforce our agreements with you; (3) investigate and defend ourselves
          against any third-party claims or allegations; (4) protect the
          security or integrity of our Platform (such as by sharing with
          companies facing similar threats); or (5) exercise or protect the
          rights and safety of Enchantxp, our Members, personnel or others. We
          attempt to notify Members about legal demands for their personal data
          when appropriate in our judgment, unless prohibited by law or court
          order or when the request is an emergency. We may dispute such demands
          when we believe, in our discretion, that the requests are overbroad,
          vague or lack proper authority, but we do not promise to challenge
          every demand. To learn more see our{" "}
          <strong>Data Request Guidelines </strong> and
          <strong> Transparency Report.</strong>{" "}
        </p>{" "}
        <br />
        <h3>3.7 Change in Control or Sale</h3> <br />
        <p style={{textAlign:"justify"}}>
          We may share your data when our business is sold to others, but it
          must continue to be used in accordance with this Privacy Policy. We
          can also share your personal data as part of a sale, merger or change
          in control, or in preparation for any of these events. Any other
          entity which buys us or part of our business will have the right to
          continue to use your data, but only in the manner set out in this
          Privacy Policy unless you agree otherwise.
        </p>
        <br />
        <h3>4. Your Choices & Obligations</h3> <br />
        <h3>4.1 Data Retention</h3> <br />
        <p style={{textAlign:"justify"}}>
          We keep most of your personal data for as long as your account is
          open. We generally retain your personal data as long as you keep your
          account open or as needed to provide you Platform. This includes data
          you or others provided to us and data generated or inferred from your
          use of our Platform. Even if you only use our Platform when looking
          for a new job every few years, we will retain your information and
          keep your profile open, unless you close your account. In some cases
          we choose to retain certain information (e.g., insights about Platform
          use) in a depersonalized or aggregated form.
        </p>{" "}
        <br />
        <h3>4.2 Rights to Access and Control Your Personal Data</h3> <br />
        <p style={{textAlign:"justify"}}>
          You can access or delete your personal data. You have many{" "}
          <strong>choices</strong>
          about how your data is collected, used and shared. We provide many
          choices about the collection, use and sharing of your data, from
          deleting or correcting data you include in your{" "}
          <strong> profile</strong> and controlling the visibility of your{" "}
          <strong> posts</strong> to advertising <strong> opt-outs</strong> and
          <strong> communication controls</strong>. We offer you{" "}
          <strong>settings </strong> to control and manage the personal data we
          have about you (for SlideShare, please <strong> contact us</strong> ).
        </p>{" "}
        <br />
        <p>For personal data that we have about you, you can:</p> <br />
        <ul>
          <li style={{ listStyle: "disc" }}>
            {" "}
            <strong>Delete Data</strong> : You can ask us to erase or delete all
            or some of your personal data (e.g., if it is no longer necessary to
            provide Platform to you).
          </li>
          <li style={{ listStyle: "disc" }}>
            {" "}
            <strong>Change or Correct Data</strong>: You can edit some of your
            personal data through your account. You can also ask us to change,
            update or fix your data in certain cases, particularly if it’s
            inaccurate.
          </li>
          <li style={{ listStyle: "disc" }}>
            {" "}
            <strong>Object to, or Limit or Restrict, Use of Data</strong>: You
            can ask us to stop using all or some of your personal data (e.g., if
            we have no legal right to keep using it) or to limit our use of it
            (e.g., if your personal data is inaccurate or unlawfully held).
          </li>{" "}
          <li style={{ listStyle: "disc" }}>
            {" "}
            <strong>Right to Access and/or Take Your Data</strong>: You can ask
            us for a copy of your personal data and can ask for a copy of
            personal data you provided in machine readable form. Visitors can
            learn more about how to make these requests here. You may also
            contact us using the contact information below, and we will consider
            your request in accordance with applicable laws. Residents in the
            Designated Countries and other regions may have additional rights
            under their laws. <strong>Learn More</strong> .
          </li>
        </ul>
        <br />
        <h3>4.3 Account Closure</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          4.3 <strong>Account Closure</strong> We keep some of your data even
          after you close your account. If you choose to close your{" "}
          <strong> Enchantxp (or SlideShare)</strong>
          account, your personal data will generally stop being visible to
          others on our Platform within 24 hours. We generally delete closed
          account information within 30 days of account closure, except as noted
          below. We retain your personal data even after you have closed your
          account if reasonably necessary to comply with our legal obligations
          (including law enforcement requests), meet regulatory requirements,
          resolve disputes, maintain security, prevent fraud and abuse (e.g., if
          we have <strong>restricted</strong> your account for breach of our{" "}
          <strong>Professional Community Policies</strong> ), enforce our User
          Agreement, or fulfill your request to “unsubscribe” from further
          messages from us. We will retain de-personalized information after
          your account has been closed. Information you have shared with others
          (e.g., through InMail, updates or group posts) will remain visible
          after you close your account or delete the information from your own
          profile or mailbox, and we do not control data that other Members have
          copied out of our Platform. Groups content and ratings or review
          content associated with closed accounts will show an unknown user as
          the source. Your profile may continue to be displayed in the platform
          of others (e.g., search engine results) until they refresh their
          cache.
        </p>{" "}
        <br />
        <h3>5. Other Important Information</h3> <br />
        <h3>5.1. Security</h3> <br />
        <p style={{textAlign:"justify"}}>
          We monitor for and try to prevent security breaches. Please use the
          security features available through our Platform. We implement
          security safeguards designed to protect your data, such as HTTPS. We
          regularly monitor our systems for possible vulnerabilities and
          attacks. However, we cannot warrant the security of any information
          that you send us. There is no guarantee that data may not be accessed,
          disclosed, altered, or destroyed by breach of any of our physical,
          technical, or managerial safeguards. Please visit our{" "}
          <strong>Safety Center</strong>
          for additional information about safely using our Platform, including
          <strong>two-factor authentication</strong> .
        </p>{" "}
        <br />
        <h3>5.2. Cross-Border Data Transfers</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          We store and use your data outside your country. We process data both
          inside and outside of the United States and rely on legally-provided
          mechanisms to lawfully transfer data across borders.{" "}
          <strong>Learn more</strong> . Countries where we process data may have
          laws which are different from, and potentially not as protective as,
          the laws of your own country.
        </p>{" "}
        <br />
        <h3>5.3 Lawful Bases for Processing</h3> <br />
        <p style={{textAlign:"justify"}}>
          We have lawful bases to collect, use and share data about you. You
          have choices about our use of your data. At any time, you can withdraw
          consent you have provided by going to settings. We will only collect
          and process personal data about you where we have lawful bases. Lawful
          bases include consent (where you have given consent), contract (where
          processing is necessary for the performance of a contract with you
          (e.g., to deliver the Enchantxp Platform you have requested) and
          “legitimate interests.” <strong>Learn more</strong> . Where we rely on
          your consent to process personal data, you have the right to withdraw
          or decline your consent at any time and where we rely on legitimate
          interests, you have the right to object. <strong>Learn More</strong> .
          If you have any questions about the lawful bases upon which we collect
          and use your personal data, please contact our Data Protection Officer{" "}
          <strong> here</strong> .
        </p>{" "}
        <br /> <h3>5.4. Direct Marketing and Do Not Track Signals</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          Our statements regarding direct marketing and “do not track” signals.
          We currently do not share personal data with third parties for their
          direct marketing purposes without your permission.{" "}
          <strong> Learn more</strong> about this and about our response to “do
          not track” signals.
        </p>
        <br />
        <h3>5.5. Contact Information</h3> <br />
        <p style={{textAlign:"justify"}}>
          You can contact us or use other options to resolve any complaints. If
          you have questions or complaints regarding this Policy, please first
         <strong>  contact Enchantxp</strong> online. You can also reach us by <strong> physical mail</strong>. If
          contacting us does not resolve your complaint, you have more  <strong>options</strong>.
          Residents in the Designated Countries and other regions may also have
          the right to contact our Data Protection Officer here.If this does not
          resolve your complaint, Residents in the Designated Countries and
          other regions may have more  <strong>options</strong> under their laws. <strong>Learn More</strong>
        </p>
      </div></div>
    </>
  );
          }