import React from "react";
import "./logis.css"; // Make sure to import the CSS file
import "@fortawesome/fontawesome-free/css/all.min.css";
import i from './Components/conatcthero.jpg'
function Logis() {
  return (
    <>
      <div className="destination_header">
        <img
          id="s"
          src={i}
          alt=""
        />
       
      </div>
      <br />
      <br />
      <br />
      <br />
      <center>
        <div className="dig1">
          <h2 >Reach Us</h2>
        </div>
        <br /><br />
        <div className="dig2" style={{width:"60%"}}>
          <p style={{lineHeight:"22px",textAlign:"justify"}}>
            We would love to hear from you and assist you in planning your next
            luxury travel experience. Please feel free to reach out to us using
            the following contact information: Our experienced team of travel
            advisors is available to answer any questions you may have and
            assist you in creating a tailor-made itinerary that matches your
            preferences and desires. Whether you're interested in a luxury beach
            getaway, a cultural exploration, or an adventure in a remote
            destination, we're here to turn your travel dreams into reality. If
            you prefer, you can also fill out the contact form below, and one of
            our representatives will get back to you promptly. Please provide us
            with as much detail as possible regarding your travel plans,
            including your preferred travel dates, destination, number of
            travelers, and any specific requirements or preferences you may
            have. We understand that luxury travel is all about personalization,
            attention to detail, and exceptional service. Rest assured that your
            information will be handled with the utmost confidentiality, and we
            will use it solely for the purpose of assisting you with your travel
            needs. Thank you for considering Enchant Experience Pte Ltd for your
            luxury travel arrangements. We look forward to creating a truly
            unforgettable travel experience for you.
          </p>
        </div>
 <br /><br /> <br /><br /><br />
    <h2>Singapore</h2>
    <br />
            <p>7 Temasek Boulevard, #12-07 Suntec Tower One
</p>
<p>Singapore, 038987
</p>
<p>Phone: +65 8333 7861
</p>
<p>Email: enchant@enchantxp.com

</p>
      </center>
    </>
  );
}

export default Logis;
