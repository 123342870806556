import React, { useState } from "react";
import "./destination.css";
import i from "./images/MaasaiMara.jpg";
import i1 from "../Enchant images/Maasai Mara/MaasaiMara_1.jpg";
import i2 from "../Enchant images/Maasai Mara/MaasaiMara_2.jpg";
import i3 from "../Enchant images/Maasai Mara/MaasaiMara_3.jpg";
import i4 from "../Enchant images/Maasai Mara/MaasaiMara_4.jpg";
import i5 from "../Enchant images/Maasai Mara/MaasaiMara_5.jpg";
import i6 from "../Enchant images/Maasai Mara/MaasaiMara_6.jpg";
import i7 from "../Enchant images/Maasai Mara/MaasaiMara_7.jpg";
import i8 from "../Enchant images/Maasai Mara/MaasaiMara_8.jpg";
import t1 from "../Enchant images/Maasai Mara/cover.jpg";

export default function MasaaiMara() {
  const [activeLink, setActiveLink] = useState("overview");

  const scrollToSection = () => {
    const targetSection = document.getElementById("accomodation");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("Accomodations");
  };
  const scrollToSection1 = () => {
    const targetSection = document.getElementById("beach");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("Beaches");
  };
  const scrollToSection2 = () => {
    const targetSection = document.getElementById("dive");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("Dive");
  };
  const scrollToSection3 = () => {
    const targetSection = document.getElementById("hotel");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("hotels");
  };
  const scrollToSection4 = () => {
    const targetSection = document.getElementById("tours");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("tours");
  };
  const scrollToSection5 = () => {
    const targetSection = document.getElementById("relax");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("relax");
  };
  const scrollToSection6 = () => {
    const targetSection = document.getElementById("luxury");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("luxury");
  };
  const scrollToSection7 = () => {
    const targetSection = document.getElementById("nature");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("nature");
  };

  return (
    <>
      <div className="div">
        <div className="destination_header">
          <img id="s" src={i} alt="" />
          <div className="he">
            <h1>
              MAASAI MARA : ABUNDANT WILDLIFE, STUNNING VISTAS, AND HIGH-END
              ACCOMMODATIONS
            </h1>
          </div>
        </div>

        <br />

        <div className="div4">
          <div className="sec1">
            <br />

            <h2>
              WHY SHOULD YOU TRAVEL TO MAASAI MARA WITH US?
              <br />
            </h2>
            <br />
            <p style={{ textAlign: "justify" }}>
              Welcome to the Maasai Mara, a land of untamed wilderness and
              breathtaking landscapes that offer a luxurious safari experience
              like no other. Situated in Kenya, the Maasai Mara is renowned for
              its abundant wildlife, stunning vistas, and high-end
              accommodations that cater to discerning travelers. The Maasai Mara
              offers a unique combination of luxury and adventure, allowing you
              to immerse yourself in the beauty of the African wilderness while
              enjoying unparalleled comfort and service. Whether you're
              witnessing the Great Migration, exploring the cultural heritage of
              the Maasai people, or embarking on thrilling game drives, the
              Maasai Mara promises an opulent safari experience that will
              forever remain etched in your memory.
            </p>
          </div>
          <div className="sec2">
            <br />

            <img src={t1} alt="" />
          </div>
        </div>
        <div className="div5" style={{ background: "#fafafa" }}>
          <br />
          <br />
          <br />
          <center>
            <h2 id="j">
              WELCOME TO THE MAASAI MARA, A LAND OF UNTAMED WILDERNESS AND
              BREATHTAKING LANDSCAPES!
            </h2>
            <br />
            <strong>
              <p>
                The Maasai Mara is renowned for its abundant wildlife, stunning
                vistas, and high-end accommodations.
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>
          {/* accomodations */}
       
          <br />

          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={i1} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}> Luxury Safari Lodges and Camps</h4>
                    <br />
                  
                    <p style={{textAlign:"justify"}}>
                    Exclusive tented camps and lodges nestled in prime wildlife
                  viewing areas.
                
                  Spacious suites and private villas with elegant furnishings
                  and modern amenities.
               
                  Personalized butler service, private verandas, and outdoor
                  showers.
                    </p>
                    <br />
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={i2} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}> Unparalleled Wildlife Encounters</h4>
                    <br />
                    
                    <p style={{textAlign:"justify"}}>
                    Expertly guided game drives offering close encounters with the
                  Big Five and other iconic species.
                 
                  Private safari vehicles for an intimate and personalized
                  wildlife viewing experience.
                 
                  Guided bush walks and hot air balloon safaris for a unique
                  perspective.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </center>

        
        
        
          <br />
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={i3} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Maasai Cultural Experiences</h4>
                    <br />
                    
                    <p style={{textAlign:"justify"}}>
                    Opportunities to interact with the Maasai people and learn
                  about their rich cultural heritage.
                  
                  Authentic Maasai-guided tours to gain insights into their
                  traditional way of life.
                
                  Visiting local communities, schools, and markets to connect
                  with the local culture.
                    </p>
                    <br />
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={i4} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Gourmet Dining in the Wild</h4>

                    <br />
                    <p style={{textAlign:"justify"}}>
                    Fine-dining experiences under the African sky, surrounded by
                  nature's beauty.
                
                  Bush breakfasts, sundowner cocktails, and candlelit dinners in
                  the heart of the wilderness.
                 
                  Expertly prepared gourmet meals showcasing local and
                  international cuisine.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </center>

         
          <br />
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={i5} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Private Wildlife Conservancies</h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                    Private Wildlife Conservancies: Staying in private
                  conservancies adjacent to the Maasai Mara National Reserve.
                 
                  Exclusive access to game drives and wildlife sightings without
                  the crowds.
                 
                  Night drives and guided walks to spot nocturnal animals and
                  experience a different side of the safari.
                    </p>
                    <br />
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={i6} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Spa and Wellness Retreats</h4>
                  
                    <br />
                    <p style={{textAlign:"justify"}}>
                    Luxury lodges offering wellness-focused treatments inspired by
                  local traditions.
                 
                  Spa facilities with serene settings and panoramic views of the
                  savannah.
                  
                  Yoga and meditation sessions that embrace the tranquility of
                  the wilderness.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </center>

          
         
          <br />
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={i7} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Hot Air Balloon Safaris</h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                    Floating over the Maasai Mara at sunrise in a hot air balloon
                  for an awe-inspiring experience.
                 
                  Bird's-eye views of the wildlife, savannah, and the meandering
                  Mara River.
                 
                  Champagne breakfasts upon landing, creating memories that last
                  a lifetime.
                    </p>
                    <br />
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={i8} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}> Conservation and Community Involvement</h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                    Engaging in responsible tourism that supports local
                  conservation efforts and community initiatives.
                
                  Participating in guided visits to conservation projects and
                  wildlife research centers.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </center>

       
        </div>
      </div>
    </>
  );
}
