import i from './b2.jpg'

export default function Terms() {
  return (
    <>
      <div className="destination_header">
        <img
          id="s"
          src={i}
          alt=""
        />
        <div className="he">
          <h1>TERMS AND CONDITIONS</h1>
        </div>
      </div>
      <br />
      <br />

    <div style={{maxWidth:"1200px",margin:"0 auto"}}>
      <div className="ab1" style={{ paddingLeft: "30px", padding: "30px" }}>
        <h3>User Agreement Table of Contents:</h3>
        <ol type="1">
          <li>1 .Introduction</li>
          <li>2 .Obligations</li>
          <li>3 .Rights and limits</li>
          <li>4 .Disclaimer and Limit of Liability</li>
          <li>5 .Termination</li>
          <li>6 .Governing Law and Dispute Resolution</li>
          <li>7 .General Terms </li>
          <li>8. Enchantxp “Dos and Don’ts”</li>
          <li>9 .Complaints Regarding Content</li>
          <li>10 .How To Contact Us</li>
        </ol>
      </div>

      <div className="ab2" style={{ paddingLeft: "30px", padding: "30px" }}>
        <h3>1. Introduction</h3>
        <br />
        <p>1.1 Contract</p>

        <br />

        <p style={{textAlign:"justify"}}>
          When you use our Platform you agree to all of these terms. Your use of
          our Platform is also subject to our Cookie Policy and our Privacy
          Policy, which covers how we collect, use, share, and store your
          personal information. You agree that by clicking “Join Now”, “Join
          Enchantxp”, “Sign Up” or similar, registering, accessing or using our
          platform (described below), you are agreeing to enter into a legally
          binding contract with Enchantxp (even if you are using our Platform on
          behalf of a company). If you do not agree to this contract (“Contract”
          or “User Agreement”), do not click “Join Now” (or similar) and do not
          access or otherwise use any of our Platform. If you wish to terminate
          this contract, at any time you can do so by closing your account and
          no longer accessing or using our Platform.
        </p>

        <br />
        <h3>Platform</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          This Contract applies to Enchantxp.com, Enchantxp-branded apps,
          Enchantxp Learning and other Enchantxp-related sites, apps,
          communications and other platform that state that they are offered
          under this Contract (“Platform”), including the offsite collection of
          data for those Platform, such as our advertising network and the
          “Apply with Enchantxp” and “Share with Enchantxp” plugins. Registered
          users of our Platform are considered as “Members”.
        </p>
        <br />
        <p>1.2 Members</p>
        <br />
        <p style={{textAlign:"justify"}}>
          When you register and join the Enchantxp platform or become a
          registered user on Enchantxp, you become a Member.
        </p>
        <br />
        <h3> Change in policies</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          We may make changes to the Contract. We may modify this Contract, our
          Privacy Policy and our Cookies Policy from time to time. If we make
          material changes to it, we will provide you notice through our
          Platform, or by other means, to provide you the opportunity to review
          the changes before they become effective. We agree that changes cannot
          be retroactive. If you object to any changes, you may{" "}
          <strong>close your account.</strong> Your continued use of our
          Platform after we publish or send a notice about our changes to these
          terms means that you are consenting to the updated terms as of their
          effective date.
        </p>
        <br />
        <h3>2. Obligations</h3>
        <br />
        <p>2.1 Service Eligibility</p>
        <br />
        <p style={{textAlign:"justify"}}>
          Here are some promises that you make to us in this Contract: You’re
          eligible to enter into this Contract and you are at least our “Minimum
          Age.” The Platform are not for use by anyone under the age of 15. To
          use the Platform, you agree that: (1) you must be the “Minimum Age”
          (described below) or older; (2) you will only have one Enchantxp
          account, which must be in your real name; and (3) you are not already
          restricted by Enchantxp from using the Platform. Creating an account
          with false information is a violation of our terms, including accounts
          registered on behalf of others or persons under the age of 16.
          “Minimum Age” means 16 years old. However, if law requires that you
          must be older in order for Enchantxp to lawfully provide the Platform
          to you without parental consent (including using of your personal
          data) then the Minimum Age is such older age.
        </p>
        <br />
        <h3>2.2 Your Account</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          You will keep your password a secret. You will not share an account
          with anyone else and will follow our rules and the law. Members are
          account holders. You agree to: (1) use a strong password and keep it
          confidential; (2) not transfer any part of your account (e.g.,
          connections) and (3) follow the law and our list of Dos and Don’ts and{" "}
          <strong>Professional Community Policies.</strong> You are responsible
          for anything that happens through your account unless you close it or
          report misuse. As between you and others (including your employer),
          your account belongs to you. However, if the Platform were purchased
          by another party for you to use (e.g. Recruiter seat bought by your
          employer), the party paying for such Service has the right to control
          access to and get reports on your use of such paid Service; however,
          they do not have rights to your personal account.
        </p>
        <br />
        <h3>2.3 Payment</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          You’ll honor your payment obligations and you are okay with us storing
          your payment information. You understand that there may be fees and
          taxes that are added to our prices. Refunds are subject to our policy.
          If you buy any of our paid Platform (“Premium Platform”), you agree to
          pay us the applicable fees and taxes and to{" "}
          <strong>additional terms</strong> specific to the paid Platform.
          Failure to pay these fees will result in the termination of your paid
          Platform. Also, you agree that:
        </p>
        <br />

        <li style={{ listStyle: "solid" }}>
          {" "}
          Your purchase may be subject to foreign exchange fees or differences
          in prices based on location (e.g. exchange rates).
        </li>

        <br />
        <li style={{ listStyle: "solid" }}>
          {" "}
          We may store and continue billing your payment method (e.g. credit
          card) even after it has expired, to avoid interruptions in your
          Platform and to use to pay other Platform you may buy.
        </li>
        <br />
        <li style={{ listStyle: "solid" }}>
          If you purchase a subscription, your payment method automatically will
          be charged at the start of each subscription period for the fees and
          taxes applicable to that period. To avoid future charges, cancel
          before the renewal date. Learn how to{" "}
          <strong>cancel or suspend</strong> your Premium Platform.
        </li>
        <br />
        <li style={{ listStyle: "solid" }}>
          All of your purchases of Platform are subject to Enchantxp’s{" "}
          <strong> refund policy.</strong>
        </li>
        <br />
        <li style={{ listStyle: "solid" }}>
          We may calculate taxes payable by you based on the billing information
          that you provide us at the time of purchase. You can get a copy of
          your invoice through your Enchantxp account settings under{" "}
          <strong> “Purchase History”.</strong>
        </li>
        <br />
        <h3>2.4 Notices and Messages</h3>

        <br />
        <p style={{textAlign:"justify"}}>
          You’re okay with us providing notices and messages to you through our
          websites, apps, and contact information. If your contact information
          is out of date, you may miss out on important notices. You agree that
          we will provide notices and messages to you in the following ways: (1)
          within the Service, or (2) sent to the contact information you
          provided us (e.g., email, mobile number, physical address). You agree
          to keep your <strong> contact information </strong> up to date. Please
          review your settings to <strong>control and limit</strong> messages
          you receive from us.
        </p>
        <br />
        <h3>2.5 Sharing</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          When you share information on our Platform, others can see, copy and
          use that information. Our Platform allow messaging and sharing of
          information in many ways, such as your profile, articles, group posts,
          links to news articles, job postings, messages and InMails.
          Information and content that you share or post may be seen by other
          Members, Visitors or others (including off of the Platform). Where we
          have made settings available, we will honor the choices you make about
          who can see content or information (e.g., message content to your
          addressees, sharing content only to Enchantxp connections, restricting
          your profile visibility from search engines, or opting not to notify
          others of your Enchantxp profile update). For job searching
          activities, we default to not notifying your connections network or
          the public. So, if you apply for a job through our Service or opt to
          signal that you are interested in a job, our default is to share it
          only with the job poster. We are not obligated to publish any
          information or content on our Service and can remove it with or
          without notice.
        </p>
        <br />
        <h3>3. Rights and Limits</h3>
        <br />
        <h3>3.1. Your License to Enchantxp</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          You own all of the content, feedback and personal information you
          provide to us, but you also grant us a non-exclusive license to it.
          We’ll honor the choices you make about who gets to see your
          information and content, including how it can be used for ads. As
          between you and Enchantxp, you own the content and information that
          you submit or post to the Platform, and you are only granting
          Enchantxp and our affiliates the following non-exclusive license: A
          worldwide, transferable and sublicensable right to use, copy, modify,
          distribute, publish and process, information and content that you
          provide through our Platform and the platform of others, without any
          further consent, notice and/or compensation to you or others. These
          rights are limited in the following ways:
        </p>
        <br />
        <p style={{textAlign:"justify"}}>
          1. You can end this license for specific content by deleting such
          content from the Platform, or generally by closing your account,
          except (a) to the extent you shared it with others as part of the
          Service and they copied, re-shared it or stored it and (b) for the
          reasonable time it takes to remove from backup and other systems.
        </p>
        <br />
        <p style={{textAlign:"justify"}}>
          2. We will not include your content in advertisements for the products
          and platform of third parties to others without your separate consent
          (including sponsored content). However, we have the right, without
          payment to you or others, to serve ads near your content and
          information, and your social actions may be visible and included with
          ads, as noted in the Privacy Policy. If you use a Service feature, we
          may mention that with your name or photo to promote that feature
          within our Platform, subject to your settings.
        </p>
        <br />
        <p style={{textAlign:"justify"}}>
          3. We will get your consent if we want to give others the right to
          publish your content beyond the Platform. However, if you choose to
          share your post as “public “, we will enable a feature that allows
          other Members to embed that public post onto third-party platform, and
          we enable search engines to make that public content findable though
          their platform. <strong>Learn More</strong>
        </p>
        <br />
        <p style={{textAlign:"justify"}}>
          4. While we may edit and make format changes to your content (such as
          translating or transcribing it, modifying the size, layout or file
          type or removing metadata), we will not modify the meaning of your
          expression.
        </p>
        <br />
        <p style={{textAlign:"justify"}}>
          5. Because you own your content and information and we only have
          non-exclusive rights to it, you may choose to make it available to
          others, including under the terms of a{" "}
          <strong> Creative Commons license</strong> You and Enchantxp agree
          that if content includes personal data, it is subject to our Privacy
          Policy. You and Enchantxp agree that we may access, store, process and
          use any information and personal data that you provide in accordance
          with, the terms of the <strong> Privacy Policy</strong> and your
          choices (including settings). By submitting suggestions or other
          feedback regarding our Platform to Enchantxp, you agree that Enchantxp
          can use and share (but does not have to) such feedback for any purpose
          without compensation to you. You promise to only provide information
          and content that you have the right to share, and that your Enchantxp
          profile will be truthful. You agree to only provide content or
          information that does not violate the law nor anyone’s rights
          (including intellectual property rights). You also agree that your
          profile information will be truthful. Enchantxp may be required by law
          to remove certain information or content in certain countries.
        </p>
        <br />
        <h3>3.2 Service Availability</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          We may change or end any Service or modify our prices prospectively.
          We may change, suspend or discontinue any of our Platform. We may also
          modify our prices effective prospectively upon reasonable notice to
          the extent allowed under the law. We don’t promise to store or keep
          showing any information and content that you’ve posted. Enchantxp is
          not a storage service. You agree that we have no obligation to store,
          maintain or provide you a copy of any content or information that you
          or others provide, except to the extent required by applicable law and
          as noted in our Privacy Policy.
        </p>
        <br />
        <h3>3.3 Other Content, Sites and Apps</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          Your use of others’ content and information posted on our Platform, is
          at your own risk. Others may offer their own products and platform
          through our Platform, and we aren’t responsible for those third-party
          activities. By using the Platform, you may encounter content or
          information that might be inaccurate, incomplete, delayed, misleading,
          illegal, offensive or otherwise harmful. Enchantxp generally does not
          review content provided by our Members or others. You agree that we
          are not responsible for others’ (including other Members’) content or
          information. We cannot always prevent this misuse of our Platform, and
          you agree that we are not responsible for any such misuse. You also
          acknowledge the risk that you or your organization may be mistakenly
          associated with content about others when we let connections and
          followers know you or your organization were mentioned in the news.
          Members have <strong>choices</strong> about this{" "}
          <strong>feature</strong>. Enchantxp may help connect Members offering
          their platform (career coaching, accounting, etc.) with Members
          seeking platform. Enchantxp does not perform nor employs individuals
          to perform these platform. You must be at least 18 years of age to
          offer, perform or procure these platform. You acknowledge that
          Enchantxp does not supervise, direct, control or monitor Members in
          the performance of these platform and agree that (1) Enchantxp is not
          responsible for the offering, performance or procurement of these
          platform, (2) Enchantxp does not endorse any particular Member’s
          offered platform, and (3) nothing shall create an employment, agency,
          or joint venture relationship between Enchantxp and any Member
          offering platform. If you are a Member offering platform, you
          represent and warrant that you have all the required licenses and will
          provide platform consistent with our{" "}
          <strong> Professional Community Policies.</strong>
          Similarly, Enchantxp may help you register for and/or attend events
          organized by Members and connect with other Members who are attendees
          at such events. You agree that (1) Enchantxp is not responsible for
          the conduct of any of the Members or other attendees at such events,
          (2) Enchantxp does not endorse any particular event listed on our
          Platform, (3) Enchantxp does not review and/or vet any of these
          events, and (4) that you will adhere to these terms and conditions
          that apply to such events.
        </p>
        <br />
        <h3>3.6 Automated Processing</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          We use data and information about you to make relevant suggestions to
          you and others. We use the information and data that you provide and
          that we have about Members to make recommendations for connections,
          content and features that may be useful to you. For example, we use
          data and information about you to recommend jobs to you and you to
          recruiters. Keeping your profile accurate and up to date helps us to
          make these recommendations more accurate and relevant.
        </p>
        <br />
        <h3>4. Disclaimer and Limit of Liability</h3>
        <br />
        <h3>4.1 No Warranty</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          This is our disclaimer of legal liability for the quality, safety, or
          reliability of our Platform. Enchantxp AND ITS AFFILIATES MAKE NO
          REPRESENTATION OR WARRANTY ABOUT THE Platform, INCLUDING ANY
          REPRESENTATION THAT THE Platform WILL BE UNINTERRUPTED OR ERROR-FREE,
          AND PROVIDE THE Platform (INCLUDING CONTENT AND INFORMATION) ON AN “AS
          IS” AND “AS AVAILABLE” BASIS. TO THE FULLEST EXTENT PERMITTED UNDER
          APPLICABLE LAW, Enchantxp AND ITS AFFILIATES DISCLAIM ANY IMPLIED OR
          STATUTORY WARRANTY, INCLUDING ANY IMPLIED WARRANTY OF TITLE, ACCURACY
          OF DATA, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR
          PURPOSE.
        </p>
        <br />
        <h3>4.2 Exclusion of Liability</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          These are the limits of legal liability we may have to you. TO THE
          FULLEST EXTENT PERMITTED BY LAW (AND UNLESS Enchantxp HAS ENTERED INTO
          A SEPARATE WRITTEN AGREEMENT THAT OVERRIDES THIS CONTRACT), Enchantxp,
          INCLUDING ITS AFFILIATES, WILL NOT BE LIABLE IN CONNECTION WITH THIS
          CONTRACT FOR LOST PROFITS OR LOST BUSINESS OPPORTUNITIES, REPUTATION
          (E.G., OFFENSIVE OR DEFAMATORY STATEMENTS),
        </p>
        <br />
        <h3>3.4 Limits</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          We have the right to limit how you connect and interact on our
          Platform. Enchantxp reserves the right to limit your use of the
          Platform, including the number of your connections and your ability to
          contact other Members. Enchantxp reserves the right to restrict,
          suspend, or terminate your account if you breach this Contract or the
          law or are misusing the Platform (e.g., violating any of the Dos and
          Don’ts or <strong>Professional Community Policies</strong> ).
        </p>
        <br />
        <h3>3.5 Intellectual Property Rights</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          We’re providing you notice about our intellectual property rights.
          Enchantxp reserves all of its intellectual property rights in the
          Platform. Trademarks and logos used in connection with the Platform
          are the trademarks of their respective owners. Enchantxp, and “in”
          logos and other Enchantxp trademarks, service marks, graphics and
          logos used for our Platform are trademarks or registered trademarks of
          Enchantxp.
        </p>
        <br />
        <p style={{textAlign:"justify"}}>
          LOSS OF DATA (E.G., DOWN TIME OR LOSS, USE OF, OR CHANGES TO, YOUR
          INFORMATION OR CONTENT) OR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL,
          SPECIAL OR PUNITIVE DAMAGES. Enchantxp AND ITS AFFILIATES WILL NOT BE
          LIABLE TO YOU IN CONNECTION WITH THIS CONTRACT FOR ANY AMOUNT THAT
          EXCEEDS (A) THE TOTAL FEES PAID OR PAYABLE BY YOU TO Enchantxp FOR THE
          Platform DURING THE TERM OF THIS CONTRACT, IF ANY, OR (B) US $1000.
        </p>
        <br />
        <h3>4.3 Basis of the Bargain; Exclusions</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          The limitations of liability in this Section 4 are part of the basis
          of the bargain between you and Enchantxp and shall apply to all claims
          of liability (e.g., warranty, tort, negligence, contract and law) even
          if Enchantxp or its affiliates has been told of the possibility of any
          such damage, and even if these remedies fail their essential purpose.
          These limitations of liability do not apply to liability for death or
          personal injury or for fraud, gross negligence or intentional
          misconduct, or in cases of negligence where a material obligation has
          been breached, a material obligation being such which forms a
          prerequisite to our delivery of platform and on which you may
          reasonably rely, but only to the extent that the damages were directly
          caused by the breach and were foreseeable upon conclusion of this
          Contract and to the extent that they are typical in the context of
          this Contract.
        </p>
        <br />
        <h3>5. Termination</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          We can each end this Contract, but some rights and obligations
          survive. Both you and Enchantxp may terminate this Contract at any
          time with notice to the other. On termination, you lose the right to
          access or use the Platform. The following shall survive termination: •
          Our rights to use and disclose your feedback; • Members and/or
          Visitors’ rights to further re-share content and information you
          shared through the Platform; • Sections 4, 6, 7, and 8.2 of this
          Contract; • Any amounts owed by either party prior to termination
          remain owed after termination.
        </p>
        <br />
        <p>
          You can visit our <strong>Help Center</strong> to close your account.
        </p>

        <br />
        <h3>6. Governing Law and Dispute Resolution:</h3>

        <br />
        <p style={{textAlign:"justify"}}>
        In the unlikely event we end up in a legal dispute, you and Enchantxp agree to resolve it in Singapore courts using Singaporean law. This section shall not deprive you of any mandatory consumer protections under the law of the country to which we direct Platform to you, where you have your habitual residence.
        </p>

        <br />
        <h3>If you live in the Designated Countries:</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          You and Enchantxp Ireland agree that the laws of Ireland, excluding
          conflict of laws rules, shall exclusively govern any dispute relating
          to this Contract and/or the Platform. You and Enchantxp Ireland agree
          that claims and disputes can be litigated only in Dublin, Ireland, and
          we each agree to personal jurisdiction of the courts located in
          Dublin, Ireland.
        </p>
        <br />
        <h3>
          For others outside of Designated Countries, including those who live
          outside of the United States:
        </h3>
        <br />
        <p style={{textAlign:"justify"}}>
          You and Enchantxp agree that the laws of the State of California,
          U.S.A., excluding its conflict of laws rules, shall exclusively govern
          any dispute relating to this Contract and/or the Platform. You and
          Enchantxp both agree that all claims and disputes can be litigated
          only in the federal or state courts in Santa Clara County, California,
          USA, and you and Enchantxp each agree to personal jurisdiction in
          those courts.
        </p>
        <br />
        <h3>7. General Terms</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          Here are some important details about the Contract. If a court with
          authority over this Contract finds any part of it unenforceable, you
          and we agree that the court should modify the terms to make that part
          enforceable while still achieving its intent. If the court cannot do
          that, you and we agree to ask the court to remove that unenforceable
          part and still enforce the rest of this Contract.
        </p>
        <br />

        <p style={{textAlign:"justify"}}>
          This Contract (including additional terms that may be provided by us
          when you engage with a feature of the Platform) is the only agreement
          between us regarding the Platform and supersedes all prior agreements
          for the Platform. If we don’t act to enforce a breach of this
          Contract, that does not mean that Enchantxp has waived its right to
          enforce this Contract. You may not assign or transfer this Contract
          (or your membership or use of Platform) to anyone without our consent.
          However, you agree that Enchantxp may assign this Contract to its
          affiliates or a party that buys it without your consent. There are no
          third-party beneficiaries to this Contract. You agree that the only
          way to provide us legal notice is at the addresses provided in Section
          10. 8. Enchantxp “Dos and Don’ts”
        </p>
        <br />
        <p style={{textAlign:"justify"}}>
          <strong>8.1. Dos </strong> Enchantxp is a community of professionals.
          This list of “Dos and Don’ts” along with our Professional Community
          Policies limit what you can and cannot do on our Platform.
        </p>
        <br />
        <p style={{textAlign:"justify"}}>
          {" "}
          <strong>You agree that you will:</strong> a. Comply with all
          applicable laws, including, without limitation, privacy laws,
          intellectual property laws, anti-spam laws, export control laws, tax
          laws, and regulatory requirements; b. Provide accurate information to
          us and keep it updated; c. Use your real name on your profile; and d.
          Use the Platform in a professional manner.
        </p>
        <br />
        <h3>8.2. Don’ts You agree that you will not:</h3>
        <br />
        <p style={{textAlign:"justify"}}>
          1 .Create a false identity on Enchantxp, misrepresent your identity,
          create a Member profile for anyone other than yourself (a real
          person), or use or attempt to use another’s account;
        </p>
        <br />
        <p style={{textAlign:"justify"}}>
         2.  Develop, support or use software, devices, scripts, robots or any
          other means or processes (including crawlers, browser plugins and
          add-ons or any other technology) to scrape the Platform or otherwise
          copy profiles and other data from the Platform;
        </p>
        <br />
        <p style={{textAlign:"justify"}}>
          3. Override any security feature or bypass or circumvent any access
          controls or use limits of the Service (such as caps on keyword
          searches or profile views);
        </p>
        <br />
        <p style={{textAlign:"justify"}}>
          4. Copy, use, disclose or distribute any information obtained from the
          Platform, whether directly or through third parties (such as search
          engines), without the consent of Enchantxp;
        </p>
        <br />
        <p style={{textAlign:"justify"}}>
          5. Disclose information that you do not have the consent to disclose
          (such as confidential information of others (including your
          employer));
        </p>
        <br />
        <p style={{textAlign:"justify"}}>
         6.  Violate the intellectual property rights of others, including
          copyrights, patents, trademarks, trade secrets or other proprietary
          rights. For example, do not copy or distribute (except through the
          available sharing functionality) the posts or other content of others
          without their permission, which they may give by posting under a
          Creative Commons license;
        </p>
        <br />
        <p style={{textAlign:"justify"}}>
          7. Violate the intellectual property or other rights of Enchantxp,
          including, without limitation, (i) copying or distributing our
          learning videos or other materials or (ii) copying or distributing our
          technology, unless it is released under open source licenses; (iii)
          using the word “Enchantxp” or our logos in any business name, email,
          or URL except as provided in the <strong>Brand Guidelines</strong>;
        </p>
        <br />
        <p style={{textAlign:"justify"}}>
          8. Post anything that contains software viruses, worms, or any other
          harmful code;
        </p>
        <br />
        <p style={{textAlign:"justify"}}>
          9. Reverse engineer, decompile, disassemble, decipher or otherwise
          attempt to derive the source code for the Platform or any related
          technology that is not open source;
        </p>
        <br />
        <p style={{textAlign:"justify"}}>
         10.  Imply or state that you are affiliated with or endorsed by Enchantxp
          without our express consent (e.g., representing yourself as an
          accredited Enchantxp trainer);
        </p>
        <br />
        <p style={{textAlign:"justify"}}>
         11.  Rent, lease, loan, trade, sell/re-sell or otherwise monetize the
          Platform or related data or access to the same, without Enchantxp’s
          consent;
        </p>
        <br />
        <p style={{textAlign:"justify"}}>
         12.  Deep-link to our Platform for any purpose other than to promote your
          profile or a Group on our Platform, without Enchantxp’s consent;
        </p>

        <br />
        <p style={{textAlign:"justify"}}>
         13.  Use bots or other automated methods to access the Platform, add or
          download contacts, send or redirect messages;
        </p>
        <br />
        <p style={{textAlign:"justify"}}>
          14 .Monitor the Platform’ availability, performance or functionality for
          any competitive purpose;
        </p>
        <br />
        <p style={{textAlign:"justify"}}>
         15 . Engage in “framing,” “mirroring,” or otherwise simulating the
          appearance or function of the Platform;
        </p>
        <br />
        <p style={{textAlign:"justify"}}>
          16. Overlay or otherwise modify the Platform or their appearance (such as
          by inserting elements into the Platform or removing, covering, or
          obscuring an advertisement included on the Platform);
        </p>
        <br />
        <p style={{textAlign:"justify"}}>
           17. Interfere with the operation of, or place an unreasonable load on, the
          Platform (e.g., spam, denial of service attack, viruses, gaming
          algorithms); and/or
        </p>

        <br />
        <p style={{textAlign:"justify"}}>
          18. Violate the <strong> Professional Community Policies </strong> or any additional terms
          concerning a specific Service that are provided when you sign up for
          or start using such Service, and the <strong> Bing Maps terms</strong> where applicable.
        </p>
        <br />
        <p style={{textAlign:"justify"}}>
          {" "}
           <strong>9. Complaints Regarding Content </strong> Contact information for complaint about
          content provided by our Members. We respect the intellectual property
          rights of others. We require that information posted by Members be
          accurate and not in violation of the intellectual property rights or
          other rights of third parties. We provide a policy and process for
          complaints concerning content posted by our Members.
        </p>

        <br />

        <p style={{textAlign:"justify"}}>
          <strong> 10. How To Contact Us</strong> Our Contact information. <strong> Our Help Center</strong> also
          provides information about our Platform. For general inquiries, you
          may contact us online. For legal notices or service of process, you
          may write us at these <strong>addresses</strong>
        </p>
       
        
      </div></div>
    </>
  );
}
