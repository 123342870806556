import React, { useState } from "react";
import "./destination.css";
import i from "./images/London.jpg";
import i1 from '../Enchant images/London/Cultural and architechtural  marvales/The British Museum.jpg'
import i2 from '../Enchant images/London/Cultural and architechtural  marvales/Buckingham Palace.jpg'
import r1 from '../Enchant images/London/Culinary Excellence/Alain Ducasse at The Dorchester.jpg'
import r2 from '../Enchant images/London/Culinary Excellence/Sketch.jpg'
import b1 from '../Enchant images/London/High-end shopping mall and market/Bond Street.jpg'
import b2 from '../Enchant images/London/High-end shopping mall and market/Borough Market.jpg'
import h1 from '../Enchant images/London/Luxurious Accommodation/The Ritz London.jpg'
import h2 from "../Enchant images/London/Luxurious Accommodation/Claridge's London.jpg"
import e1 from '../Enchant images/London/exclusive Experience/Private Thames River Cruise.jpg'
import e2 from '../Enchant images/London/exclusive Experience/Private Art Gallery Tour.jpg'
import s1 from '../Enchant images/London/wellness & Relaxation/The Bulgari Spa.jpg'
import t1 from '../Enchant images/London/Theater & Entertainment/West End Shows.jpg'
export default function London() {
  const [activeLink, setActiveLink] = useState("overview");

  const scrollToSection = () => {
    const targetSection = document.getElementById("j");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("itineraries");
  };
  const scrollToSection1 = () => {
    const targetSection = document.getElementById("r");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("locations");
  };
  const scrollToSection2 = () => {
    const targetSection = document.getElementById("k");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("hotels");
  };

  return (
    <>
      <div className="div">
        <div className="destination_header">
          <img id="s" src={i} alt="" />
          <div className="he">
            <h1>
              EXPERIENCE LONDON'S LUXURY: FROM ICONIC LANDMARKS TO GOURMET
              DINING AND EXCLUSIVE SHOPPING
            </h1>
          </div>
        </div>

        <center>
          <div className="nav2">
            <div className="div3">
              <a href="">
                <p
                  className={` ${
                    activeLink === "overview" ? "active-link" : ""
                  }`}
                >
                  OVERVIEW
                </p>
              </a>
              <a onClick={scrollToSection}>
                <p
                  className={` ${
                    activeLink === "itineraries" ? "active-link" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  ITINERARIES
                </p>
              </a>
           
              <a onClick={scrollToSection2}>
                <p
                  className={` ${activeLink === "hotels" ? "active-link" : ""}`}
                  style={{ cursor: "pointer" }}
                >
                  HOTELS
                </p>
              </a>
              <a onClick={scrollToSection1}>
                <p
                  style={{ cursor: "pointer" }}
                  className={` ${
                    activeLink === "locations" ? "active-link" : ""
                  }`}
                >
                  RESTAURANTS
                </p>
              </a>
            </div>
          </div>
        </center>

        <br />
       

        <div className="div4">
          <div className="sec1">
            <br />
        
            <h2>
              WHY SHOULD YOU TRAVEL TO LONDON WITH US?
              <br />
              
            </h2>
            <br />
            <p style={{textAlign:"justify"}}>
            Welcome to the cosmopolitan and historic city of London, where timeless elegance, cultural diversity, and upscale experiences converge to offer a luxurious and enchanting getaway. From iconic landmarks to world-class dining and exclusive shopping, London presents a blend of opulence and tradition. Let's explore the world of luxury that awaits you in this dynamic destination.

London, with its luxurious hotels, gourmet dining, and cultural marvels, offers an indulgent and captivating escape that captures both tradition and modernity. From staying in elegant accommodations to savoring gourmet cuisine and engaging in curated activities, London promises an unforgettable journey that embodies the essence of luxury and the allure of cosmopolitan living. 
            </p>
          </div>
          <div className="sec2">
            <br />
            
            <img
              src={t1}
              alt=""
            />
          </div>
        </div>

        <div className="div5" style={{ background: "#fafafa" }}>
          <br />
          <br />
          
          <center>
            <h2 id="j">RECOMMENDED ITINERARIES IN LONDON</h2>
            <br />
            <strong>
              <p>
                London, with its luxurious hotels, gourmet dining, and cultural
                marvels, offers an indulgent and captivating escape that
                captures both tradition and modernity.
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>

          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img
                    src={i1}
                    alt=""
                  />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>The British Museum</h4>
                    <br />
                 
                    <p style={{textAlign:"justify"}}>
                      Explore the world-renowned British Museum, home to an
                      extensive collection of art and artifacts from various
                      cultures and civilizations.
                    </p>
                    <br />
                 
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img
                    src={i2}
                    alt=""
                  />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Buckingham Palace</h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                      Witness the Changing of the Guard ceremony at Buckingham
                      Palace and admire the grandeur of this royal residence.
                    </p>
                    <br />
                  
                  </div>
                </div>
              </div>
            </div>
          </center>

         

          <center>
            <h2 id=""> HIGH-END SHOPPING AND MARKETS </h2>
            <br />
            <strong>
              <p>
                Experience London's reputation as a shopping mecca with upscale
                boutiques and vibrant markets
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>

          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img
                    src={b1}
                    alt=""
                  />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Bond Street</h4>
                    <br />
                 
                    <p style={{textAlign:"justify"}}>
                      Explore Bond Street, a prestigious shopping destination
                      lined with luxury brands, designer boutiques, and fine
                      jewelry stores.
                    </p>
                    <br />
                  
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img
                    src={b2}
                    alt=""
                  />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Borough Market</h4>
                    <br />
                    
                    <p style={{textAlign:"justify"}}>
                      Immerse yourself in local flavors at Borough Market, where
                      you can find artisanal produce, gourmet treats, and
                      international cuisine.
                    </p>
                    <br />
                  
                  </div>
                </div>
              </div>
            </div>
          </center>

      
          <center>
            <h2 id="k">LUXURIOUS ACCOMODATIONS</h2>
            <br />
            <strong>
              <p>
                London boasts a selection of luxurious hotels that provide the
                perfect blend of comfort and sophistication.
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>

          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img
                    src={h1}
                    alt=""
                  />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>The Ritz London</h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                      Immerse yourself in luxury at The Ritz, an iconic hotel
                      offering elegant suites, impeccable service, and an
                      ambiance reminiscent of a bygone era.
                    </p>
                    <br />
                   
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img
                    src={h2}
                    alt=""
                  />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Claridge's</h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                      Experience opulence at Claridge's, a historic hotel known
                      for its timeless charm, luxurious accommodations, and
                      exceptional dining experiences.
                    </p>
                    <br />
                   
                  </div>
                </div>
              </div>
            </div>
          </center>

          <center>
            <h2 id="r">  CULINARY EXCELLENCE </h2>
            <br />
            <strong>
              <p>
                Indulge in a culinary journey that showcases London's diverse
                and gourmet dining scene.
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>

          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img
                    src={r1}
                    alt=""
                  />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>
                      Alain Ducasse at The Dorchester
                    </h4>
                    <br />
                    <p style={{textAlign:"justify"}}>
                      Savor gourmet dishes at Alain Ducasse at The Dorchester,
                      where Michelin-starred cuisine is paired with an elegant
                      setting and panoramic views.
                    </p>{" "}
                    <br />
                  
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img
                    src={r2}
                    alt=""
                  />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Sketch</h4>
                    <br />
                    <p style={{textAlign:"justify"}}>
                      Enjoy a fusion of art and dining at Sketch, where artistic
                      interiors and creative cuisine create an immersive and
                      luxurious experience.
                    </p>{" "}
                    <br />
                  
                  </div>
                </div>
              </div>
            </div>
          </center>
        </div>
      

      <center>
        <h2 id="k">WELLNESS AND RELAXATION</h2>
        <br />
        <strong>
          <p>
            Rejuvenate your senses at London's luxury wellness centers and spas.
          </p>
        </strong>
        <br />
        <br />
        <br />
      </center>
      <center>
        {/* this is two templates */}

        <div className="hotel">
          <div className="h2" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg2">
              <img
                src={s1}
                alt=""
              />
              <div className="dp2">
                <br />
                <h4 style={{ textAlign: "start" }}>The Bulgari Spa</h4>
                
                <br />
                <p style={{textAlign:"justify"}}>
                  Immerse yourself in holistic wellness at The Bulgari Spa,
                  offering a range of treatments that combine ancient and modern
                  techniques in an elegant setting.
                </p>
                <br />
               
              </div>
            </div>
          </div>
        </div>
      </center>
   
      <center>
        <h2 id="j">EXCLUSIVE EXPERIENCES
</h2>
        <br />
        <strong>
          <p>Elevate your London journey with extraordinary activities:</p>
        </strong>
        <br />
        <br />
        <br />
      </center>

      <center>
        {/* this is two templates */}

        <div className="hotel">
          <div className="h1" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg1">
              <img
                src={e1}
                alt=""
              />
              <div className="dp1">
                <br />
                <h4 style={{ textAlign: "start" }}>
                  Private Thames River Cruise
                </h4>
                <br />
                <p style={{textAlign:"justify"}}>
                  Set sail on a private Thames River cruise, enjoying iconic
                  views of London's landmarks while sipping champagne and
                  relishing in personalized service.
                </p>{" "}
                <br />
             
              </div>
            </div>
          </div>

          <div className="h2" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg2">
              <img
                src={e2}
                alt=""
              />
              <div className="dp2">
                <br />
                <h4 style={{ textAlign: "start" }}>Private Art Gallery Tour</h4>
                <br />
                <p style={{textAlign:"justify"}}>                  Embark on a private tour of London's renowned art galleries,
                  accompanied by an expert guide who will provide insights into
                  the city's artistic treasures.
                </p>{" "}
                <br />
             
              </div>
            </div>
          </div>
        </div>


        <p style={{textAlign:"end",color:"white"}}>&copy;&nbsp;2023,Cambliss Pvt. Ltd.</p>

      </center>
      <br />
      <br />
    
      </div>
    </>
  );
}
