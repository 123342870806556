import React, { useState } from "react";
import "./destination.css";
import i from './images/Gokarna.jpg'

import t1 from '../Enchant images/Gokarna/Local Culture & Exploration/Gokarna Main Street Shopping.jpg'
import i1 from '../Enchant images/Gokarna/Secluded Beach Escapes/Om Beach.jpg'
import i2 from '../Enchant images/Gokarna/Secluded Beach Escapes/Half Moon Beach2.jpg'
import i3 from '../Enchant images/Gokarna/Secluded Beach Escapes/Mahabaleshwar Temple.jpg'
import h1 from '../Enchant images/Gokarna/Luxury accommodation/Kahani Paradise.jpg'
import h2 from '../Enchant images/Gokarna/Luxury accommodation/Swaswara, CGH Earth.jpg'
import r1 from '../Enchant images/Gokarna/Culinary Excellence/Mantra Cafe.jpg'
import r2 from '../Enchant images/Gokarna/Culinary Excellence/Namaste Cafe.jpg'
import s1 from '../Enchant images/Gokarna/Yoga and wellness/ayurvedic spa.jpg'

export default function Gokarna() {
  const [activeLink, setActiveLink] = useState("overview");

  const scrollToSection = () => {
    const targetSection = document.getElementById("j");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("itineraries");
  };
  const scrollToSection1 = () => {
    const targetSection = document.getElementById("r");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("locations");
  };
  const scrollToSection2 = () => {
    const targetSection = document.getElementById("k");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("hotels");
  };

  return (
    <>
      <div className="div">
        <div className="destination_header" >
          <img
           id="s"
            src={i}
            alt=""         />
          <div className="he">
            <h1>LUXURY HOLIDAYS & HONEYMOONS IN GOKARNA</h1>
          </div>
        </div>

        <center>
          <div className="nav2">
            <div className="div3">
              <a href="">
                <p
                  className={` ${
                    activeLink === "overview" ? "active-link" : ""
                  }`}
                >
                  OVERVIEW
                </p>
              </a>
              <a onClick={scrollToSection}>
                <p
                  className={` ${
                    activeLink === "itineraries" ? "active-link" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  ITINERARIES
                </p>
              </a>
            
              <a onClick={scrollToSection2}>
                <p
                  className={` ${activeLink === "hotels" ? "active-link" : ""}`}
                  style={{ cursor: "pointer" }}
                >
                  HOTELS
                </p>
              </a>
              <a onClick={scrollToSection1}>
                <p
                  style={{ cursor: "pointer" }}
                  className={` ${
                    activeLink === "locations" ? "active-link" : ""
                  }`}
                >
                  RESTAURANTS
                </p>
              </a>
            </div>
          </div>
        </center>

        <br />
       

        <div className="div4">
          <div className="sec1">
            <br />
           
            <h2>
              WHY SHOULD YOU TRAVEL TO GOKARNA WITH US?
              <br />
             
            </h2>
            <br/>
          <p style={{textAlign:"justify"}}>
          Welcome to the tranquil coastal haven of Gokarna, where pristine beaches, spiritual sanctuaries, and luxurious experiences converge. Known as a quieter alternative to Goa, Gokarna offers a blend of serenity, luxury, and cultural richness that's perfect for discerning travelers. Get ready to explore the exquisite side of Gokarna and discover the perfect balance between relaxation and indulgence.

Gokarna offers luxury travelers a unique blend of coastal beauty, spiritual exploration, and indulgent experiences. From upscale accommodations to gourmet dining and wellness retreats, this destination promises a harmonious balance of relaxation and cultural engagement. Immerse yourself in the serene charm of Gokarna and create lasting memories that embody the essence of luxury and natural beauty.
          </p>
          </div>
          <div className="sec2">
            <br />
            
            <img
              src={t1}
              alt=""
            />
          </div>
        </div>

        <div className="div5" style={{ background: "#fafafa" }}>
          <br />
          <br />
       
          <center>
            <h2 id="j">RECOMMENDED ITINERARIES IN GOKARNA</h2>
            <br />
            <strong>
              <p>
              Gokarna's pristine beaches are ideal for luxury travelers seeking relaxation and 
              cultural heritage through curated experiences:
</p>
            </strong>
            <br />
            <br />
            <br />
          </center>
          <div className="fhotel">
            <div className="d1">
              <img
                src={i1}
                alt=""
              />
            </div>
            <div
              className="d2"
              style={{
                background: "white",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                height:"271px"
              }}
            >
              <br />
              <div className="d21">
                <h4>Om Beach</h4>
                <br />
               
                <p style={{textAlign:"justify"}}>                Relax on the tranquil shores of Om Beach, where you can indulge in beachside lounging, swimming, and even beach yoga sessions.
                </p>
                <br /> <br />
                
              </div>
            </div>
          </div>
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img
                    src={i2}
                    alt=""
                  />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Half Moon Beach</h4>
                    <br />
                  
                    <p style={{textAlign:"justify"}}>
                    Accessible only by boat or a scenic hike, Half Moon Beach offers a secluded escape for luxury travelers seeking privacy and natural beauty.
                    </p>
                    <br />
                   
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img
                    src={i3}
                    alt=""
                  />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Mahabaleshwar Temple</h4>
                    <br />
                  
                    <p style={{textAlign:"justify"}}>
                    Explore the spiritual side of Gokarna by visiting the ancient Mahabaleshwar Temple, dedicated to Lord Shiva. Witness the rich history and architectural marvels of this sacred site.
                    </p>
                    <br />
                    
                  </div>
                </div>
              </div>
            </div>
          </center>

       
          <center>
            <h2 id="k">RECOMMENDED 
 HOTELS IN GOKARNA</h2>
            <br />
            <strong>
              <p>
              Gokarna offers a selection of upscale accommodations that provide a perfect blend of comfort and luxury.


              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>
         
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img
                    src={h1}
                    alt=""
                  />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Kahani Paradise</h4>
                    <br />
                  
                    <p style={{textAlign:"justify"}}>
                    This exclusive beachfront resort offers private villas and suites with stunning ocean views. Enjoy world-class amenities, personalized service, and a serene environment.

                    </p>
                    <br />
                   
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img
                    src={h2}
                    alt=""
                  />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Swaswara, CGH Earth</h4>
                    
                    <br />
                    <p style={{textAlign:"justify"}}>
                    An eco-friendly wellness retreat, Swaswara offers luxurious cottages surrounded by lush greenery. Immerse yourself in holistic wellness, yoga, and Ayurvedic treatments.
                    </p>
                    <br />
                   
                  </div>
                </div>
              </div>
            </div>
          </center>
         
          <center>
            <h2 id="r">RECOMMENDED 
 RESTAURANTS IN GOKARNA</h2>
            <br />
            <strong>
              <p>
              Experience a delectable journey through coastal cuisine and global flavors.


              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>

          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img
                    src={r1}
                    alt=""
                  />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Namaste Cafe</h4>
                    <br />
                    <p style={{textAlign:"justify"}}>
                    Dine by the beach and savor a range of dishes, from local seafood to international favorites. The breathtaking ocean view adds a touch of luxury to your dining experience.
                    </p>{" "}
                    <br />
                   
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img
                    src={r2}
                    alt=""
                  />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Mantra Cafe</h4>
                    <br />
                    <p style={{textAlign:"justify"}}>
                    Enjoy a fusion of Indian and Mediterranean cuisines amidst a tranquil setting. The emphasis on fresh ingredients and artistic presentation makes for an exquisite culinary experience.

                    </p>{" "}
                    <br />
                    
                  </div>
                </div>
              </div>
            </div>
          </center>
        </div>
      </div>
   
      {/* ............................................................................. */}

      <center>
        <h2 id="k">WELLNESS AND RELAXATION</h2>
        <br />
        <strong>
          <p>
          Elevate your well-being through yoga and wellness experiences that rejuvenate the mind, body, and soul.


          </p>
        </strong>
        <br />
        <br />
        <br />
      </center>
      <center>
        {/* this is two templates */}

        <div className="hotel">
        

          <div className="h2" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg2">
              <img
                src={s1}
                alt=""
              />
              <div className="dp2">
                <br />
                <h4 style={{ textAlign: "start" }}>Ayurvedic Spa Retreats</h4>
                <br />
               
                <p style={{textAlign:"justify"}}>
                Immerse yourself in traditional Ayurvedic treatments that offer relaxation and healing in a luxurious setting.
                </p>
                <br />
               
              </div>
            </div>
          </div>
        </div>
      </center>
      <br />
      <br />
      <br />
      

    
    </>
  );
}