import React, { useState } from "react";
import "./destination.css";
import a from "./images/Seychelles.jpg";
import t1 from "../Enchant images/Seychelles/Exclusive experience/n1.webp";
import h2 from "../Enchant images/Seychelles/Luxurious private Resorts & Villas/Fregate Island Private.jpg";
import h1 from "../Enchant images/Seychelles/Luxurious private Resorts & Villas/north island lodge seychelles.jpg";
import i2 from "../Enchant images/Seychelles/Marine Adventure & underwater exploration/Submersible Submarine Tour.jpg";
import i1 from "../Enchant images/Seychelles/Marine Adventure & underwater exploration/Snorkeling and Diving.jpg";
import r1 from "../Enchant images/Seychelles/Culinary Delights & Creole cuisine/The Nest at Bird Island Lodge.jpg";
import r2 from "../Enchant images/Seychelles/Culinary Delights & Creole cuisine/Le Jardin du Roi.jpg";
import s1 from "../Enchant images/Seychelles/Wellness & relaxation/Raffles Spa at Raffles Praslin.jpg";
import e1 from "../Enchant images/Seychelles/Exclusive experience/Private Yacht Charter.jpg";
import e2 from "../Enchant images/Seychelles/Exclusive experience/Helicopter Island Hopping.jpg";
export default function Seychelles() {
  const [activeLink, setActiveLink] = useState("overview");

  const scrollToSection = () => {
    const targetSection = document.getElementById("j");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("itineraries");
  };
  const scrollToSection1 = () => {
    const targetSection = document.getElementById("r");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("locations");
  };
  const scrollToSection2 = () => {
    const targetSection = document.getElementById("k");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("hotels");
  };

  return (
    <>
      <div className="div">
        <div className="destination_header">
          <img id="s" src={a} alt="" />
          <div className="he">
            <h1>
              EXPERIENCE PRISTINE BEACHES & TURQUOISE WATERS IN SEYCHELLES
            </h1>
          </div>
        </div>

        <center>
          <div className="nav2">
            <div className="div3">
              <a href="">
                <p
                  className={` ${
                    activeLink === "overview" ? "active-link" : ""
                  }`}
                >
                  OVERVIEW
                </p>
              </a>
              <a onClick={scrollToSection}>
                <p
                  className={` ${
                    activeLink === "itineraries" ? "active-link" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  ITINERARIES
                </p>
              </a>

              <a onClick={scrollToSection2}>
                <p
                  className={` ${activeLink === "hotels" ? "active-link" : ""}`}
                  style={{ cursor: "pointer" }}
                >
                  HOTELS
                </p>
              </a>
              <a onClick={scrollToSection1}>
                <p
                  style={{ cursor: "pointer" }}
                  className={` ${
                    activeLink === "locations" ? "active-link" : ""
                  }`}
                >
                  RESTAURANTS
                </p>
              </a>
            </div>
          </div>
        </center>

        <br />
      

        <div className="div4">
          <div className="sec1">
            <br />

            <h2>
              WHY SHOULD YOU TRAVEL TO SEYCHELLES WITH US?
              <br />
            </h2>
            <br />
            <p style={{textAlign:"justify"}}>
              Welcome to the paradise of Seychelles, where pristine beaches,
              turquoise waters, and luxurious experiences converge to offer an
              idyllic and opulent escape. Known for its secluded islands,
              upscale resorts, and vibrant marine life, Seychelles presents a
              perfect blend of natural beauty and indulgence. Let's explore the
              world of luxury that awaits you in this breathtaking destination.
              Seychelles, with its luxurious private resorts, marine adventures,
              and unspoiled beauty, offers an exclusive and opulent escape that
              captures both relaxation and natural wonder. From staying in
              secluded accommodations to savoring gourmet cuisine and engaging
              in curated activities, Seychelles promises an unforgettable
              journey that embodies the essence of luxury and the allure of
              paradise.
            </p>
          </div>
          <div className="sec2">
            <br />
            
            <img src={t1} alt="" />
          </div>
        </div>

        <div className="div5" style={{ background: "#fafafa" }}>
          <br />
          <br />
          
          <center>
            <h2 id="j">RECOMMENDED ITINERARIES IN SEYCHELLES</h2>
            <br />
            <strong>
              <p>
                Experience the mesmerizing underwater world of Seychelles
                through curated activities:
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>

          <center>
           

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={i1} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>
                      {" "}
                      Snorkeling and Diving
                    </h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                      Embark on luxury snorkeling and diving experiences to
                      witness colorful coral reefs, diverse marine species, and
                      the beauty of the Indian Ocean.
                    </p>
                    <br />
                    
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={i2} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>
                      Submersible Submarine Tour
                    </h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                      Dive to deeper depths in a submersible submarine, allowing
                      you to explore the underwater wonders of Seychelles in
                      comfort and style.
                    </p>
                    <br />
                  
                  </div>
                </div>
              </div>
            </div>
          </center>

         
          <center>
            <h2 id="k">LUXURIOUS PRIVATE RESORTS AND VILLAS</h2>
            <br />
            <strong>
              <p>
                Seychelles boasts a collection of luxurious private resorts and
                villas that provide a perfect blend of comfort and exclusivity.
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>
      
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={h1} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}> North Island Lodge</h4>
                    <br />
                    
                    <p style={{textAlign:"justify"}}>
                      Immerse yourself in luxury at North Island Lodge, offering
                      exclusive villas with private beaches, personalized
                      service, and a commitment to conservation.
                    </p>
                    <br />
                   
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={h2} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>
                      {" "}
                      Fregate Island Private
                    </h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                      Experience ultimate seclusion at Fregate Island Private,
                      where spacious villas, lush surroundings, and pristine
                      beaches create an atmosphere of serenity and indulgence.
                    </p>
                    <br />
                   
                  </div>
                </div>
              </div>
            </div>
          </center>
        
          <center>
            <h2 id="r">CULINARY DELIGHTS AND CREOLE CUISINE</h2>
            <br />
            <strong>
              <p>
                Indulge in a culinary journey that celebrates Creole flavors and
                international influences.
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>

         
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={r1} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>
                      The Nest at Bird Island Lodge
                    </h4>
                    <br />
                    <p style={{textAlign:"justify"}}>
                      Savor gourmet dishes at The Nest, where locally sourced
                      ingredients and fresh seafood are transformed into
                      exquisite Creole and international dishes.
                    </p>{" "}
                    <br />
                   
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={r2} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Le Jardin du Roi</h4>
                    <br />
                    <p style={{textAlign:"justify"}}>
                      Enjoy a fusion of French and Creole flavors at Le Jardin
                      du Roi, a hillside restaurant offering panoramic views and
                      culinary excellence.
                    </p>{" "}
                    <br />
                  
                  </div>
                </div>
              </div>
            </div>
          </center>
        </div>
      </div>
     
      <center>
        <h2 id="k">WELLNESS AND RELAXATION</h2>
        <br />
        <strong>
          <p>
            Rejuvenate your senses at Seychelles' luxury wellness centers and
            spas.
          </p>
        </strong>
        <br />
        <br />
        <br />
      </center>
      <center>
       

        <div className="hotel">
          <div className="h2" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg2">
              <img src={s1} alt="" />
              <div className="dp2">
                <br />
                <h4 style={{ textAlign: "start" }}>
                  Raffles Spa at Raffles Praslin
                </h4>
                <br />
               
                <p style={{textAlign:"justify"}}>
                  Immerse yourself in holistic wellness at Raffles Spa, offering
                  a range of treatments inspired by local traditions and modern
                  relaxation techniques.
                </p>{" "}
                <br />
             
              </div>
            </div>
          </div>
        </div>
      </center>
     

      <center>
        <h2 id="j">EXCLUSIVE EXPERIENCES</h2>
        <br />
        <strong>
          <p>Elevate your Seychelles journey with extraordinary activities</p>
        </strong>
        <br />
        <br />
        <br />
      </center>

      <center>
        

        <div className="hotel">
          <div className="h1" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg1">
              <img src={e1} alt="" />
              <div className="dp1">
                <br />
                <h4 style={{ textAlign: "start" }}>Private Yacht Charter</h4>
                <br />
                <p style={{textAlign:"justify"}}>
                  Set sail on a private yacht charter, exploring the Seychelles
                  archipelago in luxury and visiting hidden coves, deserted
                  islands, and vibrant coral reefs.
                </p>{" "}
                <br />
             
              </div>
            </div>
          </div>

          <div className="h2" style={{ padding: "10px", height: "400px" }}>
            <div className="dimg2">
              <img src={e2} alt="" />
              <div className="dp2">
                <br />
                <h4 style={{ textAlign: "start" }}>
                  Helicopter Island Hopping
                </h4>
                <br />
                <p style={{textAlign:"justify"}}>
                  Soar above the islands in a helicopter, experiencing
                  breathtaking aerial views of Seychelles' stunning landscapes
                  and turquoise waters.
                </p>{" "}
                <br />
              
              </div>
            </div>
          </div>
        </div>
      </center>
    </>
  );
}
