import React, { useState } from "react";
import "./destination.css";
import i from "./images/Miami.jpg";
import i1 from "../Enchant images/Miami/Miami_1.jpg";
import i2 from "../Enchant images/Miami/Miami_2.jpg";
import i3 from "../Enchant images/Miami/Miami_3.jpg";
import i4 from "../Enchant images/Miami/Miami_4.jpg";
import i5 from "../Enchant images/Miami/Miami_5.jpg";
import i6 from "../Enchant images/Miami/Miami_6.jpg";
import i7 from "../Enchant images/Miami/Miami_7.jpg";
import i8 from "../Enchant images/Miami/Miami_8.jpg";
import t1 from "../Enchant images/Miami/Miami_cover.jpg";
export default function Miami() {
  const [activeLink, setActiveLink] = useState("overview");

  const scrollToSection = () => {
    const targetSection = document.getElementById("accomodation");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("Accomodations");
  };
  const scrollToSection1 = () => {
    const targetSection = document.getElementById("beach");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("Beaches");
  };
  const scrollToSection2 = () => {
    const targetSection = document.getElementById("dive");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("Dive");
  };
  const scrollToSection3 = () => {
    const targetSection = document.getElementById("hotel");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("hotels");
  };
  const scrollToSection4 = () => {
    const targetSection = document.getElementById("tours");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("tours");
  };
  const scrollToSection5 = () => {
    const targetSection = document.getElementById("relax");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("relax");
  };
  const scrollToSection6 = () => {
    const targetSection = document.getElementById("luxury");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("luxury");
  };
  const scrollToSection7 = () => {
    const targetSection = document.getElementById("nature");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("nature");
  };

  return (
    <>
      <div className="div">
        <div className="destination_header">
          <img id="s" src={i} alt="" />
          <div className="he">
            <h1>LUXURY HOLIDAYS & GLAMOROUS EXPERIENCE IN MIAMI</h1>
          </div>
        </div>

        <br />

        <div className="div4">
          <div className="sec1">
            <br />
          
            <h2>
              WHY SHOULD YOU TRAVEL TO MIAMI WITH US?
              <br />
              
            </h2>
            <br />
            <p style={{textAlign:"justify"}}>
              Welcome to Miami, a vibrant city known for its glamorous
              lifestyle, stunning beaches, and world-class entertainment. From
              luxurious accommodations to high-end shopping and exquisite
              dining, Miami offers a playground of indulgence for the discerning
              traveler. Miami's combination of glamorous lifestyle, diverse
              cultural experiences, and upscale offerings make it a haven for
              luxury travelers seeking both relaxation and excitement. Whether
              you're lounging on the pristine beaches, exploring the vibrant
              arts scene, or indulging in gourmet dining, Miami promises a
              luxurious journey filled with unforgettable
            </p>
          </div>
          <div className="sec2">
            
            <br />
            <img src={t1} alt="" />
          </div>
        </div>
        <div className="div5" style={{ background: "#fafafa" }}>
          <br />
          <br />
          <br />
          <center>
            <h2 id="j">
              MIAMI PROMISES A LUXURIOUS JOURNEY FILLED WITH UNFORGETTABLE
              MOMENTS!
            </h2>
            <br />
            <strong>
              <p>
                The optimal period to visit Miami is from March through
                May
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>
      
          <br />
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={i1} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Luxury Beachfront Hotels</h4>
                   
                    <br />
                    <p style={{textAlign:"justify"}}>
                    Upscale oceanfront resorts and boutique hotels with
                  breathtaking views of the Atlantic.
                  
                  Lavish suites, private balconies, and personalized concierge
                  services.
                  
                  Beachside cabanas and lounges for exclusive relaxation and
                  people-watching.
                    </p>
                    <br />
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={i2} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Art and Culture Scene</h4>
                    <br />
              
                    <p style={{textAlign:"justify"}}>
                    Exploring the iconic Art Deco Historic District of South
                  Beach.
                  
                  Visiting world-renowned art galleries in the Wynwood Arts
                  District.
                  
                  Attending exclusive art events, gallery openings, and cultural
                  festivals.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </center>

          {/* beaches  */}
       <br/>
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={i3} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}> High-End Shopping</h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                    Luxury shopping at high-end designer boutiques on Lincoln Road
                  and Bal Harbour Shops.
                  
                  Designer fashion, jewelry, and accessories from renowned
                  brands.
                  
                  Exclusive shopping experiences with personal stylists and
                  private showings.
                    </p>
                    <br />
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={i4} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Fine Dining and Culinary Experiences</h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                    Michelin-starred restaurants offering innovative cuisine by
                  acclaimed chefs.
                  
                  Waterfront dining with stunning views of Biscayne Bay and the
                  Miami skyline.
                  
                  Culinary tours and gourmet food experiences featuring diverse
                  international flavors.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </center>
  
          <br />
      
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={i5} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Yacht Charters and Water Adventures</h4>
                    <br />
               
                    <p style={{textAlign:"justify"}}>
                    Chartering private yachts to explore the Intracoastal Waterway
                  and nearby islands.
                  
                  Jet skiing, paddleboarding, and kayaking in the azure waters
                  of the Atlantic.
                  
                  Deep-sea fishing and sailing excursions for an ocean
                  adventure.
                    </p>
                    <br />
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={i6} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Nightlife and Entertainment</h4>
                    <br />
                   
                    <p style={{textAlign:"justify"}}>
                    Exclusive VIP access to world-famous nightclubs and lounges.
                  
                  Front-row seats to live performances, concerts, and theater
                  shows.
                  
                  Sunset cocktail cruises and yacht parties for an unforgettable
                  nightlife experience.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </center>
        <br/>
         
       
          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={i7} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Spa Retreats and Wellness</h4>
                    <br />
                    
                    <p style={{textAlign:"justify"}}>
                    Luxurious spa resorts offering a range of rejuvenating
                  treatments and therapies.
                  
                  Yoga and wellness retreats in serene settings, both indoors
                  and on the beach.
                  
                  Private meditation sessions and holistic wellness programs.
                    </p>
                    <br />
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={i8} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Golf and Sporting Activities</h4>
                    <br />
                  
                    <p style={{textAlign:"justify"}}>
                    Tee off at championship golf courses designed by renowned
                  architects.
                  
                  Private golf lessons and access to top-tier golf clubs.
                  
                  Sporting events such as tennis tournaments, basketball games,
                  and more.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </center>
         
        </div>
      </div>
    </>
  );
}
