import React,{useState} from 'react';
import './megamenu.css'; // Import your CSS file
import Pinkbutton from '../Navbar/Pinkbutton';
import logo from '../Navbar/logo.png'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Bymonth from '../Bymonth/Bymonth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import BlackButton from '../Navbar/Blackbutton';


const MegaMenu = () => {
const usenav=useNavigate();

  const [isPopupOpen, setPopupOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact: '',
    message: '',
  });

  const handleLoginClick = () => {
    setPopupOpen(!isPopupOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const [isPopupOpen1, setPopupOpen1] = useState(false);
  const [formData1, setFormData1] = useState({
    name: '',
    password:"",
  });

  const handleLoginClick1 = () => {
    setPopupOpen1(!isPopupOpen1);
  };

  const [na,setn]=useState("")
  const [pas,setpasss]=useState("")
  const [pho,setpho]=useState("")
  const [msg,setMsg]=useState("")
 




  const [activeLink, setActiveLink] = useState('home');
  const [isListVisible, setListVisible] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState('');

  const toggleListVisibility = () => {
    setListVisible(!isListVisible);
  };

  const handleMonthClick = (month) => {
    setSelectedMonth(month);
    toggleListVisibility();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // You can handle form submission here, e.g., send data to a server or perform any desired action.
    console.log('Form Data:', formData);
    const mailtoLink = `mailto:info@enchantxp.com?subject=Contact Us&body=
    Name: ${formData.name}
    Email: ${formData.email}
    Contact: ${formData.contact}
    Message: ${formData.message}`;

    // Open the user's default email client with the pre-filled email
    window.location.href = encodeURI(mailtoLink);

    // Close the popup after submission
    setPopupOpen(false);




    // Close the popup after submission
    setPopupOpen(false);
  };
 

  const handleSubmit1 = (e) => {
    e.preventDefault();
    // You can handle form submission here, e.g., send data to a server or perform any desired action.
    
    const mailtoLink = `mailto:info@enchantxp.com?subject=Gift Card details &body=
    Name: ${na}
    Email: ${pas}
    Contact: ${pho}
    Message: ${msg}`;

    window.location.href = encodeURI(mailtoLink);
    console.log(mailtoLink);

    setPopupOpen1(false);
   
   
  };





  return (
    <div >
      <nav>

        <div className="wrapper" >
          <div className="logo" >
            <Link to="/">
            <img src={logo}  style={{width:"160px",height:"50px"}} alt="" />
            </Link>
            </div>
          <input type="radio" name="slider" id="menu-btn" />
          <input type="radio" name="slider" id="close-btn" />
          <ul className="nav-links">
          
            <label htmlFor="close-btn" className="btn close-btn">
              
            <i class="fa-solid fa-xmark" style={{position:"absolute",top:"-1px",fontSize:"22px"}}></i>     


              
              
              </label>
            <li ><a href="/" >HOME</a></li>
            <li><a href="/about" >ABOUT</a></li>
      
            <li id='dest'>
              <a href="/all" className="desktop-item" >DESTINATIONS</a>
              <input type="checkbox" id="showMega" />
              <a href="#"> <label htmlFor="showMega" className="mobile-item">DESTINATIONS</label></a>
              <div className="mega-box">
                <div className="content">
               
                  <div className="row">
                    <ul className="mega-links">
       
                      <li><a href="/agra">Agra</a></li>
                      <li><a href="/alleppey">Alleppey</a></li>
                      <li><a href="/amsterdam">Amsterdam</a></li>
                      <li><a href="amalfi coast">Amalfi Coast</a></li>
                      <li><a href="/andaman">Andaman</a></li>
                      <li><a href="/australia">Australia</a></li>

                      <li><a href="/bali">Bali</a></li>
                      <li><a href="/belgium">Belgium</a></li>
                      <li><a href="/bora bora">Bora Bora</a></li>
                      <li><a href="/cairo">Cairo</a></li>
                    </ul>
                  </div>
                  <div className="row">
                    {/* <header>Design Services</header> */}
                    <ul className="mega-links">
                    <li><a href="/cape town">Cape Town</a></li>

                    <li><a href="/cancun">Cancun</a></li>
<li><a href="/croatia">Croatia</a></li>
<li><a href="/dalhousie">Dalhousie</a></li>
<li><a href="/diu">Diu</a></li>
<li><a href="/dubai">Dubai</a></li>
<li><a href="/finland">Finland</a></li>
<li><a href="/gangtok">Gangtok</a></li>
<li><a href="/gokarna">Gokarna</a></li>
<li><a href="/goa">Goa</a></li>
                    </ul>
                  </div>
                  <div className="row">
                    {/* <header>Design Services</header> */}
                    <ul className="mega-links">
                    <li><a href="/guwahati">Guwahati</a></li>

                    <li><a href="/gulmarg">Gulmarg</a></li>
<li><a href="/jodhpur">Jodhpur</a></li>
<li><a href="kumarakom">Kumarakom</a></li>
<li><a href="/lizard island">Lizard island</a></li>

<li><a href="/london">London</a></li>
<li><a href="/maldives">Maldives</a></li>


<li><a href="/marrakech">Marrakech</a></li>
<li><a href="/mauritius">Mauritius</a></li>
<li><a href="/miami">Miami</a></li>

                    </ul>
                  </div>


                  <div className="row">
                    {/* <header>Email Services</header> */}
                    <ul className="mega-links">
                    <li><a href="/milan">Milan</a></li>

                    <li><a href="/mumbai">Mumbai</a></li>

                    <li><a href="/munnar">Munnar</a></li>

                    <li><a href="/new delhi">New Delhi</a></li>
<li><a href="/paris">Paris</a></li>
<li><a href="/ranthambore">Ranthambore</a></li>
<li><a href="/rome">Rome</a></li>
<li><a href="/scotland">Scotland</a></li>

<li><a href="/seychelles">Seychelles</a></li>
<li><a href="/shimla">Shimla</a></li>
                 
                    </ul>
                  </div>
                  <div className="row">
                    {/* <header>Security services</header> */}
                    <ul className="mega-links">
             
                    <li><a href="/singapore">Singapore</a></li>

                    <li><a href="/srinagar">Srinagar</a></li>

                    <li><a href="/st. lucia">St. Lucia</a></li>

                    <li><a href="/switzerland">Switzerland</a></li>

                    <li><a href="/the maasai mara">The Maasai Mara</a></li>
<li><a href="/tulum">Tulum</a></li>
<li><a href="/turkey">Turkey</a></li>

<li><a href="/udaipur">Udaipur</a></li>

<li><a href="/vietnam">Vietnam</a></li>
<li><a href="/vishakapatnam">Visakhapatnam</a></li>
                 
                    </ul>
                    <ul className='mega-links' id='aj'>
                    <li><a href="/all">Explore All</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>

            
            <li id='cater'>
              <a href="#" className="desktop-item">CATEGORIES</a>
              <input type="checkbox" id="showDrop" />
              <a href="#">
              <label htmlFor="showDrop" className="mobile-item">CATEGORIES</label>
              </a>
              <ul className="drop-menu" >
                <li><a href="#">MOST POPULAR</a></li>
                <li  
                className="mega-hover"
                ><a href="#">BY MONTH</a>
                <div className="mega-menu2">
              <Bymonth onMonthClick={handleMonthClick} />
              {/* Render the ByMonth component */}
            </div>

                </li>
              



                <li><a href="#">EXPERIENCES</a></li>
                {/* <li><a href="#">Drop menu 4</a></li> */}
              </ul>
            </li>
            <li onClick={handleLoginClick1}> <a href="#"> GIFTCARDS </a></li>
            <li><a onClick={handleLoginClick} style={{cursor:"pointer"}}>CONTACT US</a></li>    
            <li> <label htmlFor="showMega" className="mobile-item"><a href="https://admin.enchantxp.com/" > LOGIN</a></label>
</li>        

         
            
          
          </ul>
          <ul>  <li id='pink'  >
            <a href="https://admin.enchantxp.com/">
            <Pinkbutton/></a></li></ul>

          <label htmlFor="menu-btn" className="btn menu-btn">   
        <i class="fa-solid fa-bars" style={{position:"absolute",top:"28px",fontSize:"22px"}}></i>  
</label>
        </div>
      </nav>

      {isPopupOpen && (
        <div className="popup-container">
          <div className="popup">
            <h2 style={{ margin: "6px",marginRight:"4px" }}>Contact Us</h2>
            <form
            //  onSubmit={handleSubmit}
              style={{ padding: "20px", paddingBottom:"20px",paddingRight:"20px" ,paddingTop:"1px"}}>
              <div className="form-group" style={{ boxShadow:"none",width:"100%"}}>
                <label htmlFor="name" style={{ display: "block", marginBottom: "5px" }}>
                  Name:
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  style={{ padding: "6px", width: "100%" }}
                />
              </div>
              <div className="form-group" style={{ boxShadow:"none",width:"100%"}}>
                <label htmlFor="email" style={{ display: "block", marginBottom: "5px" }}>
                  Email:
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  style={{ padding: "6px", width: "100%" }}
                />
              </div>
              <div className="form-group" style={{boxShadow:"none",width:"100%"}}>
                <label htmlFor="contact" style={{ display: "block", marginBottom: "5px" }}>
                  Contact:
                </label>
                <input
                  type="text"
                  id="contact"
                  name="contact"
                  value={formData.contact}
                  onChange={handleChange}
                  required
                  style={{ padding: "6px", width: "100%" }}
                />
              </div>
              <div className="form-group" style={{boxShadow:"none",width:"100%" }}>
                <label htmlFor="message" style={{ display: "block", marginBottom: "5px" }}>
                  Message:
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  style={{ padding: "6px", width: "100%", minHeight: "100px" }}
                />
              </div>


              <div className="form-group" style={{ textAlign: "center" ,boxShadow:"none",width:"100%"}}>
                <button type="submit" style={{ padding: "10px 20px", marginRight: "10px",boxShadow:"none" ,background:"black",color:"white"}}>
                  Send
                </button>
              
                <button onClick={handleLoginClick} style={{ padding: "10px 20px",background:"black",color:"white" }}>
                  Close
                </button>

              </div>

            </form>
          </div>
        
     
      
        </div>
  )}
    {isPopupOpen1 && (
        <div className="popup-container">
          <div className="popup">
            <h2 style={{ margin: "10px" }}> Gift Cards </h2>
 <br/>
            <p style={{textAlign:"justify",lineHeight:"24px",}}>Post-pandemic, the world has witnessed an unprecedented urge to travel. Enchantxp offers exclusive gift cards that open doors to unforgettable experiences.
These gift cards extend beyond traditional travel and customizing experiences and arranging curated travel adventures across the globe.
The result? Memories that last a lifetime.</p>
<form onSubmit={handleSubmit1} style={{ padding: '5px' }}>
      <div className="form-group" style={{ boxShadow: 'none', width: '100%' }}>
        <label htmlFor="name" style={{ display: 'block', marginBottom: '5px',  }}>
          Name
        </label>
        <input
          type="text"
          id="name"
          name="name"
          value={na}
          onChange={(e) => setn(e.target.value)}
          required
          style={{ padding: '3px', width: '100%' }}
        />
      </div>

      <div className="form-group" style={{ boxShadow: 'none', width: '100%' }}>
        <label htmlFor="email" style={{ display: 'block', marginBottom: '5px',  }}>
          Email
        </label>
        <input
          type="email" 
          value={pas}
          onChange={(e) => setpasss(e.target.value)}
          required
          style={{ padding: '3px', width: '100%' }}
        />
      </div>

      <div className="form-group" style={{ boxShadow: 'none', width: '100%' }}>
        <label htmlFor="phono" style={{ display: 'block', marginBottom: '5px', }}>
          Phone
        </label>
        <input
          type="text"
          id="phono"
          name="phono"
          value={pho}
          onChange={(e) => setpho(e.target.value)}
          required
          style={{ padding: '3px', width: '100%' }}
        />
      </div>

      <div className="form-group" style={{ boxShadow: 'none', width: '100%' }}>
        <label htmlFor="msg" style={{ display: 'block', marginBottom: '5px', }}>
          Message
        </label>
        <textarea
          id="msg"
          name="msg"
          value={msg}
          onChange={(e) => setMsg(e.target.value)}
          required
          style={{ padding: '3px', width: '100%' }} 
        />
      </div>

      <div className="form-group" style={{ textAlign: 'center', boxShadow: 'none', width: '100%' }}>
        <button type="submit" style={{ padding: '10px 20px', marginRight: '10px', boxShadow: 'none', background: 'black', color: 'white' }}>
          Submit
        </button>

        <button onClick={handleLoginClick1} style={{ padding: '10px 20px', background: 'black', color: 'white' }}>
          Close
        </button>
      </div>
    </form>
          </div>
        
     
      
        </div>
  )}

















  </div>


  )










    
  
};

export default MegaMenu;
