import React, { useState } from "react";
import "./destination.css";
import i from "./images/Marrakech.jpg";
import t1 from "../Enchant images/Marrakech/Cultural exploration and architechtural/Marrakech.jpg";
import i2 from "../Enchant images/Marrakech/Cultural exploration and architechtural/Bahia Palace.jpg";
import i1 from "../Enchant images/Marrakech/Cultural exploration and architechtural/Jardin Majorelle.jpg";
import b1 from "../Enchant images/Marrakech/High-end shopping and souks/Souk Semmarine.jpg";
import b2 from "../Enchant images/Marrakech/High-end shopping and souks/33 Majorelle.jpg";
import h1 from "../Enchant images/Marrakech/Luxurious Riads and Boutique Hotels/La Mamounia.jpg";
import h2 from "../Enchant images/Marrakech/Luxurious Riads and Boutique Hotels/Royal Mansour Marrakech.jpg";
import r1 from "../Enchant images/Marrakech/Culinary Delights and Moroccan Cuisine/Le Jardin.jpg";
import r2 from "../Enchant images/Marrakech/Culinary Delights and Moroccan Cuisine/Dar Yacout.jpg";
import s1 from "../Enchant images/Marrakech/Wellness & Relaxation/The Spa at La Mamounia.jpg";
import e1 from "../Enchant images/Marrakech/Exclusive Experience/Hot Air Balloon Ride over the Atlas Mountains.jpg";
import e2 from "../Enchant images/Marrakech/Exclusive Experience/Private Sunset Camel Ride in the Palmeraie.jpg";
export default function Marrakech() {
  const [activeLink, setActiveLink] = useState("overview");

  const scrollToSection = () => {
    const targetSection = document.getElementById("j");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("itineraries");
  };
  const scrollToSection1 = () => {
    const targetSection = document.getElementById("r");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("locations");
  };
  const scrollToSection2 = () => {
    const targetSection = document.getElementById("k");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setActiveLink("hotels");
  };

  return (
    <>
      <div className="div">
        <div className="destination_header">
          <img id="s" src={i} alt="" />
          <div className="he">
            <h1>WELCOME TO THE ENCHANTING CITY OF MARRAKECH</h1>
          </div>
        </div>

        <center>
          <div className="nav2">
            <div className="div3">
              <a href="">
                <p
                  className={` ${
                    activeLink === "overview" ? "active-link" : ""
                  }`}
                >
                  OVERVIEW
                </p>
              </a>
              <a onClick={scrollToSection}>
                <p
                  className={` ${
                    activeLink === "itineraries" ? "active-link" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  ITINERARIES
                </p>
              </a>

              <a onClick={scrollToSection2}>
                <p
                  className={` ${activeLink === "hotels" ? "active-link" : ""}`}
                  style={{ cursor: "pointer" }}
                >
                  HOTELS
                </p>
              </a>
              <a onClick={scrollToSection1}>
                <p
                  style={{ cursor: "pointer" }}
                  className={` ${
                    activeLink === "locations" ? "active-link" : ""
                  }`}
                >
                  RESTAURANTS
                </p>
              </a>
            </div>
          </div>
        </center>

        <br />
      

        <div className="div4">
          <div className="sec1">
            <br />
           
            <h2>
              WHY SHOULD YOU TRAVEL TO MARRAKECH WITH US?
              <br />
             
            </h2>
            <br/>
            <p style={{textAlign:"justify"}}>
              Welcome to the enchanting city of Marrakech, where vibrant colors,
              rich culture, and upscale experiences come together to offer a
              luxurious and exotic escape. Known for its bustling medina,
              luxurious riads, and exquisite cuisine, Marrakech presents a
              perfect blend of opulence and Moroccan charm. Let's explore the
              world of luxury that awaits you in this captivating destination.
              Marrakech, with its luxurious riads, cultural gems, and vibrant
              experiences, offers an enchanting and opulent escape that captures
              both tradition and exotic allure. From staying in elegant
              accommodations to savoring gourmet cuisine and engaging in curated
              activities, Marrakech promises an unforgettable journey that
              embodies the essence of luxury and the allure of Moroccan charm.
            </p>
          </div>
          <div className="sec2">
            <br />
            
            <img src={t1} alt="" />
          </div>
        </div>

        <div className="div5" style={{ background: "#fafafa" }}>
          <br />
          <br />
          
          <center>
            <h2 id="j">RECOMMENDED ITINERARIES IN MARRAKECH</h2>
            <br />
            <strong>
              <p>
                Engage with Marrakech's rich history and architectural marvels
                through curated experiences:
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>

          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={i1} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Jardin Majorelle</h4>
                  
                    <br />
                    <p style={{textAlign:"justify"}}>

                      Explore the vibrant Jardin Majorelle, a serene oasis of
                      lush gardens and cobalt blue structures that was once
                      owned by fashion designer Yves Saint Laurent.
                    </p>
                    <br />
                   
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={i2} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Bahia Palace</h4>
                    
                    <br />
                    <p style={{textAlign:"justify"}}>
                      Discover the intricate beauty of Bahia Palace, a palace
                      complex showcasing exquisite Moroccan craftsmanship and
                      architectural details.
                    </p>
                    <br />
                 
                  </div>
                </div>
              </div>
            </div>
          </center>

       

          <center>
            <h2 id="">HIGH-END SHOPPING AND SOUKS</h2>
            <br />
            <strong>
              <p>
                Experience Marrakech's vibrant shopping scene with luxury
                boutiques and bustling souks
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>

          <center>
          

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={b1} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Souk Semmarine</h4>
                   
                    <br />
                    <p style={{textAlign:"justify"}}>
                      Explore Marrakech's main souk, Souk Semmarine, where you
                      can find everything from handcrafted textiles and leather
                      goods to intricate ceramics and spices.
                    </p>
                    <br />
                   
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={b2} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>33 Majorelle</h4>
                    
                    <br />
                    <p style={{textAlign:"justify"}}>
                      Immerse yourself in luxury shopping at 33 Majorelle, a
                      concept store offering a curated collection of Moroccan
                      fashion, accessories, and home decor.
                    </p>
                    <br />
                  
                  </div>
                </div>
              </div>
            </div>
          </center>

          <center>
            <h2 id="k">LUXURIOUS RIADS AND BOUTIQUE HOTELS</h2>
            <br />
            <strong>
              <p>
                Marrakech boasts a collection of luxurious riads and boutique
                hotels that provide a perfect blend of comfort and traditional
                Moroccan elegance.
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>

          <center>
          

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={h1} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>La Mamounia</h4>
                    
                    <br />
                    <p style={{textAlign:"justify"}}>
                      Immerse yourself in luxury at La Mamounia, an iconic
                      palace hotel offering opulent rooms, lush gardens, and
                      exquisite Moroccan architecture.
                    </p>
                    <br />
                  
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={h2} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>
                      Royal Mansour Marrakech
                    </h4>
                    <br />
                  
                    <p style={{textAlign:"justify"}}>
                      Experience a private haven at Royal Mansour, where each
                      riad boasts its own private pool, personalized butler
                      service, and unparalleled attention to detail.
                    </p>
                    <br />
                  
                  </div>
                </div>
              </div>
            </div>
          </center>

        
          <center>
            <h2 id="r">CULINARY DELIGHTS AND MOROCCAN CUISINE </h2>
            <br />
            <strong>
              <p>
                Indulge in a culinary journey that celebrates Moroccan flavors
                and international influences.
              </p>
            </strong>
            <br />
            <br />
            <br />
          </center>

          <center>
            {/* this is two templates */}

            <div className="hotel">
              <div className="h1" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg1">
                  <img src={r1} alt="" />
                  <div className="dp1">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Le Jardin</h4>
                    <br />
                    <p style={{textAlign:"justify"}}>
                      Savor gourmet dishes at Le Jardin, a lush oasis in the
                      heart of the medina, offering a fusion of Moroccan and
                      Mediterranean cuisine amidst serene garden surroundings.
                    </p>{" "}
                    <br />
                  
                  </div>
                </div>
              </div>

              <div className="h2" style={{ padding: "10px", height: "400px" }}>
                <div className="dimg2">
                  <img src={r2} alt="" />
                  <div className="dp2">
                    <br />
                    <h4 style={{ textAlign: "start" }}>Dar Yacout</h4>
                    <br />
                    <p style={{textAlign:"justify"}}>
                      Enjoy traditional Moroccan cuisine at Dar Yacout, where
                      you can dine on the rooftop terrace with panoramic views
                      of the city and Atlas Mountains.
                    </p>{" "}
                    <br />
                   
                  </div>
                </div>
              </div>
            </div>
          </center>
        </div>

        <center>
          <h2 id="k">WELLNESS AND RELAXATION</h2>
          <br />
          <strong>
            <p>
              Rejuvenate your senses at Marrakech's luxury wellness centers and
              hammams.
            </p>
          </strong>
          <br />
          <br />
          <br />
        </center>
        <center>
          {/* this is two templates */}

          <div className="hotel">
            <div className="h2" style={{ padding: "10px", height: "400px" }}>
              <div className="dimg2">
                <img src={s1} alt="" />
                <div className="dp2">
                  <br />
                  <h4 style={{ textAlign: "start" }}>The Spa at La Mamounia</h4>
                 
                  <br />
                  <p style={{textAlign:"justify"}}>
                    Immerse yourself in holistic wellness at The Spa, offering a
                    range of treatments that combine Moroccan traditions with
                    modern relaxation techniques.
                  </p>
                  <br />
                
                </div>
              </div>
            </div>
          </div>
        </center>
       

        <center>
          <h2 id="j">EXCLUSIVE EXPERIENCES</h2>
          <br />
          <strong>
            <p>Elevate your Marrakech journey with extraordinary activities</p>
          </strong>
          <br />
          <br />
          <br />
        </center>

        <center>
          {/* this is two templates */}

          <div className="hotel">
            <div className="h1" style={{ padding: "10px", height: "400px" }}>
              <div className="dimg1">
                <img src={e1} alt="" />
                <div className="dp1">
                  <br />
                  <h4 style={{ textAlign: "start" }}>
                    Hot Air Balloon Ride over the Atlas Mountains
                  </h4>
                  <br />
                  <p style={{textAlign:"justify"}}>
                    Soar above the Atlas Mountains in a hot air balloon for a
                    breathtaking aerial view of Marrakech's surrounding
                    landscapes.
                  </p>{" "}
                  <br />
                 
                </div>
              </div>
            </div>

            <div className="h2" style={{ padding: "10px", height: "400px" }}>
              <div className="dimg2">
                <img src={e2} alt="" />
                <div className="dp2">
                  <br />
                  <h4 style={{ textAlign: "start" }}>
                    Private Sunset Camel Ride in the Palmeraie
                  </h4>
                  <br />
                  <p style={{textAlign:"justify"}}>
                    Embark on a private camel ride through the Palmeraie during
                    sunset, enjoying the serene beauty of the palm groves.
                  </p>{" "}
                  <br />
                
                </div>
              </div>
            </div>
          </div>
        </center>
        <br />
        <br />
      </div>
    </>
  );
}
